import { apiCall } from "../../modules/api-call";
import { CONFIG } from "../../config/config";

export const getCreatorsRequest = (inputs) => {
  return async (dispatch) => {
    try {
      let urlAppend = "";
      if (inputs) {
        urlAppend = `?userName=${inputs}`;
      }
      
      dispatch({ type: "LIST_CREATORS_REQUEST", payload: {} });
      const { data: response } = await apiCall({
        method: "get",
        url: CONFIG.BACKEND_API_URL + `creator/list/${urlAppend}`,
        auth: 1,
      });
      if (response && response.statusCode === 200 && response.error === false) {
        dispatch({ type: "LIST_CREATORS_REQUEST_SUCCESS", payload: response });
      } else {
        dispatch({ type: "LIST_CREATORS_REQUEST_ERROR", payload: response });
      }
    } catch (err) {
      dispatch({ type: "LIST_CREATORS_REQUEST_ERROR", payload: {message: err?.response?.message ? err?.response?.message :err.message } });
    }
  };
};

export const getCreatorsReset = () => {
  return async (dispatch) => {
    dispatch({ type: "LIST_CREATORS_REQUEST_RESET" });
  };
};
