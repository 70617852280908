import React from "react";
import { Modal } from "antd";
import { faBullhorn } from "@fortawesome/pro-solid-svg-icons";
import ModalHeader from "../../ModalHeader";

const OfferModal = (props) => {
  return (
    <Modal open={props.open} mask={true} bodyStyle={{ height: "90vh", overflowY: "auto", overflowX: "none" }} title={<ModalHeader titleIcon={faBullhorn} modalTitle={"Campaign Overview"} close={props.onClose} />} className="find-creator-modal" centered onCancel={props.onClose} closable={false} footer={props.footer || null} destroyOnClose={true} width={1200}>
      {props.children}
    </Modal>
  );
};

export default OfferModal;
