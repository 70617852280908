import React, { Fragment } from "react";
import "./index.scss";
import { Typography, Card, Button } from "antd";
import moment from "moment";
import Lottie from "lottie-react";
import { RightArrow } from "../CommonWidgets/FontIcons";
import monkeyAnimationJson from "../assets/animations/Pushtok_Monkey_Animation.json";
import timerAnimationJson from "../assets/animations/Timer_Animation.json";
const { Text, Title } = Typography;

// Add Error Handling.
function OfferStatusHelper(props) {
  const offerDetail = props?.data,
    creatorName = offerDetail?.creatorId?.userName ? offerDetail?.creatorId?.userName : "Pushtok team", //"Bella Poarch";
    marketerName = offerDetail?.marketerId?.userName, //"Shariq";
    statusDetails = props?.status[0],
    dateToShow = statusDetails?.key === "sent" ? offerDetail?.approveBy : offerDetail?.deadline,
    hideActivityStatuses = ["offer-expired", "cancelled"],
    canHideActivity = hideActivityStatuses.includes(statusDetails?.key) ? true : false,
    deadline = moment(dateToShow).utc().fromNow(), //"Due in 72 Hours",
    lastActionDate = fetchActionDate(offerDetail, statusDetails.key),
    { status, details, showActivity, showTimer, buttonText } = getDetailsFromStatusName(statusDetails?.statusText);

  return (
    <>
      <Card className={`offer-status-helper-container ${showActivity == 2 ? "action-border" : ""}`} bordered={false}>
        <div className="offer-status-helper-tile">
          <div className="status-text">
            <Title level={4}>{status}</Title>
            <Text type="secondary" className="expense-item-card-date">
              {moment(lastActionDate).utc().fromNow()}
            </Text>
            <br></br>
            <Text className="status-description">{details}</Text>
          </div>
          {!canHideActivity && (
            <div className="status-activity">
              {showActivity == 0 || showActivity == 1 ? statusDetails?.key !== "completed" && <Lottie loop={true} animationData={monkeyAnimationJson} autoplay={true} className={"monkey-anim-width"} rendererSettings="xMidYMid slice" /> : <></>}
              {showActivity == 2 ? (
                <div className="status-helper-button">
                  <Button size="large" type="primary" className={"btn-action shake-animation"} onClick={() => props.onTabSwitch("3")}>
                    {buttonText}
                    <RightArrow />
                  </Button>
                </div>
              ) : (
                <></>
              )}

              {showTimer ? (
                <div className="time-remaining-container">
                  <div className="timer-animation lottie-width">
                    <Lottie loop={true} animationData={timerAnimationJson} autoplay={true} className={"timer-anim-width"} rendererSettings="xMidYMid slice" />
                  </div>
                  <Text className="deadline-text">{getDeadlineData()}</Text>
                </div>
              ) : (
                <></>
              )}
            </div>
          )}
        </div>
      </Card>
    </>
  );

  function getDeadlineData() {
    if (statusDetails?.isOverDue) {
      const deadlineString = (
        <span>
          Due {deadline}{" "}
          {statusDetails?.isOverDue && !props?.actionTab && (
            <>
              <span>-</span>{" "}
              <a className={"secondary-color"} onClick={() => props.onTabSwitch("3")}>
                Cancel
              </a>
            </>
          )}
        </span>
      );
      return deadlineString;
    } else {
      return `Due ${deadline}`;
    }
  }

  function fetchActionDate(offerDetail, currentStatus) {
    const actionRow = offerDetail?.videoRevisions?.filter((r) => r.currentStatus === currentStatus);
    return actionRow[actionRow.length - 1]?.updatedAt;
  }

  function getDetailsFromStatusName(status_name) {
    let status = "",
      details = "",
      showActivity = 0, // 0 : Nothing   |   1 : Animation   |   2: Button
      showTimer = true,
      buttonText = "";

    switch (status_name) {
      case "Offer Sent":
        status = "Offer Sent";
        showActivity = props?.actionTab === true ? 1 : 0;
        showTimer = true;
        // buttonText = "Cancel";
        details = `${creatorName} has received your offer. They have to accept the offer within the time limit, otherwise the order will be cancelled. `;
        break;
      case "Offer Received":
        status = "Offer Received";
        showActivity = props?.actionTab === true ? 1 : 2;
        showTimer = true;
        details = `You have received an offer! Review the brief and approve it if it seems right for you.`;
        buttonText = "Respond";
        break;
      case "Offer Rejected":
        status = `Offer Rejected by ${creatorName}`;
        showTimer = false;
        break;
      case "Offer Cancelled":
        status = `Offer Cancelled by ${marketerName}`;
        showTimer = false;
        break;
      case "Offer Expired":
        status = `Offer Expired`;
        showTimer = false;
        break;
      case "Shipment-In-Progress":
        status = "Shipment-In-Progress";
        showActivity = 1;
        details = `${marketerName} has received the shipping address and will soon send the shipment.`;
        break;
      case "Shipment Required":
        status = "Shipment Required";
        showActivity = props?.actionTab === true ? 1 : 2;
        details = `Please enter the shipment details`;
        buttonText = "Submit";
        break;
      case "Shipment-In-Transit":
        status = "Shipment-In-Transit";
        showActivity = 1;
        details = `${creatorName} will soon the receive the shipment and acknowledge for the same.`;
        break;
      case "Mark as Received":
        status = "Mark as Received";
        buttonText = "Received";
        showActivity = props?.actionTab === true ? 1 : 2;
        details = `${marketerName} has sent you this shipment. Please acknowledge for the same.`;
        break;
      case "Video-In-Progress":
        status = "Video-In-Progress";
        showActivity = 1;
        details = `${creatorName} has accepted your offer and will submit a video within the time limit.`;
        break;
      case "Video Required":
        status = "Video Required";
        showActivity = props?.actionTab === true ? 1 : 2;
        details = `Please create and upload the video by the deadline.`;
        buttonText = "Submit Video";
        break;
      case "Review Required":
        status = "Review Required";
        showActivity = props?.actionTab === true ? 1 : 2;
        details = `${creatorName} has submitted their video. Please review it.`;
        buttonText = "Review Video";
        break;

      case "Review In-Progress":
        status = "Review In-Progress";
        showActivity = 1;
        details = `Please wait while ${marketerName} reviews your video.`;
        break;
      case "Revision Requested":
        status = "Revision Requested";
        showActivity = props?.actionTab === true ? 1 : 2;
        buttonText = "Submit Video";
        details = `${marketerName} has provided you feedback on your video. Please submit another video. `;
        break;
        case "Tiktok Upload-In-Progress":
          status = "Tiktok Upload-In-Progress";
          showActivity = 1;
          details = `Please wait while ${creatorName} update the tiktok url.`;
          break;
      case "Tiktok Url Required":
        status = "Tiktok Url Required";
        showActivity = props?.actionTab === true ? 1 : 2;
        buttonText = "Update Tiktok Url";
        details = `${marketerName} is waiting for the tiktok url. `;
      break;
      case "Video Completed":
        status = "Video Completed";
        showActivity = props?.actionTab === true ? 1 : 2;
        buttonText = "View Video";
        showTimer = false;
        break;
    }
    return { status, details, showActivity, showTimer, buttonText };
  }
}

export default OfferStatusHelper;
