import { apiCall } from "../../modules/api-call";
import { CONFIG } from "../../config/config";

export const createInstantCampaignRequest = (data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: "CREATE_INSTANT_CAMPAIGN_REQUEST", payload: {} });
      const { data: response } = await apiCall({
        method: "POST",
        url: CONFIG.BACKEND_API_URL + "user/instant/campaign",
        auth: 1,
        data,
      });
      if (response && response.statusCode === 201 && response.error === false) {
        dispatch({ type: "CREATE_INSTANT_CAMPAIGN_REQUEST_SUCCESS", payload: response });
      } else {
        dispatch({ type: "CREATE_INSTANT_CAMPAIGN_REQUEST_ERROR", payload: response });
      }
    } catch (err) {
      dispatch({ type: "CREATE_INSTANT_CAMPAIGN_REQUEST_ERROR", payload: { message: err?.response?.message ? err?.response?.message :err.message} });
    }
  };
};

export const createInstantCampaignReset = () => {
  return async (dispatch) => {
    dispatch({ type: "CREATE_INSTANT_CAMPAIGN_REQUEST_RESET" });
  };
};
