import React from "react";
import ReactDOM from "react-dom";
import { ThemeSwitcherProvider } from "react-css-theme-switcher";
import "./index.scss";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./redux/store";
import { createRoot } from "react-dom/client";

/* const themes = {
  override: `${process.env.PUBLIC_URL}/master-override.css`
};
*/

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <React.StrictMode>
    {/* <ThemeSwitcherProvider themeMap={themes} defaultTheme="override" insertionPoint="styles-insertion-point"> */}
    <Provider store={store}>
      <Router>
        <App />
      </Router>
    </Provider>
    {/* </ThemeSwitcherProvider> */}
  </React.StrictMode>
  //   document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
