import React, { useEffect, useState } from "react";
import styles from "./index.module.scss";
import { CONFIG } from "../../../../config/config";
import { apiCall } from "../../../../modules/api-call";
import { Spin } from "antd";
import { calculateMetricsForMultipleObjectives } from "../../../../helpers/calculateMetricsForMultipleObjectives";
import "overlayscrollbars/overlayscrollbars.css";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";

const TABLE_FIRST_ROW = ["Name", "Status", "Impressions", "CPM", "CPC", "Conversion", "CPA", "CTR"];

const EMPTY_METRICS = [
  {
    value: 0,
    label: "Impressions",
  },
  {
    value: 0,
    label: "CPM",
    moneyIndicator: true,
  },
  {
    value: 0,
    label: "CPC",
    moneyIndicator: true,
  },
  {
    value: 0,
    label: "Conversion",
  },
  {
    value: 0,
    label: "CPA",
    moneyIndicator: true,
  },
  {
    value: 0,
    label: "CTR",
    relativeIndicator: true,
  },
];

const sumMetrics = (creativeMetrics, filters, ads) => {
  if (filters.length === 0) {
    return [];
  }

  const filteredAds = filters.length === 3 ? ads : ads.filter((ad) => filters.includes(ad.filter));

  if (filters.length === 1) {
    return creativeMetrics[filters[0]] || EMPTY_METRICS;
  } else {
    return calculateMetricsForMultipleObjectives(filteredAds, "creativesBreakdown");
  }
};

const CreativesInfo = ({ isOpen, startDate, endDate, boostId }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const [activeFilters, setActiveFilters] = useState(["awareness", "conversion"]);

  const handleClickFilter = (type) => {
    setActiveFilters((prev) => {
      if (prev.includes(type)) {
        if (prev.length === 1) {
          return prev;
        }
        return prev.filter((filterType) => filterType !== type);
      } else {
        return [...prev, type];
      }
    });
  };

  const getCreativesMetrics = async () => {
    setLoading(true);
    let url = CONFIG.BACKEND_API_URL + `user/evergreeen-boost/creatives-metrics?boostId=${boostId}`;
    if (startDate && endDate) {
      url = url + `&startDate=${startDate}&endDate=${endDate}`;
    }
    try {
      const { data: response } = await apiCall({
        method: "GET",
        url,
        auth: 1,
      });
      setData(response?.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const summedCreativeMetrics =
    data?.length &&
    data.map((creative) => ({
      ...creative,
      creativeMetrics: sumMetrics(creative.creativeMetrics, activeFilters, creative.ads),
    }));


  useEffect(() => {
    isOpen && getCreativesMetrics();
  }, [isOpen]);

  return (
    <div>
      <div className={styles.header}>
        <p className={styles.title}>Who did we reach?</p>
        <div className={styles.filters}>
          <button
            onClick={() => handleClickFilter("awareness")}
            className={`${styles.button} ${
              activeFilters.includes("awareness") ? styles.active : null
            }`}
          >
            Awareness Ads
          </button>
          <button
            onClick={() => handleClickFilter("conversion")}
            className={`${styles.button} ${
              activeFilters.includes("conversion") ? styles.active : null
            }`}
          >
            Conversion Ads
          </button>
          <button
            onClick={() => handleClickFilter("follows")}
            className={`${styles.button} ${
              activeFilters.includes("follows") ? styles.active : null
            }`}
          >
            Follower Ads
          </button>
        </div>
      </div>
      {loading ? (
        <div className={styles.loaderWrapper}>
          <Spin size="large" />
        </div>
      ) : (
        !!summedCreativeMetrics?.length && (
          <div className={styles.tableWrapper}>
            <OverlayScrollbarsComponent className={styles.table}>
              <div className={styles.tableRow}>
                {TABLE_FIRST_ROW.map((item) => (
                  <div key={item} className={styles.tableCell}>
                    <p>{item}</p>
                  </div>
                ))}
              </div>
              {summedCreativeMetrics?.map((creative) => (
                <div className={styles.tableRow} key={creative.id}>
                  <div className={styles.tableCell}>
                    <p>{creative.name}</p>
                  </div>
                  <div className={`${styles.tableCell} ${styles.tableCellIndicator}`}>
                    <p>{creative.status}</p>
                  </div>
                  {creative.creativeMetrics.map((metric) => (
                    <div
                      className={`${styles.tableCell} ${styles.tableCellIndicator}`}
                      key={metric.label}
                    >
                      <p>
                        {metric?.moneyIndicator
                          ? `$${Number(metric.value).toFixed(2)}`
                          : metric?.relativeIndicator
                          ? Number(metric.value).toFixed(2)
                          : metric.value}
                      </p>
                    </div>
                  ))}
                </div>
              ))}
            </OverlayScrollbarsComponent>
          </div>
        )
      )}
    </div>
  );
};

export default CreativesInfo;
