import React from "react";
import { Tag } from "antd";
const { CheckableTag } = Tag;
const Tags = (props) => {
  const items = props.tagsData,
    showCount = props.showItems,
    finalItems = items.slice(0, showCount);

  return (
    finalItems &&
    finalItems.map((item, i) => {
      return (
        <CheckableTag checked key={i}>
          {item}
        </CheckableTag>
      );
    })
  );
};
export default Tags;
