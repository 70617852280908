import React from "react";
import "./index.scss";
import { Typography, Card } from "antd";
import ImageRenderer from "../../CommonWidgets/ImageRenderer";
import DeliverableContainer from "../../CommonWidgets/DeliverableContainer";
import moment from "moment";
const { Text, Title } = Typography;

function TimelineTile(props) {
  const item = props?.statusItem;

  return (
    <Card className="timeline-status-helper-container" bordered={false}>
      <div className={`offer-status-helper-tile ${item.className}`}>
        <div className="image-bg">
          <ImageRenderer imageUrl={props?.profileImage} isAvatar={false} className={"timeline-img"} />
        </div>
        <div className="status-text justify-center">
          <Title level={4}>{item?.statusText}</Title>
          <Text>{moment(item?.updatedAt).utc().fromNow()}</Text>
          <Text className="secondary-text">{moment(item?.updatedAt).format("MM/DD/YY · H:mm A")}</Text>
        </div>
      </div>

      {item?.content && (
        <div className={"mt3"}>
          <DeliverableContainer content={item?.content} contentType={item?.contentType} />
          {item?.subContent && (
          <div className={"mt3"}>
              {item?.subContent}
          </div>
          )}
        </div>
        
        
      )}
    </Card>
  );
}

export default TimelineTile;
