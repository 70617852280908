import axios from "axios";
import { CONFIG } from "../config/config";
import { callLogout } from "../helper";

export const apiCall = async ({ method, url, headers, data, auth }) => {
  try {
    const axiosConfig = {
      method,
      url,
      headers,
    };

    if (auth === 1) {
      let token = JSON.parse(localStorage.getItem(CONFIG.LOCALSTORAGE_USER))?.token;
      if (token) {
        axiosConfig.headers = { ...axiosConfig.headers, Authorization: `Bearer ${token}` };
      }
    } else if (auth === 2) {
      // let token = JSON.parse(localStorage.getItem(CONFIG.LOCALSTORAGE_USER)).refresh_token;
      // data.refreshToken = token;
    }
    if (method === "post" || "patch") axiosConfig.data = data;
    const response = await axios(axiosConfig);
    return response;
  } catch (err) {
    if (err?.message === "Network Error") {
      throw new Error(err);
    } else {
      console.log("error response status");
      console.log(err.response.status);
      const isModalShown = window.localStorage.getItem("login");
      if (!isModalShown || isModalShown === null) {
        window.localStorage.setItem("login", true);
      }
      if (err?.response?.status === 401) {
        callLogout();
      }

      //  window.location.href="/";

      //  throw new Error(err);
    }
  }
};
