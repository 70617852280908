const stripeCountryList = [ 
     {"name": "Australia", "code": "AU"}, 
     {"name": "Austria", "code": "AT"}, 
     {"name": "Belgium", "code": "BE"}, 
     {"name": "Bulgaria", "code": "BG"}, 
     {"name": "Canada", "code": "CA"}, 
     {"name": "Cyprus", "code": "CY"}, 
     {"name": "Czech Republic", "code": "CZ"}, 
     {"name": "Denmark", "code": "DK"}, 
     {"name": "Estonia", "code": "EE"}, 
     {"name": "Finland", "code": "FI"}, 
     {"name": "France", "code": "FR"}, 
     {"name": "Germany", "code": "DE"}, 
     {"name": "Greece", "code": "GR"}, 
     {"name": "Hong Kong", "code": "HK"}, 
     {"name": "Ireland", "code": "IE"}, 
     {"name": "Italy", "code": "IT"}, 
     {"name": "Japan", "code": "JP"}, 
     {"name": "Latvia", "code": "LV"}, 
     {"name": "Lithuania", "code": "LT"}, 
     {"name": "Luxembourg", "code": "LU"}, 
     {"name": "Malta", "code": "MT"}, 
     {"name": "Netherlands", "code": "NL"}, 
     {"name": "New Zealand", "code": "NZ"}, 
     {"name": "Norway", "code": "NO"}, 
     {"name": "Poland", "code": "PL"}, 
     {"name": "Portugal", "code": "PT"}, 
     {"name": "Romania", "code": "RO"}, 
     {"name": "Singapore", "code": "SG"}, 
     {"name": "Slovakia", "code": "SK"}, 
     {"name": "Slovenia", "code": "SI"}, 
     {"name": "Spain", "code": "ES"}, 
     {"name": "Sweden", "code": "SE"}, 
     {"name": "Switzerland", "code": "CH"}, 
     {"name": "United Kingdom", "code": "GB"},
     {"name": "United States", "code": "US"}
  ];
   
   export default stripeCountryList;