import React from "react";
import { Button, Checkbox, Form, Input } from "antd";
import { OBJECTIVES } from "../../../../../constants";
import styles from "./index.module.scss";

const AddCreativeModal = ({ handleAddCreative, fullPackage }) => {
  const onFinish = (values) => {
    const creativeToAdd = {
      adIds: [],
      authCode: values.newCreativeAuthCode,
      statusByTiktok: "submitted",
      statusByUser: "live",
      nameByUser: values?.newCreativeName || "",
      objectives: values.newCreativeObjectives,
    };

    handleAddCreative(creativeToAdd);
  };

  return (
    <Form
      name="creativeForm"
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
      autoComplete="off"
    >
      <div className={styles.creativeField}>
        <label className={styles.label}>Authorization code</label>
        <Form.Item
          name="newCreativeAuthCode"
          rules={[
            {
              required: true,
              message: "Please input Auth code",
            },
          ]}
        >
          <Input className={styles.input} />
        </Form.Item>
      </div>
      <div className={styles.creativeField}>
        <label className={styles.label}>Ad name</label>
        <Form.Item name="newCreativeName">
          <Input className={styles.input} />
        </Form.Item>
      </div>
      <div className={styles.creativeField}>
        <label className={styles.label}>Objectives</label>
        <Form.Item
          name="newCreativeObjectives"
          rules={[
            {
              required: true,
              message: "Please select at least one objective",
            },
          ]}
        >
          <Checkbox.Group
            className={styles.objectivesCheckboxes}
            options={OBJECTIVES.map((val) => ({
              value: val,
              label: val,
              disabled: val === "follows" && !fullPackage,
            }))}
          />
        </Form.Item>
      </div>

      <Form.Item>
        <Button className={styles.addButton} type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default AddCreativeModal;
