import { apiCall } from "../../modules/api-call";
import { CONFIG } from "../../config/config";
import { checkIfUserLoggedIn } from "../../helper";

export const viewProfileRequest = () => {
  return async (dispatch) => {
    try {
      if (checkIfUserLoggedIn()) {
        let userType = (checkIfUserLoggedIn().userType === "creator") ? "creator" : "user";
        dispatch({ type: "VIEW_PROFILE_REQUEST", payload: {} });
        const { data: response } = await apiCall({
          method: "get",
          url: CONFIG.BACKEND_API_URL + `${userType}`,
          auth: 1,
        });
         if (response && response.statusCode === 200 && response.error === false) {
          dispatch({ type: "VIEW_PROFILE_REQUEST_SUCCESS", payload: response });
        } else {
          dispatch({ type: "VIEW_PROFILE_REQUEST_ERROR", payload: response });
        }
      }
    } catch (err) {
      dispatch({ type: "VIEW_PROFILE_REQUEST_ERROR", payload: { message: err?.response?.message ? err?.response?.message :err.message} });
    }
  };
};

export const editProfileRequest = (profileDetails) => {
  return async (dispatch) => {
    try {
      dispatch({ type: "EDIT_PROFILE_REQUEST", payload: profileDetails });
      let userType = (checkIfUserLoggedIn().userType === "creator") ? "creator" : "user";
      const { data: response } = await apiCall({
        method: "patch",
        url: CONFIG.BACKEND_API_URL + `${userType}`,
        data: profileDetails,
        auth: 1,
      });
      if (response && response.statusCode === 200 && response.error === false) {
        dispatch({ type: "EDIT_PROFILE_REQUEST_SUCCESS", payload: response });
      } else {
        dispatch({ type: "EDIT_PROFILE_REQUEST_ERROR", payload: response });
      }
    } catch (err) {
      dispatch({ type: "EDIT_PROFILE_REQUEST_ERROR", payload: { message: err?.response?.message ? err?.response?.message :err.message } });
    }
  };
};

export const viewProfileReset = () => {
  return async (dispatch) => {
    dispatch({ type: "VIEW_PROFILE_REQUEST_RESET" });
  };
};


export const editProfileReset = () => {
  return async (dispatch) => {
    dispatch({ type: "EDIT_PROFILE_REQUEST_RESET" });
  };
};
