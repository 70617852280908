import React, { useState, useEffect } from "react";
import "./index.scss";
import { Select, Button, Form, DatePicker, Typography } from "antd";
import moment from "moment";
import FloatInput from "../../CommonWidgets/FloatInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/pro-solid-svg-icons";
import Colors from "../../../theme/Colors";
const { Text, Link } = Typography;
const { Option } = Select;


const SoundFilterModal = (props) => {
  const [hasFilterSoundFormValue, setFilterSoundFormValue] = useState({
      genre: 0,
      soundTitle: "",
    }),
    handleChange = (fieldName, e) => {
        let formData = { ...hasFilterSoundFormValue };
        if (e?.target?.name) {
            setFilterSoundFormValue({ ...formData, [e.target.name]: e.target.value });
        }
         else {
            setFilterSoundFormValue({ ...formData, [fieldName]: e });
        }
      },
    genreOptions = ["Hip Hop", "Pop", "Rock", "Indie", "Alternative", "Metal", "R&B", "EDM", "Punk", "Reggae", "Funk", "Ska", "World Music", "Dubstep", "Latin", "Trap", "Country", "Americana", "Folk", "Jazz", "Blues", "Ambient", "Classical", "Hardcore", "Afrobeat"],
    [showAdvancedSearch, setShowAdvancedSearch] = useState(false),
    handleClick = () => {
      setShowAdvancedSearch(!showAdvancedSearch);
    };

    useEffect(() => {
        setFilterSoundFormValue(hasFilterSoundFormValue);
        console.log("hasFilterSoundFormValue", hasFilterSoundFormValue);
        props.filterSoundData(hasFilterSoundFormValue);
    
      }, [hasFilterSoundFormValue]);

  return (
    <div className="search-box-container">
      <Form disabled={!props?.isSearchEnabled}>
        <div className={`search-title-container`}>
          <div className="search-title-item">
            <Form.Item name="soundTitle" hasFeedback>
              <FloatInput label="Search" values={hasFilterSoundFormValue.soundTitle} type="text" vType="search" btnType="primary" onClick={() => props?.startSearch({ ...hasFilterSoundFormValue, soundTitle: hasFilterSoundFormValue.soundTitle })} size="large" placeholder="Sound Title" name="soundTitle" onChange={(e) => handleChange("soundTitle", e.target.value)} />
            </Form.Item>
          </div>
          <div className="search-title-item">
            <Button className="btn-filter" type={showAdvancedSearch ? "default" : "primary"} block onClick={handleClick}>
              <FontAwesomeIcon icon={faFilter} />
            </Button>
          </div>
        </div>
        <div className={`slide-up ${showAdvancedSearch && "active"}`}>
          <div className={`sound-filter-box-container`}>
            <div className="sound-filter-container">
              {/* <div className="filter-item">
                <div className="section-title">
                  <div>
                    <Text level={3} className="fw-600">
                      {"When?"}
                    </Text>
                  </div>
                  <div>
                  <a
                    onClick={() => {
                        handleChange("when","");
                    }}
                  >
                    <Link style={{ color: Colors.primaryColor }}>Clear</Link>
                  </a>
                  </div>
                </div>
                <div>
                  <Select defaultValue="Is Before" className="w-full" size={"large"} onChange={(value)=>handleChange("when", value)} values={hasFilterSoundFormValue.when}>
                    <Option value="Is Before">Is Before</Option>
                    <Option value="Is After">Is After</Option>
                  </Select>
                </div>
              </div>
              <div className="filter-item">
                <div className="section-title">
                  <div>
                    <Text level={3} className="fw-600">
                      {"Pick Date"}
                    </Text>
                  </div>
                  <div>
                  <a
                    onClick={() => {
                        onDateChange("","");
                    }}
                  >
                    <Link style={{ color: Colors.primaryColor }}>Clear</Link>
                  </a>
                  </div>
                </div>
                <div>
                  <FloatInput size="large" placeholder="Select Date" disabled={false} label="Pick a date" name="createdAt" vType="date" className="w-full" values={hasFilterSoundFormValue?.createdAt ? moment(hasFilterSoundFormValue?.createdAt).format("MM-DD-YYYY") : ""} onChange={onDateChange} />
                </div>
              </div>
                  */}
              <div className="filter-item">
                <div className="section-title">
                  <div>
                    <Text level={3} className="fw-600">
                      {"Genre"}
                    </Text>
                  </div>
                  <div>
                  <a
                    onClick={() => {
                        handleChange("genre",0)
                    }}
                  >
                    <Link style={{ color: Colors.primaryColor }}>Clear</Link>
                  </a>
                  </div>
                </div>
                <div>
                  <Select className="w-full" placeholder="Select Genre" size={"large"} onChange={(value)=>handleChange("genre",value)} value={hasFilterSoundFormValue.genre}>
                  <Option value={0} key={0}>Select Genre </Option>
                    {genreOptions.map((item, i) => {
                      return (
                      
                        <Option value={item} key={item}>
                          {item}
                        </Option>
                      );
                    })}
                  </Select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
};
export default SoundFilterModal;
