import React, { useState, useEffect } from "react";
import { Typography, Button } from "antd";
import { LeftArrow, RightArrow } from "../../CommonWidgets/FontIcons";
import ListShippingAddress from "../../ShippingAddress/ListShippingAddress";
import "./index.scss";
const { Title, Text } = Typography;

const CreatorOfferAction = (props) => {
    const [clicked, setClicked] = useState(""),
        [showActionButtons, setShowActionButtons] = useState(true),
        validateHasShipping = props?.needShipping ? true : false,
        [disableAcceptButton, setDisableAcceptButton] = useState(validateHasShipping),
        [selectedShippingAddressId, setSelectedShippingAddressId] = useState(""),
        clickedAction = (data) => {
            setClicked(data?.newStatus);
            switch (data?.newStatus) {
                case "rejected":
                    props.creatorOfferAction(data?.newStatus, { message: data.message })
                    break;
                case "pending-shipment":
                case "video-in-progress": //old: video-in-progress
                    props.creatorOfferAction(data?.newStatus);
                    break;
                default:
                    break;
            }
            return true;
        },
        offerNextStatus = props?.needShipping ? "pending-shipment" : "video-in-progress";


    useEffect(() => {
        setDisableAcceptButton(disableAcceptButton);
    }, [disableAcceptButton]);

    useEffect(() => {
        setSelectedShippingAddressId(selectedShippingAddressId);
    }, [selectedShippingAddressId]);

    return (
        <>

            <div>
                <Title level={4}>{"Do you want to accept this offer?"}</Title>
                {props?.needShipping === true && (
                    <div className="mb3">
                        <ListShippingAddress showCreatorActionButtons={(val) => setShowActionButtons(val)} setSelectedAddressRow={row => props?.setSelectedAddressRow(row)} isAcceptButtonEnabled={() => setDisableAcceptButton(false)} selectedShippingAddressId={(id) => setSelectedShippingAddressId(id)} />
                    </div>
                )}
                <Text>You will be required to submit the video within the allocated time</Text>
                {showActionButtons && (
                    <div className="offer-action">
                        <div>
                            <Button type={"default"} loading={clicked === "rejected" ? props?.loaderStatus : false} onClick={() => clickedAction({ newStatus: "rejected", message: "rejected" })}><LeftArrow /> Reject</Button>
                        </div>
                        <div>
                            <Button type={"primary"} disabled={disableAcceptButton} loading={clicked === offerNextStatus ? props?.loaderStatus : false} onClick={() => clickedAction({ newStatus: offerNextStatus })}>Accept<RightArrow /></Button>
                        </div>
                    </div>
                )}

            </div>
        </>
    );
}

export default CreatorOfferAction; 