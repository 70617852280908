import React from "react";
import { Typography } from "antd";

const { Text } = Typography;

const TrackingInformation = ({ item }) => {
  const { trackingId, shippingCompanyName } = item;
  return (
    <>
      <div>
        <Text> Tracking ID: </Text>
        <Text className="label-text">{trackingId}</Text>
      </div>
      <div>
        <Text> Shipping Company Name: </Text>
        <Text className="label-text">{shippingCompanyName}</Text>
      </div>
    </>
  );
};

export default TrackingInformation;
