export default (
  state = {
    loading: false,
    userBoosts: null,
    error: null,
  },
  action
) => {
  switch (action.type) {
    case "SET_USER_BOOSTS_LOADING":
      return { ...state, loading: action.payload.loading };
    case "SET_USER_BOOSTS":
      return { ...state, loading: false, userBoosts: action.payload };
    case "SET_USER_BOOSTS_ERROR":
      return { ...state, loading: false, userBoosts: null, error: action.payload };
    default:
      return state;
  }
};
