import React, { useState, useEffect, forwardRef } from "react";
import { Space, Form, Button, Row, Col, Select } from "antd";
import FloatInput from "../../CommonWidgets/FloatInput";
import DeliverableContainer from "../../CommonWidgets/DeliverableContainer";
import "../../assets/styles/modal.scss";
import { isFormInValid } from "../../../helper";
const { Option } = Select;

const MarketerUpdateShippingInfo = forwardRef((props, ref) => {
  const [form] = Form.useForm(),
    newStatus = "pending-receipt",
    [clicked, setClicked] = useState(""),
    [formError, setFormError] = useState(false),
    clickedAction = () => {
      setClicked(newStatus);
      onManageUpdateShippingInfo();
    },
    companyList = [
      {
        name: "Select Company",
        code: "",
      },
      {
        name: "UPS",
        code: "UPS",
      },
      {
        name: "FedEx",
        code: "FedEx",
      },
      {
        name: "USPS",
        code: "USPS",
      },
    ],
    handleSelectCompanyChange = (value) => {
      let formData = { ...hasUpdateShippingInfoFormValue };
      setUpdateShippingInfoFormValue({ ...formData, ["shippingCompanyName"]: value });
    },
    onManageUpdateShippingInfo = () => {
      form
        .validateFields()
        .then((values) => {
          if (!isFormInValid(form)) {
            //   props.loadingUpdate(true);
            props.marketerShippingAction(newStatus, hasUpdateShippingInfoFormValue);
          }
        })
        .catch((info) => {
          setFormError(true);
        });
    },
    [hasUpdateShippingInfoFormValue, setUpdateShippingInfoFormValue] = useState({
      trackingId: "",
      shippingCompanyName: "",
    }),
    onChange = (e) => {
      let formData = { ...hasUpdateShippingInfoFormValue };
      setUpdateShippingInfoFormValue({ ...formData, [e.target.name]: e.target.value });
    };

  useEffect(() => {
    setUpdateShippingInfoFormValue(hasUpdateShippingInfoFormValue);
  }, [setUpdateShippingInfoFormValue]);

  return (
    <DeliverableContainer
      contentType="Shipping Details"
      content={
        <div className="mt3">
          <Form
            name="updateShippingInfo"
            form={form}
            initialValues={{
              remember: true,
            }}
          >
            <Space direction="vertical" className="w-full">
              <Row gutter={{ xs: 8, sm: 16, md: 20, lg: 20 }}>
                <Col span={18}>
                  <Form.Item
                    name={["shippingInfo", "trackingId"]}
                    initialValue={hasUpdateShippingInfoFormValue.trackingId}
                    rules={[
                      {
                        required: true,
                        message: "Please enter the tracking id",
                      },
                    ]}
                    hasFeedback
                  >
                    <FloatInput size="large" label="Tracking ID" placeholder="#12345" type="text" name="trackingId" values={hasUpdateShippingInfoFormValue.trackingId} onChange={(e) => onChange(e)} />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name={["shippingInfo", "shippingCompanyName"]}
                    initialValue={hasUpdateShippingInfoFormValue.shippingCompanyName}
                    rules={[
                      {
                        required: true,
                        message: "Please enter the shipping company name",
                      },
                    ]}
                    hasFeedback
                  >
                    <Select size={"large"} onChange={handleSelectCompanyChange} defaultValue={hasUpdateShippingInfoFormValue.shippingCompanyName}>
                      {companyList &&
                        companyList.map((item) => {
                          return (
                            <Option key={item.code} value={item.code}>
                              {item.name}
                            </Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item>
                <div className="offer-action">
                  <Button type="primary" loading={clicked === newStatus && !formError ? props?.loaderStatus : false} onClick={() => clickedAction(newStatus)}>
                    {" "}
                    Submit
                  </Button>
                </div>
              </Form.Item>
            </Space>
          </Form>
        </div>
      }
    />
  );
});

MarketerUpdateShippingInfo.displayName = "MarketerUpdateShippingInfo";
export default MarketerUpdateShippingInfo;
