import React from "react";
import { Typography } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/pro-solid-svg-icons";
import "./index.scss";

const { Title } = Typography;

const ModalHeader = (props) => {
  return (
    <Title level={5}>
      <span>
        <FontAwesomeIcon icon={props?.titleIcon} /> {props?.modalTitle}
      </span>
      <div className="float-right">
        {" "}
        <FontAwesomeIcon icon={faTimesCircle} className="close-icon" onClick={() => props.close()} />
      </div>
    </Title>
  );
};
export default ModalHeader;
