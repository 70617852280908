export default (
  state = {
    loading: false,
    payload: {},
    error: null,
  },
  action
) => {
  switch (action.type) {
    case "VIEW_PROFILE_REQUEST":
    case "EDIT_PROFILE_REQUEST":
      return { ...state, loading: true, payload: {}, error: null };
    case "VIEW_PROFILE_REQUEST_SUCCESS":
    case "EDIT_PROFILE_REQUEST_SUCCESS":
      return { ...state, loading: false, payload: action.payload, error: null };
    case "VIEW_PROFILE_REQUEST_ERROR":
    case "EDIT_PROFILE_REQUEST_ERROR":
      return { ...state, loading: false, payload: {}, error: action.payload };
    case "EDIT_PROFILE_REQUEST_RESET":
      return { loading: false, payload: {}, error: null };
    default:
      return state;
  }
};
