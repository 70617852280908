import React, { useRef, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Typography, Spin } from "antd";
import { getShippingAddressesRequest } from "../../../redux/actions/list-shipping-address";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAddressCard, faCancel, faSave } from "@fortawesome/pro-solid-svg-icons";
import AntSpinner from "../../CommonWidgets/AntSpinner";
import "./index.scss";
import AddShippingAddress from "../AddShippingAddress";
import NoShippingAddressDataFound from "../../Result/NoShippingAddressDataFound";
import ShippingAddressFormatter from "../ShippingAddressFormatter";
const { Title } = Typography;

const ListShippingAddress = (props) => {
  const dispatch = useDispatch(),
    cRef = useRef(),
    [shippingAddressData, setShippingAddressData] = useState([]),
    [selectedShippingAddressId, setSelectedShippingAddressId] = useState(""),
    [isDataLoading, setDataLoading] = useState(false),
    [isLoading, setLoading] = useState(false),
    [innerStep, setInnerStep] = useState(1),
    { listShippingAddressData } = useSelector((state) => ({ listShippingAddressData: state.listShippingAddressData })),
    updateLoadingData = (isLoad) => {
      setDataLoading(isLoad);
    },
    updateShippingAddressData = (data) => {
      if (data[0]) {
        props?.setSelectedAddressRow(data[0]);
        setSelectedShippingAddressId(data[0]._id);
        setShippingAddressData(data);
        props.selectedShippingAddressId(data[0]?._id);
        props.showCreatorActionButtons(true);
        props?.isAcceptButtonEnabled();
        setInnerStep(1);
    
      }
    },
    moveToAddStep = (nextStep) => {
      props.showCreatorActionButtons(false);
      setInnerStep(nextStep);
    }, 
    moveToListStep = (prevStep) => {
      props.showCreatorActionButtons(true);
      setInnerStep(prevStep);
    };

  useEffect(() => {
    setLoading(true);
    dispatch(getShippingAddressesRequest());
  }, []);

  useEffect(() => {
    if (!listShippingAddressData.error && Object.keys(listShippingAddressData?.payload).length) {
      setShippingAddressData(listShippingAddressData?.payload?.data);
      setLoading(false);

      if (props.from) {
        setSelectedShippingAddressId(listShippingAddressData?.payload?.data[0]._id);
      }
    } else {
      if (listShippingAddressData?.error?.message) {
        setLoading(false);
      }
    }
  }, [listShippingAddressData]);

  useEffect(() => {
    setShippingAddressData(shippingAddressData);
  }, [shippingAddressData]);

  return (
    <>
      {innerStep === 1 ? (
        <>
          <div className={"mb3"}>
            {props.from === "offer" ? (
              <Title level={3}> Shipping Address</Title>
            ) : (
              <div className="shipping-title-container">
                <div className="shipping-item">
                  <span className="label-text">SHIPPING</span>
                  <p>Please select an address where the product can be shipped to you</p>
                </div>
                <div className="shipping-item">
                  <Button type="primary" size="large" onClick={() => moveToAddStep(2)}>
                    <FontAwesomeIcon icon={faAddressCard} /> Add a Shipping Address
                  </Button>
                </div>
              </div>
            )}
          </div>

          <Spin indicator={AntSpinner} spinning={isLoading} size={"large"}>
            {shippingAddressData && shippingAddressData.length > 0 ? (
              <>
                <ShippingAddressFormatter
                  addressData={shippingAddressData}
                  setValue={(value) => {
                    setSelectedShippingAddressId(value);
                    props?.isAcceptButtonEnabled();
                    props.selectedShippingAddressId(value);
                  }}
                  from={"offer"}
                  value={selectedShippingAddressId}
                  selectedRow={(row) => props?.setSelectedAddressRow(row)}
                />
              </>
            ) : (
              listShippingAddressData.error && <NoShippingAddressDataFound showContent={isLoading} startAdd={() => setInnerStep(2)} />
            )}
          </Spin>
        </>
      ) : (
        <>
          <Title level={3}>
            <span> Add Shipping Address</span>
          </Title>
          <AddShippingAddress saveShippingAddress={true} updateData={updateShippingAddressData} from={"offer"} ref={cRef} loadingUpdate={updateLoadingData} />

          <div className="shipping-action">
            <div>
              <Button size="large" type="default" onClick={() => moveToListStep(1)}>
                <FontAwesomeIcon icon={faCancel} /> Cancel
              </Button>
            </div>
            <div>
              <Button size="large" type="primary" loading={isDataLoading} onClick={() => cRef.current.onAddShippingAddress()}>
                <FontAwesomeIcon icon={faSave} /> Save
              </Button>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ListShippingAddress;
