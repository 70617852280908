import React, { useState, useEffect } from "react";
import "./index.scss";
import { Select, Button, Form, Typography, Checkbox } from "antd";
import FloatInput from "../CommonWidgets/FloatInput";
import { engagementRates, searchFromRange, searchToRange, searchFromCostRange, searchToCostRange } from "../../config/searchRange";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/pro-solid-svg-icons";
import FindCreatorSearchRangeTile from "../FindCreatorSearchRangeTile/index";
import Colors from "../../theme/Colors";
import AnimateHeight from "react-animate-height";


const { Text, Link } = Typography;
const FindCreatorSearch = (props) => {
  const [creatorTagList, setTags] = useState([]),
    [height, setHeight] = useState(0),
    [selectedTags, setSelectedTags] = useState([]),
    [isFavorite, setFavorite] = useState(false),
    [hasSearchFormValue, setSearchFormValue] = useState({
      userName: "",
      tags: "",
      engagementRate: "",
      followersFrom: "",
      followersTo: "",
      averageViewsFrom: "",
      averageViewsTo: "",
      costFrom: "",
      costTo: "",
      isFavorite: false,
    }),
    handleChange = (fieldName, e) => {
      let formData = { ...hasSearchFormValue };
      if (e?.target?.name) {
        setSearchFormValue({ ...formData, [e.target.name]: fieldName == "isFavorite" ? e.target.checked : e.target.value });
        if (formData.name == "tags") {
          setSelectedTags(e?.target?.value);
        }
        if (fieldName == "isFavorite") {
          console.log("e.target.checked", e.target.checked);
          setFavorite(e?.target?.checked);
        }
      } else {
        setSearchFormValue({ ...formData, [fieldName]: e });
      }
    },
    [showAdvancedSearch, setShowAdvancedSearch] = useState(false),
    handleClick = () => {
      setShowAdvancedSearch(!showAdvancedSearch);
    },
    
    resetSearch = (code) => {
      let formData = { ...hasSearchFormValue };
      switch (code) {
        case "follower":
          formData.followersFrom = "";
          formData.followersTo = "";
          break;
        case "views":
          formData.averageViewsFrom = "";
          formData.averageViewsTo = "";
          break;
        case "cost":
          formData.costFrom = "";
          formData.costTo = "";
          break;
        case "engRate":
          formData.engagementRate = "";
          break;
        case "isFavorite":
          formData.isFavorite = false;
          break;
      }
      setSearchFormValue(formData);
    };

  useEffect(() => {
    if (props?.creatorTags) {
      const tagsForSearch = props.creatorTags.map((tags) => {
        return { label: tags, value: tags };
      });
      setTags(tagsForSearch);
    }
  }, [props]);

  useEffect(() => {
    setTags(creatorTagList);
  }, [creatorTagList]);

  useEffect(() => {
    setFavorite(isFavorite);
  }, [isFavorite]);

  useEffect(() => {
    setSearchFormValue(hasSearchFormValue);
    props?.startSearch(hasSearchFormValue);
  }, [hasSearchFormValue]);



  useEffect(() => {
    setFavorite(isFavorite);
  }, [isFavorite]);


  return (
    <div className="search-box-container">
      <Form disabled={!props?.isSearchEnabled}>
        <div className={`search-user-container`}>
          <div className="search-user-item">
            <Form.Item name="userName" hasFeedback>
              <FloatInput label="Search" values={hasSearchFormValue.userName} type="text" vType="search" btnType="primary" onClick={() => props?.startSearch({ ...hasSearchFormValue, userName: hasSearchFormValue.userName })} size="large" placeholder="Username" name="userName" onChange={(e) => handleChange("userName", e)} />
            </Form.Item>
          </div>
          <div className="search-user-item">
            <Button className="btn-filter" type={showAdvancedSearch ? "default" : "primary"} block onClick={() => setHeight(height === 0 ? "auto" : 0)}>
              <FontAwesomeIcon icon={faFilter} />
            </Button>
          </div>
        </div>
        <AnimateHeight duration={500} height={height}>
          <div className={`range-box-container`}>
            <div className="tagsContainer">
              <div className="section-title">
                <div>
                  <Text level={3} className="fw-600" style={{ color: Colors.mutedText }}>
                    {"Select Tags"}
                  </Text>
                </div>
                <div>
                  <a
                    onClick={() => {
                      setSelectedTags([]);
                      handleChange("tags", []);
                    }}
                  >
                    <Link style={{ color: Colors.primaryColor }}>Clear</Link>
                  </a>
                </div>
              </div>
              <Select
                name="tags"
                mode="tags"
                className=" w-full mb3"
                placeholder="Select Tags"
                options={creatorTagList}
                value={selectedTags}
                onSelect={(value) => {
                  const newTags = [...selectedTags, value];
                  setSelectedTags(newTags);
                  handleChange("tags", newTags);
                }}
                onDeselect={(value) => {
                  const newTags = selectedTags.filter((tag) => tag !== value);
                  setSelectedTags(newTags);
                  handleChange("tags", newTags);
                }}
              />
            </div>
            <div className="range-container">
              <div>
                <FindCreatorSearchRangeTile handleChange={handleChange} title="Followers" code="follower" fromKey="followersFrom" toKey="followersTo" clearValues={(code) => resetSearch(code)} searchFromRange={searchFromRange} searchToRange={searchToRange} isRange={true} />
                <FindCreatorSearchRangeTile handleChange={handleChange} title="Average Views" code="views" fromKey="averageViewsFrom" toKey="averageViewsTo" clearValues={(code) => resetSearch(code)} searchFromRange={searchFromRange} searchToRange={searchToRange} isRange={true} />
              </div>

              <div>
                <FindCreatorSearchRangeTile handleChange={handleChange} title="Cost" code="cost" fromKey="costFrom" toKey="costTo" clearValues={(code) => resetSearch(code)} searchFromRange={searchFromCostRange} searchToRange={searchToCostRange} isRange={true} />
                <FindCreatorSearchRangeTile handleChange={handleChange} title="Engagement Rate" code="engRate" fromKey="engagementRate" toKey="" clearValues={(code) => resetSearch(code)} searchFromRange={engagementRates} isRange={false} />
              </div>
            </div>

            <div className="tagsContainer">
              <div className="section-title">
                <div>
                  <Text level={3} className="fw-600" style={{ color: Colors.mutedText }}>
                    {"Filter by Favorite"}
                  </Text>
                </div>
                <div>
                  <a
                    onClick={() => {
                      setFavorite(false);
                      handleChange("isFavorite", false);
                    }}
                  >
                    <Link style={{ color: Colors.primaryColor }}>Clear</Link>
                  </a>
                </div>
              </div>
              <Checkbox name="isFavorite" className=" w-full mb3" onChange={(e) => handleChange("isFavorite", e)}>
                Favorite
              </Checkbox>
            </div>
          </div>
        </AnimateHeight>
      </Form>
    </div>
  );
};
export default FindCreatorSearch;
