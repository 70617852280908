import React from "react";
import { Result, Button } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart, faCheck } from "@fortawesome/pro-solid-svg-icons";
const OfferConfirmation = (props) => {

  
  return (
            <Result
                icon={<FontAwesomeIcon icon={faHeart}  className="card-result" />}
                subTitle={<><div>Your offer was sent to <span className='fw-600'>{props.creatorName}</span>. They have 72 hours to accept it.</div><div>Once the offer is accepted, your card will be charged.</div></>}
                extra={[
                    <Button key="viewCampaigns" type="primary" size="large" onClick={()=>window.location.href='/offers'}>
                   <FontAwesomeIcon icon={faCheck} /> View Campaigns
                  </Button>
                ]}
            />
    );
    
};
export default OfferConfirmation;
