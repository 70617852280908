import { apiCall } from "../../modules/api-call";
import { CONFIG } from "../../config/config";

export const changeOfferStatusRequest = (data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: "CHANGE_OFFER_STATUS_REQUEST", payload: {} });
      const { data: response } = await apiCall({
        method: "patch",
        url: CONFIG.BACKEND_API_URL + "user/offer/status",
        data,
        auth: 1,
      });
      if (response && response.statusCode === 200 && response.error === false) {
        // localStorage.removeItem(CONFIG.LOCALSTORAGE_USER);
        dispatch({ type: "CHANGE_OFFER_STATUS_REQUEST_SUCCESS", payload: response });
      } else {
        dispatch({ type: "CHANGE_OFFER_STATUS_REQUEST_ERROR", payload: response });
      }
    } catch (err) {
      dispatch({ type: "CHANGE_OFFER_STATUS_REQUEST_ERROR", payload: { message: err?.response?.message ? err?.response?.message :err.message} });
    }
  };
};

export const changeOfferStatusReset = () => {
  return async (dispatch) => {
    dispatch({ type: "CHANGE_OFFER_STATUS_REQUEST_RESET" });
  };
};
