import { apiCall } from "../../modules/api-call";
import { CONFIG } from "../../config/config";

export const createEvergreenBoostRequest = (data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: "CREATE_BOOST_REQUEST", payload: {} });
      const { data: response } = await apiCall({
        method: "POST",
        url: CONFIG.BACKEND_API_URL + "user/evergreen-boost",
        auth: 1,
        data,
      });
      console.log("CREATE BOOST Request response => ", response);
      if (response && response.statusCode === 201 && response.error === false) {
        dispatch({ type: "CREATE_BOOST_REQUEST_SUCCESS", payload: response });
      } else {
        dispatch({ type: "CREATE_BOOST_REQUEST_ERROR", payload: response });
      }
    } catch (err) {
      console.log("CREATE_BOOST_REQUEST_ERROR error => ", err);
      dispatch({
        type: "CREATE_BOOST_REQUEST_ERROR",
        payload: { message: "Something went wrong" },
      });
    }
  };
};

export const getUserEvergreenBoosts = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: "SET_USER_BOOSTS_LOADING",
        payload: {
          loading: true,
        },
      });
      const { data: response } = await apiCall({
        method: "GET",
        url: CONFIG.BACKEND_API_URL + "user/evergreen-boost",
        auth: 1,
      });
      console.log("Get user boosts Request response => ", response);
      if (
        response &&
        (response.statusCode < 400) &&
        response.error === false
      ) {
        dispatch({
          type: "SET_USER_BOOSTS",
          payload: response.data,
        });
      } else {
        console.log("hello");
        dispatch({ type: "SET_USER_BOOSTS_ERROR", payload: response });
      }
    } catch (err) {
      console.log("SET_USER_BOOSTS_ERROR error => ", err);
      dispatch({ type: "SET_USER_BOOSTS_ERROR", payload: { message: "Something went wrong" } });
    }
  };
};
