import React from "react";
import "../assets/styles/modal.scss";
import ImageRenderer from "../CommonWidgets/ImageRenderer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { formatNumber } from "../../helper";
import { faHeart, faUsers, faVideo } from "@fortawesome/pro-solid-svg-icons";
import { Card, Tag, Typography } from "antd";
const { CheckableTag } = Tag;
const { Title } = Typography;
import Tags from "../CommonWidgets/Tags";

const UserHeaderInModal = (props) => {
  const userData = props?.userData,
    headerData = [
      {
        key: "1",
        title: "Followers",
        value: userData?.creatorInfo?.followers,
        icon: faUsers,
      },
      {
        key: "2",
        title: "Likes",
        value: userData?.creatorInfo?.likes,
        icon: faHeart,
      },
      {
        key: "3",
        title: "Videos",
        value: userData?.creatorInfo?.videoCount,
        icon: faVideo,
      },
    ];
  return (
    <Card>
      <div className={"row-header-container "}>
        <div className={"header-item"}>
          <ImageRenderer imageUrl={userData?.profileImage} isAvatar={false} className={"profile-img"} />
        </div>
        <div className={"header-item"}>
          {userData?.creatorInfo?.tiktokHandle ? (
            <>
              <a target="_blank" rel="noreferrer" href={`https://www.tiktok.com/@${userData?.creatorInfo?.tiktokHandle}`}>
                <Title level={4} className="m0">
                  {" "}
                  {userData?.userName}
                </Title>
              </a>
              <div className="view-info-container">
                {headerData.map((item, index) => {
                  return (
                    <div className="view-info-item" key={item?.value}>
                      <p>
                        {item.icon && <FontAwesomeIcon icon={item.icon} />} {item?.value}
                      </p>
                      <h5>{item?.title}</h5>
                    </div>
                  );
                })}
              </div>

              <div className={"offer-card w-full modal-tag-bg tags-container"}>
                {userData?.creatorInfo?.tags && userData?.creatorInfo?.tags.length > 0 && <Tags tagsData={userData?.creatorInfo?.tags} showItems={userData?.creatorInfo?.tags && userData?.creatorInfo?.tags.length} />}{" "}
              </div>
            </>
          ) : (
            <Title level={4} className="m0">
              {" "}
              {userData?.userName}
            </Title>
          )}
        </div>
        <div className="header-item">
          <CheckableTag checked className="creator-price nohover ">
            {formatNumber(userData?.price)}
          </CheckableTag>
        </div>
      </div>
    </Card>
  );
};

export default UserHeaderInModal;
