import PubNub from 'pubnub';
import { trimSpace, checkIfUserLoggedIn, findName } from '../helper';
import { CONFIG } from "../config/config";

const pubnub = new PubNub({
    publishKey: CONFIG.PUBNUB_PUBLISH_KEY,
    subscribeKey: CONFIG.PUBNUB_SUBSCRIBE_KEY,
    logVerbosity: true,
    uuid: checkIfUserLoggedIn().userName ? trimSpace(checkIfUserLoggedIn().userName) : "test"
});

export const checkIfAlreadyMember = async () => {
    const memberShipResult = await pubnub.objects.getMemberships({
        uuid: trimSpace(checkIfUserLoggedIn().userName),
        include: {
            customFields: true,
            channelFields: true,
            customChannelFields: true
        },
    });

    if (memberShipResult && memberShipResult.status === 200) {
        if (memberShipResult?.data?.length > 0) {
            const reformatResults = reformatMemberShipChannels(memberShipResult);
            return reformatResults;
        } else {
            return [];
        }
    } else {
        return [];
    }

}


const reformatMemberShipChannels = (aResult) => {
    try {
        if (aResult?.data && aResult?.data[0]?.channel?.id) {
            let tmpData = [], formatData = [];
            for (let j = 0; j < aResult?.data?.length; j++) {
                let aChannel = aResult?.data[j];
                if (aChannel?.channel?.name) {
                    aChannel.channel.name = findName(aChannel?.channel?.name);
                } else {
                    aChannel.channel.name = "";
                }
                if (aChannel?.channel?.custom && aChannel?.channel?.custom?.thumb) {
                    aChannel.channel.custom.thumb =
                        (checkIfUserLoggedIn().userType === "musician") ? aChannel?.channel?.custom?.thumb : aChannel?.channel?.description;
                }
                tmpData.push(aChannel?.channel);
                formatData.push({ "id": aChannel?.channel?.id, "name": aChannel?.channel?.name, "eTag": aChannel?.channel?.eTag, "custom": aChannel?.channel?.custom ? aChannel?.channel?.custom : {} });
            }
            return formatData;
        } else {
            return [];
        }

    } catch (err) {
        return err;
    }

}

export const createChannel = async (channelData) => {
    try {
        const reformatResults = await checkIfAlreadyMember(),
            findIfChannelExist = reformatResults && reformatResults.filter((item => item.id === channelData?.id));
 
        if (findIfChannelExist?.length <= 0) {
            await  pubnub.objects.setUUIDMetadata(
                {
                    uuid: trimSpace(channelData?.id.split("-")[0]),
                    data: {
                        name: checkIfUserLoggedIn().userName,
                        profileUrl: checkIfUserLoggedIn().profileImage
                    }
                
                }
            );
          await pubnub.objects.setUUIDMetadata(
                {
                    uuid: trimSpace(channelData?.id.split("-")[1]),
                    data: {
                        name: trimSpace(channelData?.id.split("-")[1]),
                        profileUrl: channelData?.custom?.thumb
                    }
                
                }
            );
            const channelResult = await pubnub.objects.setChannelMetadata({
                channel: channelData?.id,
                data: channelData,
                include: {
                    customFields: true,
                    channelFields: true,
                    customChannelFields: true
                },
            });

            if (channelResult && channelResult.status === 200) {
                //marketer membership
              //  pubnub.subscribe({uuid:  trimSpace(checkIfUserLoggedIn().userName), channels: [ channelData?.id]});
               // pubnub.subscribe({uuid:  trimSpace(channelData?.id.split("-")[1]), channels: [ channelData?.id]});
                
                const aResult = await pubnub.objects.setMemberships({
                    uuid: trimSpace(checkIfUserLoggedIn().userName),
                    
                    channels: [
                        channelData?.id
                    ],
                    include: {
                        // To include channel fields in response
                        customFields: true,
                        channelFields: true,
                        customChannelFields: true
                    },
                });
                     await pubnub.objects.setMemberships({
                        uuid: trimSpace(channelData?.id.split("-")[1]),
                        channels: [
                            channelData?.id
                        ],
                        include: {
                            // To include channel fields in response
                            channelFields: true,
                        },
                    });

                if (aResult.status === 200) {
                   // pubnub.subscribe({channels: [ channelData?.id], withPresence: true});
                    pubnub.subscribe({uuid:  trimSpace(checkIfUserLoggedIn().userName), channels: [ channelData?.id]});
                    pubnub.subscribe({uuid:  trimSpace(channelData?.id.split("-")[1]), channels: [ channelData?.id]});
                    window.localStorage.removeItem('channelDetail');
                    return await reformatMemberShipChannels(aResult);

                } else {
                    return [];
                }

            } else {
                return [];
            }
        } else {
            return reformatResults;
        }
    } catch (err) {
        return [];

    }
}