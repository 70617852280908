import React, { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { useSelector, useDispatch } from "react-redux";
import { notification, Form } from "antd";
import FloatInput from "../../CommonWidgets/FloatInput";
import { createCardRequest, createCardReset } from "../../../redux/actions/create-card";
import "../../assets/styles/modal.scss";
import { isFormInValid } from "../../../helper";
import useAnalytics from "../../../hooks/useAnalytics";
import { PaymentInputsWrapper, usePaymentInputs } from "react-payment-inputs";
import images from "react-payment-inputs/images";
import { css } from 'styled-components';
import "./index.scss";

const AddCard = forwardRef((props, ref) => {
  const dispatch = useDispatch(),
    { trackAddCard, trackAddCardFailed } = useAnalytics(),
    { createCardData } = useSelector((state) => ({ createCardData: state.createCardData })),
    [form] = Form.useForm(),
    { wrapperProps, meta, getCardImageProps, getCardNumberProps, getExpiryDateProps, getCVCProps } = usePaymentInputs(),
    onManageCard = () => {
      form
        .validateFields()
        .then(() => {
          if (!isFormInValid(form)) {
          
            props.loadingUpdate(true);
            delete hasAddCardFormValue?._id;
            delete hasAddCardFormValue?.actualCardNumber;
            delete hasAddCardFormValue.expiryDate;
            delete hasAddCardFormValue.cvc;
           
            let obj = hasAddCardFormValue;
            obj.expiryMonthYear =  hasAddCardFormValue.expiryMonthYear.replace(/ /g, '');
            if (props.from) {
              let isSave = false;
              if (props.saveCard === true) {
                isSave = true;
              }
              hasAddCardFormValue.isSave = isSave;
              dispatch(createCardRequest(obj));
            } else {
              dispatch(createCardRequest(obj));
            }
          }
        })
        .catch((info) => {
          props.loadingUpdate(false);
        });
    },
    [hasAddCardFormValue, setAddCardFormValue] = useState({
      nameOnCard: props.setDataToPage ? props.setDataToPage.nameOnCard : "",
      cardNumber: props.setDataToPage ? props.setDataToPage.cardNumber : "",
      expiryMonthYear: props.setDataToPage ? props.setDataToPage.expiryMonthYear : "",
      cvv: props.setDataToPage ? props.setDataToPage.cvv : "",
    }),
    onChange = (e) => {
      let formData = { ...hasAddCardFormValue };
      setAddCardFormValue({ ...formData, [e.target.name]: e.target.value });
    },
    onBlur = (e) => {
    // validateInputs();
    },
    validateInputs = () => {
      let errors = {};
      if (meta.erroredInputs.cardNumber) {
        errors.cardNumber = meta.erroredInputs.cardNumber;
      }
      if (meta.erroredInputs.expiryDate) {
        errors.expiryDate = meta.erroredInputs.expiryDate;
      }
      if (meta.erroredInputs.cvc) {
        errors.cvc = meta.erroredInputs.cvc;
      }
      console.log("errors", errors,meta);
      if (Object.keys(errors).length) {
        props?.disableSubmit(true);
      } else {
        props?.disableSubmit(false);
      }
    }
    

  useImperativeHandle(ref, () => ({
    onAddCard() {
      onManageCard();
    },
  }));

  useEffect(() => {
    setAddCardFormValue(hasAddCardFormValue);
  validateInputs();

  }, [hasAddCardFormValue]);

  useEffect(() => {
    if (!createCardData.error && Object.keys(createCardData?.payload).length) {
      notification.success({ message: "Success", description: createCardData?.payload?.message, placement: "topRight" });
      trackAddCard("add_card");
      form.resetFields();
      props.updateData(createCardData?.payload.data);
      props.loadingUpdate(false);
      dispatch(createCardReset());
      setAddCardFormValue({
        nameOnCard: "",
        cardNumber: "",
        expiryMonthYear: "",
        cvv: "",
      });
      if (props?.from !== "offer") props.close();
    } else {
      if (createCardData?.error?.message) {
        trackAddCardFailed("add_card", createCardData?.error?.message);
        notification.error({ message: "Error", description: createCardData?.error?.message, placement: "topRight" });
        props.loadingUpdate(false);
      }
    }
  }, [createCardData]);

  return (
    <Form
      name="manageCard"
      form={form}
      initialValues={{
        remember: true,
      }}
      onFinish={onManageCard}
     
      
    >
      <Form.Item
        name={["card", "nameOnCard"]}
        initialValue={hasAddCardFormValue.nameOnCard}
        rules={[
          {
            required: true,
            message: "Please enter Name as in Card",
          },
        ]}
        hasFeedback
      >
        <FloatInput size="large" label="Name On Card" placeholder="John Doe" type="text" name="nameOnCard" values={hasAddCardFormValue.nameOnCard} onChange={(e) => onChange(e)} />
      </Form.Item>
      <PaymentInputsWrapper {...wrapperProps} className="w-full payment-container"
      styles={{
        errored: {
          base: css`
            border: none !important;
          `
        }
          
        }}
            >
            
        <div className="control-elements">
          <svg {...getCardImageProps({ images })} />
          <input {...getCardNumberProps({  onBlur: (e)=>onBlur(e),onChange: (e) => onChange(e) })} />
        </div>
        <div className="justify-content-between control-elements">
          <input {...getExpiryDateProps({  onBlur: (e)=>onBlur(e),onChange: (e) => onChange(e) })} name={"expiryMonthYear"} />
          <input {...getCVCProps({ onBlur: (e)=>onBlur(e), onChange: (e) => onChange(e) })}  name={"cvv"}/>
        </div>
       
      </PaymentInputsWrapper>

      {/*   <Space direction="vertical" className="w-full">
        <Form.Item
          name={["card", "nameOnCard"]}
          initialValue={hasAddCardFormValue.nameOnCard}
          rules={[
            {
              required: true,
              message: "Please enter Name as in Card",
            },
          ]}
          hasFeedback
        >
          <FloatInput size="large" label="Name On Card" placeholder="John Doe" type="text" name="nameOnCard" values={hasAddCardFormValue.nameOnCard} onChange={(e) => onChange(e)} />
        </Form.Item>
        <Form.Item
          name={["card", "cardNumber"]}
          initialValue={hasAddCardFormValue.cardNumber}
          rules={[
            {
              required: true,
              message: "Please enter Card Number",
            },
          ]}
          hasFeedback
        >
          <FloatInput type="text" size="large" label="Card Number" placeholder="4444 0000 4444 0000" name="cardNumber" values={hasAddCardFormValue.cardNumber} onChange={(e) => onChange(e)} />
        </Form.Item>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col span={16}>
            <Form.Item
              name={["card", "expiryMonthYear"]}
              initialValue={hasAddCardFormValue.expiryMonthYear}
              rules={[
                {
                  required: true,
                  message: "Please enter Expiration Month and Year",
                },
                {
                  pattern: /^(1[0-2]|0[1-9]|\d)\/(20\d{2}|19\d{2}|0(?!0)\d|[1-9]\d)$/,
                  message: "Please follow MM/YYYY or MM/YY format",
                },
              ]}
              hasFeedback
            >
              <FloatInput type="text" size="large" label="Expiration Month And Year" placeholder="01/24" name="expiryMonthYear" values={hasAddCardFormValue.expiryMonthYear} onChange={(e) => onChange(e)} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name={["card", "cvv"]}
              initialValue={hasAddCardFormValue.cvv}
              rules={[
                {
                  required: true,
                  message: "Please input your Card CVV",
                },
                {
                  pattern: /^(?:\d*)$/,
                  message: "CVV should contain just number",
                },
                {
                  pattern: /^[\d]{3,4}$/,
                  message: "Please check cvv length",
                }
              ]}
              hasFeedback
            >
              <FloatInput type="text" size="large" label="CVV" placeholder="404" name="cvv" values={hasAddCardFormValue.cvv} onChange={(e) => onChange(e)} />
            </Form.Item>
          </Col>
        </Row>
            </Space> */}
    </Form>
  );
});

AddCard.displayName = "AddCard";
export default AddCard;
