import React, { useEffect, useState, useRef } from "react";
import { Modal,  Button } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck,faMagnifyingGlassChart } from "@fortawesome/pro-solid-svg-icons";
import "../assets/styles/modal.scss";
import CreateInstantCampaignWizard from "../CreateInstantCampaignWizard";
import ModalHeader from "../ModalHeader";
import { LeftArrow, RightArrow } from "../CommonWidgets/FontIcons";
import useAnalytics from "../../hooks/useAnalytics";
import "./index.scss";


const CreateInstantCampaignModal = (props) => {
  console.log("props", props);
  const [currentModalStep, setCurrentModalStep] = useState(-1),
  {trackInstantBookOrderInterrupted} = useAnalytics(),
    [createOfferNextStatus, setCreateOfferNextStatus] = useState(true),
    [createCardNextStatus, setCreateCardNextStatus] = useState(true),
    [bookStep, setBookStep] = useState(0),
    bookOfferRef = useRef(),
    newBookStep = (newStep) => {
      setBookStep(newStep);
    },
    trackBookingInterruptStep = () => {
      let stepText = "Instant Campaign - Create"
      switch (bookStep) {
        case 1:
        stepText = "Instant Campaign - Payment";
        break;
        case 2:
        stepText = "Instant Campaign - Confirmation";
        break;
        default:
          break;
      }
      trackInstantBookOrderInterrupted(stepText);
      return true;
    }

    useEffect(() => {
        props.loadingUpdate(false);
    }, []);

  useEffect(() => {
    setCurrentModalStep(currentModalStep);
  }, [currentModalStep]);

  return (
    <Modal
      open={props.open}
      mask={true}
      bodyStyle={{ height: "var(--find-creator-modal-height)" }}
      title={<ModalHeader titleIcon={faMagnifyingGlassChart} modalTitle={"Book Creator"} close={props.close} />}
      className="find-creator-modal"
      width={1200}
      centered
      onCancel={()=>{trackBookingInterruptStep(); props.close}}
      closable={false}
      footer={
        <>
            {bookStep < 1 && (
              <>
                <Button type="default" onClick={props.close}>
                  <LeftArrow /> Cancel
                </Button>
                <Button type="primary" onClick={() => bookOfferRef?.current?.onNext()} disabled={createOfferNextStatus}>
                  Next <RightArrow />
                </Button>
                
              </>
            )}
            {bookStep >= 1 && bookStep < 2 && (
              <>
                <Button type="default" onClick={() => bookOfferRef?.current?.onPrev()}>
                  <LeftArrow /> Back
                </Button>
                <Button type="primary" onClick={() => bookOfferRef?.current?.onMoveToNext()} disabled={createCardNextStatus}>
                  Next <RightArrow />
                </Button>
              </>
            )}

            {bookStep === 2 && (
              <>
                <Button type="default" onClick={() => bookOfferRef.current.onPrev()}>
                  <LeftArrow /> Back
                </Button>
                <Button type="primary" onClick={() => bookOfferRef?.current?.onBookCreateOffer()} loading={bookOfferRef.current.onLoading()}>
                  <FontAwesomeIcon icon={faCircleCheck} /> Book Creator
                </Button>
              </>
            )}
          </>
        
      }
    >
      <div className="flex flex-column w-full">
          <CreateInstantCampaignWizard wOpen={true} ref={bookOfferRef} wClose={props.close} selectedBudget={props.chosenMetaData} updateCurrentBookStep={(step) => newBookStep(step)} updateCreateOfferNextStatus={(status) => setCreateOfferNextStatus(status)} currentOfferNextButtonStatus={createOfferNextStatus} updateCreateCardNextStatus={(status) => setCreateCardNextStatus(status)} currentCardNextButtonStatus={createCardNextStatus} />
      </div>
    </Modal>
  );
};

export default CreateInstantCampaignModal;
