import { apiCall } from "../../modules/api-call";
import { CONFIG } from "../../config/config";

export const manageSoundRequest = (data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: "ADD_OR_EDIT_SOUND_REQUEST", payload: {} });
      const { data: response } = await apiCall({
        method: "POST",
        url: CONFIG.BACKEND_API_URL + "user/sound",
        auth: 1,
        data,
      });
      console.log("ADD_OR_EDIT SOUND Request response => ", response);
      if (response && (response.statusCode === 201 || response.statusCode === 200) && response.error === false) {
        dispatch({ type: "ADD_OR_EDIT_SOUND_REQUEST_SUCCESS", payload: response });
      } else {
        dispatch({ type: "ADD_OR_EDIT_SOUND_REQUEST_ERROR", payload: response });
      }
    } catch (err) {
      console.log("ADD_OR_EDIT_SOUND_REQUEST_ERROR error => ", err);
      dispatch({ type: "ADD_OR_EDIT_SOUND_REQUEST_ERROR", payload: { message: "Something went wrong" } });
    }
  };
};

export const manageSoundReset = () => {
  return async (dispatch) => {
    dispatch({ type: "ADD_OR_EDIT_SOUND_REQUEST_RESET" });
  };
};
