import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Card, List, Button, Typography, notification } from "antd";
import { Animated } from "react-animated-css";
import { faRocketLaunch, faMusic } from "@fortawesome/pro-solid-svg-icons";
import { getBoostProductsRequest } from "../../redux/actions/list-products-boost";
import BoostSuccessModal from "../../components/BoostSuccessModal";
import AuthModal from "../../components/AuthModal";
import "./index.scss";
import CheckWeb from "../../components/CommonWidgets/CheckWeb";
import ModalContainer from "../../components/ModalContainer";
import ModalHeader from "../../components/ModalHeader";
import BoostInfoModal from "../../components/BoostInfoModal";
import { updateBoostRequest, updateBoostReset } from "../../redux/actions/update-boost";
import useAnalytics from "../../hooks/useAnalytics";
import InstantBookOffer from "../../components/InstantBookOffer";
import { validURL, checkIfUserLoggedIn } from "../../helper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const { Title, Text } = Typography;

const Boost = (props) => {
  const web = CheckWeb(),
    dispatch = useDispatch(),
    { trackBoostModalOpened } = useAnalytics(),
    boostInfoRef = useRef(null),
    [isLoading, setLoading] = useState(false),
    { listProductsBoostData } = useSelector((state) => ({
      listProductsBoostData: state.listProductsBoostData,
    })),
    [boostProductsData, setBoostProductsData] = useState([]),
    [selectedBoostInfo, setSelectedBoostInfo] = useState({}),
    [showBoostSuccessModal, setShowBoostSuccessModal] = useState(false),
    [isBoostModalOpen, setIsBoostModalOpen] = useState(false),
    [isDisabled, setDisabled] = useState(true),
    [showAuthModal, setShowAuthModal] = useState(false),
    query = new URLSearchParams(props.location.search),
    checkoutSessionId = query.get("session_id"),
    boostType = query.get("boostType"),
    onBoostSelect = (boostDetail) => {
      if (!checkIfUserLoggedIn()?.userType) {
        setShowAuthModal(true);
      } else {
        setSelectedBoostInfo(boostDetail);
        trackBoostModalOpened({
          name: boostDetail.name,
          productId: boostDetail.productId,
          productKey: boostDetail.productKey,
        });
        setIsBoostModalOpen(true);
      }
    },
    validateData = (data, isUrl) => {
      const { authCode, countries, ageGroups, trafficUrl } = data;
      let isEnabled;
      switch (isUrl) {
        case "true":
          isEnabled =
            authCode.length > 0 &&
            countries.length > 0 &&
            ageGroups.length > 0 &&
            trafficUrl.length > 0 &&
            validURL(trafficUrl);
          break;
        case "false":
          isEnabled = authCode.length > 0 && countries.length > 0 && ageGroups.length > 0;
          break;
        case "optional":
          if (trafficUrl && trafficUrl?.length > 0) {
            isEnabled =
              authCode.length > 0 &&
              countries.length > 0 &&
              ageGroups.length > 0 &&
              validURL(trafficUrl);
          } else {
            isEnabled = authCode.length > 0 && countries.length > 0 && ageGroups.length > 0;
          }
          break;
      }
      if (isEnabled) {
        setDisabled(false);
      } else {
        setDisabled(true);
      }
    };

  useEffect(() => {
    if (!listProductsBoostData.error && Object.keys(listProductsBoostData?.payload).length) {
      setBoostProductsData(listProductsBoostData?.payload?.data);
    } else {
      if (listProductsBoostData?.error?.message) {
        notification.error({
          message: "Error",
          description: listProductsBoostData?.error?.message,
          placement: "topRight",
        });
      }
    }
  }, [listProductsBoostData]);

  useEffect(() => {
    if (checkoutSessionId && checkoutSessionId.length > 0) {
      dispatch(updateBoostRequest(checkoutSessionId, boostType));
    }
    dispatch(getBoostProductsRequest());
  }, []);

  return (
    <div className="wrapper-with-background">
      <div>
        <Title level={3}>
          <FontAwesomeIcon icon={faRocketLaunch} />
          <span className={"text-primary"}>Boost</span> Your Video
        </Title>
        <Text type="default">Increase the effectiveness of your Tiktok video.</Text>
      </div>
      <List
        className="mt5 boost-row"
        grid={{
          gutter: 16,
          xs: 1,
          sm: 2,
          md: 2,
          lg: 4,
          xl: 5,
          xxl: 5,
        }}
        dataSource={boostProductsData}
        renderItem={(item, index) => (
          <Animated
            animationIn="fadeIn"
            className={index === 0 && "boost-default-card"}
            isVisible={true}
            animationInDuration={600}
            animationInDelay={500 + (index + 1) * 100}
          >
            <List.Item key={item.key} className="w-full p0 m0">
              <Card
                key={index}
                className="boost"
                onClick={() => onBoostSelect(item)}
                bordered={false}
              >
                <div>
                  <Title level={3} className="mh-100 pd0 ">
                    {item.name}
                  </Title>
                  <p className="text-left mh-100 md0 text-capitalize">{item.description}</p>
                  <Title level={3}>Starting at ${Math.round(item.price * 100)}</Title>
                </div>
              </Card>
            </List.Item>
          </Animated>
        )}
      />
      {checkIfUserLoggedIn()?.userType !== "creator" && (
        <>
          <div className="mt8 mb3">
            <Title level={3}>
              <FontAwesomeIcon icon={faMusic} />
              Grow Your <span className={"text-primary"}>Sound</span>
            </Title>
            <Text type="default">Have influencers use your sound in their next Tiktok video.</Text>
          </div>
          <InstantBookOffer location={props.location.search} />
        </>
      )}

      {isBoostModalOpen && (
        <ModalContainer
          isOpen={isBoostModalOpen}
          title={
            <ModalHeader
              titleIcon={faRocketLaunch}
              modalTitle="Boost"
              close={() => {
                setIsBoostModalOpen(false);
              }}
            />
          }
          footer={null}
          width={1000}
        >
          <div>
            <BoostInfoModal
              boostDetail={selectedBoostInfo}
              validateFormValues={(data, isUrl) => validateData(data, isUrl)}
              checkoutSessionId={checkoutSessionId}
              loadingUpdate={(val) => setLoading(val)}
              ref={boostInfoRef}
              isDisabled={isDisabled}
              isLoading={isLoading}
            />
          </div>
        </ModalContainer>
      )}

      {showBoostSuccessModal && (
        <ModalContainer
          isOpen={showBoostSuccessModal}
          title={
            <ModalHeader
              titleIcon={faRocketLaunch}
              modalTitle="Boost Confirmation"
              close={() => {
                setShowBoostSuccessModal(false);
              }}
            />
          }
          footer={
            <div className="text-center" key="btn-boost">
              <Button
                type="primary"
                htmlType="submit"
                size={"large"}
                className="btn-boost"
                onClick={() => setShowBoostSuccessModal(false)}
              >
                Confirm
              </Button>
            </div>
          }
          width={1000}
        >
          <BoostSuccessModal />
        </ModalContainer>
      )}
      {showAuthModal && <AuthModal open={showAuthModal} close={() => setShowAuthModal(false)} />}
    </div>
  );
};

export default Boost;
