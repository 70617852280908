import React, { useMemo, useState } from "react";
import styles from "./index.module.scss";
import { Select, Form, Checkbox, Spin } from "antd";
import boostCountryList from "../../../../config/boostCountries";
import { AGE_GROUPS } from "../../../../constants";
import { apiCall } from "../../../../modules/api-call";
import { CONFIG } from "../../../../config/config";
import { compareTwoObjects } from "../../../../helpers/compareTwoObjects";

const Targeting = ({ boostInfo, setBoostInfo }) => {
  const [loading, setLoading] = useState(false);

  const [targeting, setTargeting] = useState({
    countries: boostInfo.countries,
    ageGroups: boostInfo.ageGroups,
    objectives: boostInfo.objectives,
  });

  const [form] = Form.useForm();

  const handleClickFilter = (type) => {
    setTargeting((prev) => {
      if (prev.objectives?.includes(type)) {
        if (prev.objectives.length === 1) {
          return prev;
        }
        return {
          ...prev,
          objectives: prev.objectives.filter((filterType) => filterType !== type),
        };
      } else {
        return {
          ...prev,
          objectives: [...prev.objectives, type],
        };
      }
    });
  };

  const handleChange = (name, value) => {
    setTargeting((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (values) => {
    try {
      setLoading(true);
      const { data: response } = await apiCall({
        method: "PUT",
        url: CONFIG.BACKEND_API_URL + `user/evergreen-boost`,
        auth: 1,
        data: {
          boostId: boostInfo.boostId,
          ...values,
          objectives: targeting.objectives,
        },
      });

      setBoostInfo((prev) => ({
        ...prev,
        ageGroups: response.data.ageGroups,
        countries: response.data.countries,
        objectives: response.data.objectives,
      }));
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const dataEqual = useMemo(
    () =>
      compareTwoObjects(targeting, {
        countries: boostInfo.countries,
        ageGroups: boostInfo.ageGroups,
        objectives: boostInfo.objectives,
      }),
    [
      targeting,
      {
        countries: boostInfo.countries,
        ageGroups: boostInfo.ageGroups,
        objectives: boostInfo.objectives,
      },
    ]
  );

  return (
    <div>
      <div className={styles.header}>
        <p className={styles.title}>Who did we reach?</p>
        <div className={styles.filters}>
          <button
            onClick={() => handleClickFilter("awareness")}
            className={`${styles.button} ${
              targeting.objectives?.includes("awareness") ? styles.active : null
            }`}
          >
            Awareness Ads
          </button>
          <button
            onClick={() => handleClickFilter("conversion")}
            className={`${styles.button} ${
              targeting.objectives?.includes("conversion") ? styles.active : null
            }`}
          >
            Conversion Ads
          </button>
          <button
            disabled={!boostInfo.fullPackage}
            onClick={() => handleClickFilter("follows")}
            className={`${styles.button} ${
              targeting.objectives?.includes("follows") ? styles.active : null
            }`}
          >
            Follower Ads
          </button>
        </div>
      </div>
      <div>
        <Form
          onFinish={(values) => handleSubmit(values)}
          name="targetingForm"
          form={form}
          initialValues={{
            remember: true,
          }}
        >
          <Form.Item
            label="Target countries"
            rootClassName={styles.targetWrapper}
            initialValue={targeting.countries}
            name="countries"
            rules={[
              {
                required: true,
                message: "Plese select countries!",
              },
            ]}
            hasFeedback
          >
            <Select
              className={styles.countriesSelect}
              id="countries"
              mode="tags"
              placeholder="Where should we target?"
              onChange={(value) => handleChange("countries", value)}
            >
              {boostCountryList.map((country) => (
                <Select.Option key={country.code} value={country.name}>
                  {country.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Age groups"
            initialValue={targeting.ageGroups}
            rootClassName={styles.targetWrapper}
            name="ageGroups"
            rules={[
              {
                required: true,
                message: "Plese select age group!",
              },
            ]}
            hasFeedback
          >
            <Checkbox.Group
              onChange={(value) => handleChange("ageGroups", value)}
              options={AGE_GROUPS}
              value={targeting.ageGroups}
            />
          </Form.Item>
          <button disabled={dataEqual || loading} className={styles.saveButton}>
            {loading ? <Spin size="small" /> : "Submit Changes"}
          </button>
        </Form>
      </div>
    </div>
  );
};

export default Targeting;
