import React, { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Form, Row, Col, Switch, Select, Spin } from "antd";
import ReactQuill from "react-quill";
import AntSpinner from "../CommonWidgets/AntSpinner";
import FloatInput from "../CommonWidgets/FloatInput";
import { getSoundsRequest } from "../../redux/actions/list-sound";
import SoundModal from "../Sound/SoundModal";
import "react-quill/dist/quill.snow.css";
import "../assets/styles/modal.scss";
import "../assets/styles/quill.scss";
import moment from "moment";
import { isFormInValid } from "../../helper";
const { Option } = Select;

const CreateOffer = forwardRef((props, ref) => {
  console.log(props.setDataToPage);
  const [form] = Form.useForm(),
    dispatch = useDispatch(),
    [soundData, setSoundData] = useState([]),
    [isLoading, setLoading] = useState(false),
    [currentRecord, setCurrentRecord] = useState({}),
    [showAddSoundButton, setShowAddSoundButton] = useState(false),
    [showSoundModal, setShowSoundModal] = useState(false),
    { listSoundData } = useSelector((state) => ({ listSoundData: state.listSoundData })),
    [hasAddOfferFormValue, setAddOfferFormValue] = useState({
      offerTitle: props.setDataToPage?.offerTitle?.length > 0 ? props.setDataToPage?.offerTitle : "",
      offerDesc: props.setDataToPage?.offerDesc?.length > 0 ? props.setDataToPage?.offerDesc : "",
      deadline: props.setDataToPage?.deadline?.length > 0 ? props.setDataToPage.deadline : "",
      approveBy: moment().add(3, "days"), //make 3 for live
      needTiktokUpload: props.setDataToPage?.needTiktokUpload?.length > 0 ? props.setDataToPage?.needTiktokUpload : true,
      requireShipping: props.setDataToPage?.requireShipping ? props.setDataToPage?.requireShipping : false,
      sound: {
        id: props.setDataToPage?.sound?.id?.length > 0 ? props.setDataToPage?.sound?.id : "",
        title: props.setDataToPage?.sound?.title?.length > 0 ? props.setDataToPage?.sound?.title : "",
        link: props.setDataToPage?.sound?.link?.length > 0 ? props.setDataToPage?.sound?.link : "",
      },
    }),
    onDeadLineDateChange = (date, dateString) => {
      let formData = { ...hasAddOfferFormValue };
      setAddOfferFormValue({ ...formData, ["deadline"]: dateString });
    },
    onApproveByDateChange = (date, dateString) => {
      let formData = { ...hasAddOfferFormValue };
      setAddOfferFormValue({ ...formData, ["approveBy"]: dateString });
    },
    onChange = (e) => {
      let formData = { ...hasAddOfferFormValue };
      if (e?.target?.name) {
        setAddOfferFormValue({ ...formData, [e.target.name]: e.target.value });
      }
    },
    onOfferDescChange = (e) => {
      let formData = { ...hasAddOfferFormValue };
      setAddOfferFormValue({ ...formData, ["offerDesc"]: e });
    },
    onRequireShippingChange = (e) => {
      let formData = { ...hasAddOfferFormValue };
      setAddOfferFormValue({ ...formData, ["requireShipping"]: e });
    },
    onNeedTiktokUploadChange = (e) => {
      let formData = { ...hasAddOfferFormValue };
      setAddOfferFormValue({ ...formData, ["needTiktokUpload"]: e });
    },
    disabledDate = (current) => {
      // Can not select days before today and today
      return current && current < moment().add(3, "day"); //-3 to test
    },
    handleSelectSoundChange = (value, row = {}) => {
      if (value === "add") {
        setShowSoundModal(true);
      } else {
        let formData = { ...hasAddOfferFormValue },
          findSoundDetails = {};
        if (row && row?._id) {
          findSoundDetails = row;
        } else {
          findSoundDetails = soundData && soundData.filter((items) => items.soundTitle === value)[0];
          console.log("findSoundDetails",soundData,  findSoundDetails);
          if (!findSoundDetails) findSoundDetails = soundData[0];
       //   console.log("findSoundDetails", value, soundData[0]);
        }
        formData.sound = {
          id: findSoundDetails?.id ? findSoundDetails?.id: findSoundDetails?._id,
          title: findSoundDetails?.soundTitle,
          link: findSoundDetails?.soundLink,
        };
        setAddOfferFormValue(formData);
      }
    };

  useImperativeHandle(ref, () => ({
    onAddOffer() {
      form
        .validateFields()
        .then(() => {
          if (!isFormInValid(form)) {
            props.updateData(hasAddOfferFormValue);
            props.setNext();
          }
        })
        .catch((info) => {
          console.log(info);
        });
    },
  }));

  useEffect(() => {
    setAddOfferFormValue(hasAddOfferFormValue);
    if (hasAddOfferFormValue?.offerTitle?.length > 0 && hasAddOfferFormValue?.offerDesc?.length > 0 && hasAddOfferFormValue?.deadline?.length > 0 && hasAddOfferFormValue?.sound?.id.length > 0) {
      // alert("here");
      props.isNextDisabled(false);
    } else {
      props.isNextDisabled(true);
    }
  }, [hasAddOfferFormValue]);

  useEffect(() => {
    setLoading(true);
    dispatch(getSoundsRequest());
  }, []);

  useEffect(() => {
    if (!listSoundData.error && Object.keys(listSoundData?.payload).length) {
      setShowAddSoundButton(false);
      setSoundData(listSoundData?.payload?.data);
      setLoading(false)
    } else {
      if (listSoundData?.error?.error === true) {
        setShowAddSoundButton(true);
        setLoading(false);
      }
      //   notification.error({ message: "Error", description: listSoundData?.error?.message, placement: "topRight" });
    }
  }, [listSoundData]);

  useEffect(() => {
    setSoundData(soundData);
  }, [soundData]);

  useEffect(() => {
    setCurrentRecord(currentRecord);
  }, [currentRecord]);

  useEffect(() => {
    setShowAddSoundButton(showAddSoundButton);
  }, [showAddSoundButton]);

  return (
    <Spin indicator={AntSpinner} spinning={isLoading} size={"large"}>
      <Form
        name="addOffer"
        form={form}
        initialValues={{
          remember: true,
        }}
        className="createOfferModal"
      >
        <>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={12}>
              <Form.Item
                name={["offer", "offerTitle"]}
                initialValue={hasAddOfferFormValue.offerTitle}
                rules={[
                  {
                    required: true,
                    message: "Please input campaign name",
                  },
                ]}
                hasFeedback
              >
                <FloatInput maxLength={100} required={true} type="text" size="large" label="Campaign Name" placeholder="Enter Campaign Name" name="offerTitle" values={hasAddOfferFormValue.offerTitle} onChange={(e) => onChange(e)} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name={["offer", "soundId"]}
                initialValue={hasAddOfferFormValue?.sound?.title}
                rules={[
                  {
                    required: true,
                    message: "Please select Sound",
                  },
                ]}
                hasFeedback
              >
                
                <Select size ={"large"} name="soundId" onChange={handleSelectSoundChange} defaultValue={hasAddOfferFormValue?.sound?.id} placeholder={"Select Sound"} required={true}>
                <Option key="" value="">Select Sound</Option>
                  {soundData &&
                    soundData.map((item, i) => {
                      return (
                        <Option key={item._id} value={item._id} >
                         {item.soundTitle}
                        </Option>
                      );
                      
                    })
                    
                    }
                      <Option key="add" value="add">
                    Create New Sound
                  </Option>
                
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Form.Item name={["offer", "offerDesc"]} initialValue={hasAddOfferFormValue.offerDesc}>
            <ReactQuill theme="snow" name="offerDesc" value={hasAddOfferFormValue.offerDesc} onChange={(e) => onOfferDescChange(e)} placeholder="Enter Campaign Description" />
          </Form.Item>

          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={12}>
              <Form.Item name={["offer", "approveBy"]} initialValue={hasAddOfferFormValue?.approveBy ? moment(hasAddOfferFormValue?.approveBy, "MM-DD-YYYY") : null}>
                {/*  <DatePicker disabled={true} className="w-full"  placeholder="APPROVE BY"  onChange={onApproveByDateChange} required={true} format={"MM/DD/YYYY"} defaultValue={hasAddOfferFormValue?.approveBy ? moment(hasAddOfferFormValue?.approveBy, "MM-DD-YYYY") : ""} />
                 */}
                <FloatInput size="large" placeholder="Select Date" disabled={true} label="Accept Offer By" name="approveBy" vType="date" className="w-full" values={hasAddOfferFormValue?.approveBy ? moment(hasAddOfferFormValue?.approveBy, "MM-DD-YYYY") : ""} onChange={onApproveByDateChange} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name={["offer", "deadline"]}
                initialValue={hasAddOfferFormValue?.deadline ? moment(hasAddOfferFormValue?.deadline, "MM-DD-YYYY") : null}
                rules={[
                  {
                    required: true,
                    message: "Please enter deadline",
                  },
                ]}
                hasFeedback
              >
                <FloatInput size="large" placeholder="Select Date" disabled={false} label="Campaign Deadline" name="deadline" vType="date" className="w-full" required={true} values={hasAddOfferFormValue?.deadline ? moment(hasAddOfferFormValue?.deadline, "MM-DD-YYYY") : ""} disabledDate={disabledDate} onChange={onDeadLineDateChange} />
              </Form.Item>
            </Col>
          </Row>
          <div>
            <Form.Item
              name={["offer", "requireShipping"]}
              initialValue={hasAddOfferFormValue?.requireShipping}
              rules={[
                {
                  required: true,
                  message: "Please choose an option",
                },
              ]}
              hasFeedback
            >
                {/*
              <p>
                <Switch name="needTiktokUpload" values={hasAddOfferFormValue.needTiktokUpload} onChange={(e) => onNeedTiktokUploadChange(e)} className={"mr2"} />
                Add-on: request the creator to post the video on their TikTok account
              </p>
            
              <p>
                <Switch name="requireShipping" values={hasAddOfferFormValue.requireShipping} onChange={(e) => onRequireShippingChange(e)} className={"mr2"} defaultChecked={hasAddOfferFormValue.requireShipping} />
                This offer requires shipping
              </p>
            */}
            </Form.Item>
          </div>
        </>
      </Form>

      {showSoundModal === true && (
        <SoundModal
          open={showSoundModal}
          updateSoundData={(data) => setSoundData(data)}
          defaultRecord={(row) =>{console.log("new row", row); handleSelectSoundChange(row.soundTitle)}}
          source="offer"
          close={() => {
            setShowSoundModal(false);
          }}
        />
      )}
    </Spin>
  );
});

CreateOffer.displayName = "CreateOffer";
export default CreateOffer;
