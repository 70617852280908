import React from "react";
import ModalContainer from "../ModalContainer";
import ModalHeader from "../ModalHeader";
import Auth from "../Auth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/pro-solid-svg-icons";

const AuthModal = (props) => {
  return (
    props?.open && (
      <ModalContainer isOpen={props?.open} title={<ModalHeader titleIcon={faLock} modalTitle="Login/Register" close={props?.close} />} footer={null}>
        <Auth />
      </ModalContainer>
    )
  );
};

export default AuthModal;
