import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Form, Button, Typography, notification, Select, Card } from "antd";
import FloatInput from "../../components/CommonWidgets/FloatInput";
import { editProfileRequest, editProfileReset } from "../../redux/actions/profile";
import UploadProfilePhoto from "../../components/UploadProfilePhoto/index";
import { updateLocalStorage } from "../../helper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/pro-solid-svg-icons";
import { checkIfUserLoggedIn, isFormInValid } from "../../helper";
import stripeCountryList from "../../config/stripeCountries";
import ImageRenderer from "../../components/CommonWidgets/ImageRenderer";
import "./index.scss";
const { Title } = Typography;
const { Option } = Select;

const Profile = () => {
  const dispatch = useDispatch(),
    [form] = Form.useForm(),
    { profileData } = useSelector((state) => ({ profileData: state.profileData })),
    [imageUrl, setImageUrl] = useState(""),
    [buttonLoading, setButtonLoading] = useState(false),
    [countryValue, setCountryValue] = useState(checkIfUserLoggedIn()?.country),
    onUpdateProfile = () => {
      form
        .validateFields()
        .then(() => {
          if (!isFormInValid(form)) {
            hasProfileFormValue.country = countryValue;
            delete hasProfileFormValue.confirmPassword;
            if (hasProfileFormValue.userPassword.length <= 0) {
              delete hasProfileFormValue.userPassword;
            }
            setButtonLoading(true);
            dispatch(editProfileRequest(hasProfileFormValue));
          } else {
            notification.error({ message: "Error", description: "Please fix the errors", placement: "topRight" });
          }
        })
        .catch((info) => {
          notification.error({ message: "Error", description: "Please fix the errors", placement: "topRight" });
        });
    },
    handleSelectChange = (value) => {
      setCountryValue(value);
    },
    [hasProfileFormValue, setProfileForm] = useState({
      userName: checkIfUserLoggedIn()?.userName,
      email: checkIfUserLoggedIn()?.email,
      tiktokHandle: checkIfUserLoggedIn()?.tiktokHandle,
      country: checkIfUserLoggedIn()?.country,
      userPassword: "",
      confirmPassword: "",
    }),
    onChange = (e) => {
      let formData = { ...hasProfileFormValue };
      setProfileForm({ ...formData, [e.target.name]: e.target.value });
    };

  useEffect(() => {
    setProfileForm(hasProfileFormValue);
  }, [hasProfileFormValue]);

  useEffect(() => {
    setImageUrl(imageUrl);
  }, [imageUrl]);

  useEffect(() => {
    if (!profileData.error && Object.keys(profileData?.payload).length) {
      let profileInfo = profileData?.payload?.data;
      updateLocalStorage(profileInfo);
      setButtonLoading(false);
      notification.success({ message: "Success", description: profileData?.payload?.message, placement: "topRight" });
      dispatch(editProfileReset());
    } else {
      if (profileData?.error?.message) {
        setButtonLoading(false);
        notification.error({ message: "Error", description: profileData?.error?.message, placement: "topRight" });
      }
    }
  }, [profileData]);

  return (
    <>
      <Card className="profile-section profile-image-centered">
        {checkIfUserLoggedIn().userType === "musician" && <UploadProfilePhoto />}
        {checkIfUserLoggedIn().userType === "creator" && checkIfUserLoggedIn().profileImage && <ImageRenderer imageUrl={checkIfUserLoggedIn().profileImage} isAvatar={false} className={"img-cover c-img image-rounded"} height={70} width={70} />}
      </Card>
      <Form
        name="normal_login"
        initialValues={{
          remember: true,
        }}
        onFinish={onUpdateProfile}
      >
        <Card className="profile-section">
          <Title level={3}>Personal Details</Title>
          {checkIfUserLoggedIn().userType === "musician" && (
            <Form.Item
              name={["user", "userName"]}
              initialValue={hasProfileFormValue.userName}
              rules={[
                {
                  required: true,
                  message: "Please input your User Name",
                },
              ]}
              hasFeedback
            >
              <FloatInput size="large" label="Name" placeholder="Name" type="text" name="userName" values={hasProfileFormValue.userName} onChange={(e) => onChange(e)} />
            </Form.Item>
          )}
          <Form.Item
            name={["user", "email"]}
            initialValue={hasProfileFormValue.email}
            rules={[
              {
                type: "email",
                message: "The input is not valid E-mail",
              },
              {
                required: true,
                message: "Please input your Email",
              },
            ]}
            hasFeedback
          >
            <FloatInput size="large" placeholder="Email" label="Email" type="text" name="email" values={hasProfileFormValue.email} onChange={(e) => onChange(e)} />
          </Form.Item>
          {checkIfUserLoggedIn().userType === "creator" && (
            <>
              <Form.Item
                name={["user", "tiktokHandle"]}
                initialValue={hasProfileFormValue?.tiktokHandle}
                rules={[
                  {
                    required: true,
                    message: "Please input your tiktok handle",
                  },
                ]}
                hasFeedback
              >
                <FloatInput size="large" disabled={true} placeholder="TikTok Handle" label="TikTok Handle" type="text" name="tiktokHandle" values={hasProfileFormValue.tiktokHandle} onChange={(e) => onChange(e)} />
              </Form.Item>
              <Form.Item
                name={["user", "country"]}
                initialValue={hasProfileFormValue?.country}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Please select  Country",
                  },
                ]}
              >
                <Select defaultValue={hasProfileFormValue?.country} size={"large"} onChange={handleSelectChange} disabled className="select-disabled">
                  {stripeCountryList &&
                    stripeCountryList.map((item) => {
                      return (
                        <Option key={item.code} value={item.code}>
                          {item.name}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </>
          )}
        </Card>
        <Card className="profile-section">
          <Title level={3}>Change Password</Title>
          <Form.Item name="userPassword" hasFeedback>
            <FloatInput
              size="large"
              label="Password"
              placeholder="Password"
              type="password"
              name="userPassword"
              values={hasProfileFormValue.userPassword}
              onChange={(e) => onChange(e)}
              rules={[
                {
                  pattern: new RegExp(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/),
                  message: "Minimum eight characters, at least one letter, one number and one special character:",
                },
              ]}
              hasFeedback
            />
          </Form.Item>
          <Form.Item
            className="mb0"
            name="confirm"
            // label="Confirm Password"
            dependencies={["userPassword"]}
            hasFeedback
            rules={[
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("userPassword") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error("The two passwords that you entered do not match"));
                },
              }),
            ]}
          >
            <FloatInput size="large" label="Confirm Password" placeholder="Confirm Password" type="password" name="confirmPassword" values={hasProfileFormValue.confirmPassword} onChange={(e) => onChange(e)} />
          </Form.Item>
          <Form.Item className="mt4">
            <Button size="large" type="primary" block htmlType="submit" loading={buttonLoading}>
              <span className="f18">
                <FontAwesomeIcon icon={faSave} />
                Update
              </span>
            </Button>
          </Form.Item>
        </Card>
      </Form>
    </>
  );
};

export default Profile;
