import React, { useState, useRef, useEffect } from "react";
import { Button, Modal } from "antd";
import AddCard from "../AddCard";
import "../../assets/styles/modal.scss";
import { RightArrow } from "../../CommonWidgets/FontIcons";

const AddCardModal = (props) => {
  const [isDataLoading, setDataLoading] = useState(false),
  [isSubmitDisabled, setIsDisabledSubmit] = useState(false),
    cardRef = useRef(null),
    setNewData = (data) => {
      props.updateTable(data);
    },
    updateLoadingData = (isLoad) => {
      setDataLoading(isLoad);
    };

    useEffect(()=> {
      setIsDisabledSubmit(isSubmitDisabled);
    }, [isSubmitDisabled]);

  return (
    <Modal
      open={props.open}
      mask={true}
      title="Add Card"
      centered
      onCancel={props.close}
      closable={false}
      footer={[
        <Button key="submit" disabled={isSubmitDisabled} type="primary" size={"large"} className="m0" loading={isDataLoading} onClick={() => cardRef.current.onAddCard()}>
          Submit <RightArrow/>
        </Button>, 
      ]}
    >
      <AddCard ref={cardRef} updateData={setNewData} loadingUpdate={updateLoadingData} close={props.close} disableSubmit={(value)=>setIsDisabledSubmit(value)} />
    </Modal>
  );
};

export default AddCardModal;
