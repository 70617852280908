import React, { useState } from "react";
import { Input, DatePicker, Button } from "antd";
import "./index.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/pro-regular-svg-icons";
import Colors from "../../../theme/Colors";
const { TextArea } = Input;

const FloatInput = (props) => {
  const [focus, setFocus] = useState(false);
  let { label, values, placeholder, type, required, name, vType, className, disabled, maxLength, icon, onClick, btnType, extras } = props;

  let isOccupied = focus || (values && values.length !== 0);
  let labelClass = isOccupied ? "label label-float" : "label";
  className = className ? className : "";
  let fieldType = type ? type : "text";
  let placeHolderClass = vType === "textarea" ? "placeholder noselect txt-placeholder" : "placeholder noselect";
  labelClass = vType === "date" ? labelClass + " fld-date" : labelClass;
  icon = icon || faSearch;
  return (
    <div className="float-label noselect" onBlur={() => setFocus(false)} onFocus={() => setFocus(true)}>
      {vType === "textarea" && <TextArea onChange={props.onChange} defaultValue={values} name={name} className={className} />}
      {(type === "text" || type === "password") && (
        <>
          <Input.Group compact className="flex">
            <Input
              onChange={props.onChange}
              onPressEnter={() => {
                onClick && onClick();
              }}
              type={fieldType}
              defaultValue={values}
              name={name}
              maxLength={maxLength}
              {...extras}
            />
            {vType === "search" && (
              <Button
                type={btnType}
                block
                onClick={() => {
                  onClick && onClick();
                }}
              >
                <FontAwesomeIcon icon={icon} />
              </Button>
            )}
          </Input.Group>
        </>
      )}
      {vType === "date" && <DatePicker disabled={disabled} suffixIcon={null} className={className} onChange={props.onChange} format={"MM/DD/YYYY"} disabledDate={props.disabledDate} defaultValue={values} />}

      {focus && !values && (
        <div className={placeHolderClass} style={{ color: Colors.lightText }}>
          {placeholder}
        </div>
      )}

      <label className={labelClass} style={{ color: Colors.lightText }}>
        <span className={"required noselect"}>{required && "* "}</span> {icon && vType === "search" && <FontAwesomeIcon icon={icon} />} {label}
      </label>
    </div>
  );
};

export default FloatInput;
