import React from "react";
import { Radio, List } from "antd";

import "../../assets/styles/modal.scss";
import "./index.scss";
import Colors from "../../../theme/Colors";
import ShippingAddress from "..";

const ShippingAddressTile = (props) => {
  const item = props?.shippingAddressItem;

  return (
    <List.Item key={item?._id} onClick={() => props?.onChange(item?._id)} className={"shipping-list-item"} style={{ backgroundColor: Colors.deepPurple, border: props?.selected ? `1px solid ${Colors.primaryColor}` : null, borderRadius: "8px", padding: "20px" }}>
      <List.Item.Meta avatar={props?.from === "offer" && <Radio value={item?._id}></Radio>} description={<ShippingAddress item={item} />} className="flex" />
    </List.Item>
  );
};

export default ShippingAddressTile;
