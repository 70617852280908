import React, { forwardRef, useState, useEffect } from "react";
import { Timeline, Typography } from "antd";
import TimelineTile from "./TimelineTile";
import "./index.scss";
import VideoPlayer from "../VideoPlayer";
import timelineStatusList from "../../config/timelineStatus";
import { checkIfUserLoggedIn } from "../../helper";
import ShippingAddress from "../ShippingAddress/index";
import TrackingInformation from "../TrackingInformation";

const { Text } = Typography;
const OfferTimeline = forwardRef((props, ref) => {
  // Used to make the Timeline component responsive.
  const [matches, setMatches] = useState(window.matchMedia("(min-width: 768px)").matches);

  useEffect(() => {
    window.matchMedia("(min-width: 768px)").addEventListener("change", (e) => setMatches(e.matches));
  }, []);

  const revisions = props?.data?.videoRevisions,
    formatImage = (item) => {
      return item?.updatedBy === "musician" ? props?.data?.marketerProfileImage : props?.data?.creatorProfileImage;
    },
    validateIfTimelineExist = (item) => {
      let timelineData = timelineStatusList.filter((statusRow) => statusRow.key === item.currentStatus);
      if (timelineData[0] && timelineData[0]?.key) {
        return true;
      }
      return false;
    },
    getVideoContent = (videoUrl, tiktokUrl) => {
      return (
      <VideoPlayer videoUrl={videoUrl} height={"235px"} />
      )
    },
    getTiktokUrl = (tiktokUrl) => {
      return (
        tiktokUrl && (
          <div>
          <Text> Tiktok Url: </Text>
          <Text className="label-text">{tiktokUrl}</Text>
        </div>
       )
      )
    },
    getShippingDetail = (data) => {
      return (
        <>
          <ShippingAddress item={data} />
        </>
      );
    },
    getTrackingDetail = (trackingId, shippingCompanyName) => {
      return (
        <>
          <TrackingInformation item={{ trackingId, shippingCompanyName }} />
        </>
      );
    },
    formatTimelineItem = (item) => {
      let timelineData = timelineStatusList.filter((statusRow) => statusRow.key === item.currentStatus);
       if (timelineData[0] && timelineData[0]?.key) {
        item.statusText = timelineData[0].statusText;
        item.className = timelineData[0].whoAmI.includes(checkIfUserLoggedIn().userType) ? "" : "other-user-tile-reverse";
        if (item?.currentStatus === "video-rejected") {
          item.content = item?.rejectReason;
          item.contentType = "Feedback";
        }
        if (item?.currentStatus === "pending-shipment") {
          item.content = getShippingDetail(item?.shippingAddress);
          item.contentType = "Address";
        }
        if (item?.currentStatus === "pending-receipt") {
          item.content = getTrackingDetail(item?.trackingId, item?.shippingCompanyName);
          item.contentType = "Tracking Information";
        }
       
        if (item?.currentStatus === "video-submitted") {
          item.content = getVideoContent(item?.videoUrl, null);
          item.contentType = "Video";
        }
        if (item?.currentStatus === "completed") {
          console.log(item);
          item.content = getVideoContent(item?.videoUrl);
          item.subContent =  getTiktokUrl(item?.tiktokUrl);
          item.contentType = "Video";
        }
        item.key = item?.currentStatus;
        return item;
      }
      return null;
    };

  return (
    <>
      <Timeline mode={matches ? "alternate" : "left"}>
        {revisions &&
          revisions.map((item) => {
            return (
              validateIfTimelineExist(item) && (
                <Timeline.Item position={item.updatedBy === "creator" ? "right" : "left"} color={"var(--secondary-color)"}>
                  <TimelineTile statusItem={formatTimelineItem(item)} profileImage={formatImage(item)} />
                </Timeline.Item>
              )
            );
          })}
      </Timeline>
    </>
  );
});

OfferTimeline.displayName = "OfferTimeline";
export default OfferTimeline;
