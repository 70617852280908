const timelineStatusList = [
  { key: "sent", statusText: "Offer Sent", whoAmI: ["musician"] },
  { key: "pending-shipment", statusText: "Address Shared", whoAmI: ["musician"] },
  { key: "pending-receipt", statusText: "Package Sent", whoAmI: ["creator"] },
  { key: "video-in-progress", statusText: "Offer Accepted", whoAmI: ["creator"] },
  { key: "video-submitted", statusText: "Video Uploaded", whoAmI: ["creator"] },
  { key: "video-approved", statusText: "Video Approved", whoAmI: ["musician"] },
  { key: "rejected", statusText: "Offer Rejected", whoAmI: ["musician"] },
  { key: "cancelled", statusText: "Offer Cancelled", whoAmI: ["musician"] },
  { key: "video-rejected", statusText: "Revision Requested", whoAmI: ["musician"] },
  { key: "completed", statusText: "Video Completed", whoAmI: ["musician"] },
];

export default timelineStatusList;
