import React, {useState} from "react";
import ForgotPassword from "./ForgotPassword";
import Login from "./Login";
import SignUp from "./SignUp";

const Auth = () => {
  const [newStep, setNewStep] = useState(1),
    redirectTo = () => {
      let openComponent;
      switch (newStep) {
        case 1:
          openComponent = <Login updateNewStep={(newStep)=>setNewStep(newStep)} />;
          break;
        case 2:
          openComponent = <SignUp updateNewStep={(newStep)=>setNewStep(newStep)} />;
          break;
        case 3:
          openComponent = <ForgotPassword updateNewStep={(newStep)=>setNewStep(newStep)} />;
          break;
          default:
            openComponent = <Login updateNewStep={(newStep)=>setNewStep(newStep)} />;
          break;
      }
      return openComponent;
    };
  return <>{redirectTo()}</>;
};
export default Auth;
