import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Table, Typography, Spin, notification, Form, Card } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHandshakeAngle, faXmarkCircle } from "@fortawesome/pro-solid-svg-icons";
import { getOffersRequest } from "../../redux/actions/list-offer";
import "./index.scss";
import moment from "moment";
import AntSpinner from "../../components/CommonWidgets/AntSpinner";
import { formatNumber, checkIfUserLoggedIn, trimSpace } from "../../helper";
import NoDataFound from "../../components/Result/NoDataFound";
import CreatorDashboard from "../../components/CreatorDashboard/index";
import ImageRenderer from "../../components/CommonWidgets/ImageRenderer";
import OfferModal from "../../components/CommonWidgets/OfferModal";
import { faBullhorn } from "@fortawesome/pro-regular-svg-icons";
import { createChannel } from "../../modules/pubnub-module";
import OfferDetailsModal from "../../components/OfferDetailsModal/index";
import FloatInput from "../../components/CommonWidgets/FloatInput";
import PillSelector from "../../components/PillSelector";
import StatusItem from "./StatusItem";
import useAnalytics from "../../hooks/useAnalytics";
import BoostCampaigns from "../../components/BoostCampaigns";
import { updateBoostRequest, updateBoostReset } from "../../redux/actions/update-boost";
import { getBoostProductsRequest } from "../../redux/actions/list-products-boost";

// @ts-ignore

const { Title, Text } = Typography;

const ListOffers = (props) => {
  const dispatch = useDispatch(),
    { trackCampaignsViewed, trackBoostOrderCompleted, trackBoostOrderFailed } = useAnalytics(),
    history = useHistory(),
    [filters, setFilters] = useState({
      offers: true,
      inProgress: true,
      completed: false,
      cancelled: false,
    }),
    [activeOffer, setActiveOffer] = useState(null),
    [videoSending, setVideoSending] = useState(false),
    [videoUploaded, setVideoUploaded] = useState(false),
    [offerDetailsModal, setofferDetailsModal] = useState(false),
    [messageModal, openMessageModal] = useState(false),
    [currentStatus, setCurrentStatus] = useState(null),
    [offerDetails, setOfferDetails] = useState(null),
    [isLoading, setIsLoading] = useState(false),
    [offersData, setOffersData] = useState([]),
    { listOfferData } = useSelector((state) => ({ listOfferData: state.listOfferData })),
    [masterOffersData, setMasterOffersData] = useState([]),
    [searchInput, setSearchInput] = useState(null),
    getFilteredData = (offerData) => {
      if (!offerData) return [];
      return getSortedData(
        offerData.filter(
          (data) =>
            (filters.offers && data.currentStatus === "sent") ||
            (filters.inProgress &&
              (data.currentStatus === "pending-shipment" ||
                data.currentStatus === "pending-receipt" ||
                data.currentStatus === "video-in-progress" ||
                data.currentStatus === "video-submitted")) ||
            data.currentStatus === "video-approved" ||
            (filters.completed && data.currentStatus === "completed") ||
            (filters.cancelled &&
              (data.currentStatus === "cancelled" || data.currentStatus === "video-rejected"))
        )
      );
    },
    onSearch = () => {
      let filteredData = masterOffersData || [];
      if (searchInput && searchInput.length > 0) {
        filteredData = filteredData?.filter(
          (items) =>
            items.currentStatus.toLowerCase().includes(searchInput.toLowerCase()) ||
            items.otherUserName.toLowerCase().includes(searchInput.toLowerCase()) ||
            items.offerTitle.toLowerCase().includes(searchInput.toLowerCase())
        );
      }

      filteredData = getFilteredData(filteredData);

      setOffersData(filteredData);
    },
    getSortedData = (offerData) => {
      if (!offerData) return [];
      return offerData.sort((a, b) => moment(b.updatedAt) - moment(a.updatedAt));
    },
    onFilter = (key, value) => {
      setFilters({
        ...filters,
        [key]: value,
      });
    },
    updateNewData = (row) => {
      let clonedOffersData = [...offersData];
      let index = clonedOffersData.findIndex((d) => d._id === row._id);
      clonedOffersData[index] = row;
      setOffersData(getFilteredData(clonedOffersData));
    },
    formatNameField = (userName, record) => {
      let profileImage =
        record.whoAmI === "musician" ? record?.creatorProfileImage : record?.marketerProfileImage;

      return (
        <div className="flex align-items-center">
          <ImageRenderer
            imageUrl={profileImage}
            isAvatar={true}
            height={50}
            width={50}
            className={"lh47 f30"}
          />
          <div className="flex flex-column ml2">
            <Text className="text-bold">{record.offerTitle}</Text>
            <Text>{userName}</Text>
            <Text className={"secondary-text"}>{moment(record.createdAt).format("MM/DD/YY")}</Text>
          </div>
        </div>
      );
    },
    setStatus = (status, record) => {
      return <StatusItem record={record} status={status} />;
    },
    setColumns = () => {
      return [
        {
          title: "Campaign",
          dataIndex: "offerTitle",
          key: "offerTitle",
          render: (_, record) => formatNameField(record.otherUserName, record),
        },
        {
          title: "Sound",
          dataIndex: "soundTitle",
          key: "soundTitle",
          render: (_, record) => record.sound.title,
        },
        {
          title: "Status",
          dataIndex: "currentStatus",
          key: "currentStatus",
          render: (status, record) => setStatus(status, record),
        },

        {
          title: "Due",
          dataIndex: "deadline",
          key: "deadline",
          render: (date, record) =>
            record.currentStatus === "completed" || record.currentStatus === "cancelled" ? (
              <div>-</div>
            ) : (
              <>
                <div>{moment(date).utc().fromNow()}</div>
                <div className={"secondary-text"}>{moment(date).format("MM/DD/YY")}</div>
              </>
            ),
        },

        {
          title: "Amount",
          dataIndex: "finalPrice",
          key: "finalPrice",
          align: "right",
          render: (finalPrice) => (finalPrice ? `${formatNumber(finalPrice)} ` : "0"),
        },
      ];
    };

  useEffect(() => {
    setIsLoading(true);
    dispatch(getOffersRequest());
  }, []);

  useEffect(() => {
    onSearch();
  }, [searchInput, filters]);

  useEffect(() => {
    if (!listOfferData.error && Object.keys(listOfferData?.payload).length) {
      setOffersData(getFilteredData(listOfferData?.payload?.data?.offerData));
      setMasterOffersData(listOfferData?.payload?.data?.offerData);
      setIsLoading(false);
      const search = window.location.search,
        params = new URLSearchParams(search),
        queryId = params.get("id");
      if (queryId && queryId?.length > 0) {
        const offerRecords = listOfferData?.payload?.data?.offerData,
          selectedData = offerRecords.filter((items) => items._id === queryId);
        if (selectedData && selectedData?.length > 0) openOfferDetailsModal(selectedData[0]);
      }
    } else {
      if (listOfferData?.error?.message) {
        setIsLoading(false);
        //    notification.error({ message: "Error", description: listOfferData?.error?.message, placement: "topRight" });
      }
    }
  }, [listOfferData]);

  const openOfferDetails = (offer) => {
    setActiveOffer(offer);
  };

  const checkWhoAmI = () => {
    return activeOffer?.whoAmI === "musician" ? 1 : 2;
  };
  const openOfferDetailsModal = (record) => {
    setOfferDetails(record);
    setActiveOffer(record);
    setofferDetailsModal(true);
    trackCampaignsViewed(record?.currentStatus);
  };

  const query = new URLSearchParams(props.location.search);
  const checkoutSessionId = query.get("session_id");
  const boostType = query.get("boostType");
  const [showBoostSuccessModal, setShowBoostSuccessModal] = useState(false);

  const { listProductsBoostData, updateBoostData } = useSelector((state) => ({
    listProductsBoostData: state.listProductsBoostData,
    updateBoostData: state.updateBoostData,
  }));

  useEffect(() => {
    if (checkoutSessionId && checkoutSessionId.length > 0) {
      dispatch(updateBoostRequest(checkoutSessionId, boostType));
    }
    dispatch(getBoostProductsRequest());
  }, []);

  useEffect(() => {
    if (!updateBoostData.error && Object.keys(updateBoostData?.payload).length) {
      setShowBoostSuccessModal(true);
      // window.rewardful("convert", { email: checkIfUserLoggedIn()?.email });
      const currentStatus = updateBoostData?.payload?.data?.status;
      notification.success({
        message: "Success",
        description: updateBoostData?.payload?.message,
        placement: "topRight",
      });
      if (currentStatus === "complete" || "expired") {
        const timer = setTimeout(() => {
          trackBoostOrderCompleted(updateBoostData?.payload?.data?.boostData);
          dispatch(updateBoostReset());
          window.history.pushState({}, document.title, "/offers");
        }, 2000);
        return () => clearTimeout(timer);
      }
    } else {
      console.log("sdfdsf", updateBoostData?.error?.message);
      if (updateBoostData?.error?.message) {
        trackBoostOrderFailed(updateBoostData?.error?.data?.boostData);
        notification.error({
          message: "Error",
          description: updateBoostData?.error?.message,
          placement: "topRight",
        });
      }
    }
  }, [updateBoostData]);

  return (
    <>
      <Spin indicator={AntSpinner} spinning={isLoading} size={"large"}>
        {checkIfUserLoggedIn() && checkIfUserLoggedIn().userType === "creator" && (
          <div className={"mb5"}>
            <CreatorDashboard dashboardData={listOfferData?.payload?.data?.dashboardData} />
          </div>
        )}
        <BoostCampaigns />
        <Card className="creator-campaigns">
          <div className="flex flex-row justify-content-between">
            <Title level={1} className={"mt2"}>
              <span>
                <FontAwesomeIcon icon={faHandshakeAngle} /> Creator Campaigns
              </span>
            </Title>
          </div>
          <Form>
            <div className={`search-user-container flex-column`}>
              <div className="search-user-item">
                <Form.Item hasFeedback>
                  <FloatInput
                    label="Search"
                    values={searchInput}
                    type="text"
                    size="large"
                    placeholder="Username or Offer status"
                    onChange={(e) => setSearchInput(e.target.value)}
                    vType="search"
                    btnType="primary"
                    onClick={() => onSearch()}
                  />
                </Form.Item>
              </div>
              <div className="search-user-item align-self-left filter-user-item">
                <Form.Item hasFeedback>
                  <PillSelector
                    isSelected={filters.offers}
                    text={"Offers"}
                    onSelectionChanged={() => onFilter("offers", !filters.offers)}
                  />
                </Form.Item>
                <Form.Item hasFeedback>
                  <PillSelector
                    isSelected={filters.inProgress}
                    text={"In-Progress"}
                    onSelectionChanged={() => onFilter("inProgress", !filters.inProgress)}
                  />
                </Form.Item>
                <Form.Item hasFeedback>
                  <PillSelector
                    isSelected={filters.completed}
                    text={"Completed"}
                    onSelectionChanged={() => onFilter("completed", !filters.completed)}
                  />
                </Form.Item>
                <Form.Item hasFeedback>
                  <PillSelector
                    isSelected={filters.cancelled}
                    text={"Cancelled"}
                    onSelectionChanged={() => onFilter("cancelled", !filters.cancelled)}
                  />
                </Form.Item>
              </div>
            </div>
          </Form>
        </Card>

        {/* <Search size="large" onSearch={onSearch} enterButton className="search" /> */}
        <>
          {offersData.length > 0 ? (
            <Table
              columns={setColumns()}
              dataSource={offersData}
              scroll={{ x: 700 }}
              pagination={{
                pageSize: 5,
              }}
              className={"offer-list mt5"}
              rowKey="_id"
              onRow={(record) => {
                return {
                  onClick: () => {
                    //redirectToOfferDetail(record);
                    openOfferDetailsModal(record);
                  }, // click row
                };
              }}
            />
          ) : (
            <NoDataFound showContent={isLoading} />
          )}
        </>
      </Spin>
      <OfferModal
        open={offerDetailsModal}
        title={"Campaign Overview"}
        onClose={() => {
          setActiveOffer(null);
          setofferDetailsModal(false);
          setVideoUploaded(false);
          setVideoSending(false);
          //  dispatch(resetSignedUrl());
        }}
        icon={faBullhorn}
      >
        <OfferDetailsModal
          data={offerDetails}
          closeModal={() => setofferDetailsModal(false)}
          isLoading={isLoading}
          updateRow={(row) => updateNewData(row)}
        />
      </OfferModal>

      <OfferModal
        open={messageModal}
        onClose={() => {
          openMessageModal(false);
          setCurrentStatus(null);
        }}
        width={"400px"}
        title={""}
        height={"320px"}
        disableMinHeight
        footer={<p></p>}
        bodyStyle={{ display: "flex", justifyContent: "center" }}
      >
        <div className="text-center flex flex-column justify-content-center">
          <h2 className="text-white">
            <FontAwesomeIcon icon={faXmarkCircle} className="large-icon" />
          </h2>
          <h3>{currentStatus?.title}</h3>
          <p className="mt4">{currentStatus?.message}</p>
        </div>
      </OfferModal>
    </>
  );
};

export default ListOffers;
