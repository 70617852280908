import React, { useState } from "react";
import { Typography, Button } from "antd";
import "./index.scss";
import { LeftArrow, RightArrow } from "../../CommonWidgets/FontIcons";
import ReactQuill from "react-quill";
import "../../assets/styles/quill.scss";
import VideoPlayer from "../../VideoPlayer";
const { Title, Text } = Typography;

const MarketerReviewVideoAction = (props) => {
  console.log("marketer review video", props);
  const [revisionStep, setRevisionStep] = useState(0),
    [rejectionRemarks, setRejectionRemarks] = useState(""),
    [clicked, setClicked] = useState(""),
    wrapCompletedStatus = props?.needTiktokUpload === true ? "video-approved" : "completed",
    clickedAction = (data) => {
      console.log("data?.newStatus", data?.newStatus);
      setClicked(data?.newStatus);
      switch (data?.newStatus) {
        case "video-approved":
        case "completed":
          props.reviewAction(data?.newStatus);
          break;
        case "video-rejected":
          if (props?.revisionCount === 0) {
            props.reviewAction("completed");
          } else {
            props.reviewAction(data?.newStatus, { message: data.message });
          }
          break;
        default:
          break;
      }
      return true;
    };

  return (
    <div>
      <Title level={4}>{"Review Video"}</Title>
      {revisionStep === 0 ? (
        <>
          <Text>Here you can review the video and make sure that everything looks right.</Text>
          <div className="review-video">
            <div>
              <VideoPlayer videoUrl={props?.videoUrl} height={"260px"} />
            </div>
            <div>
              <Button type={"primary"} loading={clicked === wrapCompletedStatus ? props?.loaderStatus : false} onClick={() => clickedAction({ newStatus: wrapCompletedStatus })}>
                Approve Video
                <RightArrow />{" "}
              </Button>
            </div>
            <div>
              <a className="text-white" onClick={() => setRevisionStep(1)}>
                Request a revision
              </a>
            </div>
          </div>
        </>
      ) : (
        <>
          <Text>What should they change?</Text>

          <div className="review-video">
            <div>
              {/* <FloatInput  size="large" placeholder="Be Specific! Help the creator adjust by providing them with timestamps of what to fix" disabled={false} label="Feedback" name="rejectionRemarks" vType="textarea" className="h-100" required={true} values={rejectionRemarks} onChange={(e) =>setRejectionRemarks(e.target.value)} /> */}

              <ReactQuill theme="snow" name="rejectionRemarks" value={rejectionRemarks} onChange={(e) => setRejectionRemarks(e)} placeholder="Be Specific! Help the creator adjust by providing them with timestamps of what to fix" />
            </div>
            <div className="offer-action">
              <div>
                <Button type={"default"} onClick={() => setRevisionStep(0)}>
                  <LeftArrow /> Back
                </Button>
              </div>
              <div>
                <Button type={"primary"} loading={clicked === "video-rejected" ? props?.loaderStatus : false} onClick={() => clickedAction({ newStatus: "video-rejected", message: rejectionRemarks })}>
                  Request Revision <RightArrow />
                </Button>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default MarketerReviewVideoAction;
