import React from "react";
import { Radio } from "antd";
import ShippingAddressTile from "../ShippingAddressTile";
import "./index.scss";

const ShippingAddressFormatter = (props) => {
  const shippingAddressDetail = props?.addressData,
    onChange = (e) => {
      let selectedValue = e?.target && e?.target?.value ? e.target.value : e;
      props.setValue(selectedValue);
      const selectedShippingRow = shippingAddressDetail.filter((row) => row._id === selectedValue);
      props.selectedRow(selectedShippingRow[0]);
    };

  return (
    <Radio.Group onChange={onChange} value={props.value} className={"mb3 address-formatter"}>
      {shippingAddressDetail &&
        shippingAddressDetail.map((item, index) => {
          return <ShippingAddressTile key={index} onChange={onChange} shippingAddressItem={item} from={"offer"} selected={props.value === item?._id} />;
        })}
    </Radio.Group>
  );
};

export default ShippingAddressFormatter;
