import React, { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Select,
  Checkbox,
  Typography,
  Form,
  notification,
  Input,
  Button,
  Slider,
  Card,
} from "antd";
import FloatInput from "../../components/CommonWidgets/FloatInput";
import { createBoostRequest, createBoostReset } from "../../redux/actions/boost";
import boostCountryList from "../../config/boostCountries";
import boostCitiesList from "../../config/boostCities";
import useAnalytics from "../../hooks/useAnalytics";
import "./index.scss";

import BudgetInfo from "../CommonWidgets/BudgetInfo";
import { getRange } from "../../helper";
import { createEvergreenBoostRequest } from "../../redux/actions/evergeen-boost";
const { Title, Text } = Typography;
const { Option } = Select;
const CheckboxGroup = Checkbox.Group;

const BoostInfoModal = forwardRef(
  (
    { boostDetail, checkoutSessionId, loadingUpdate, validateFormValues, isDisabled, isLoading },
    ref
  ) => {
    console.log({ boostDetail });
    const plainOptions = ["13-17", "18-24", "25-34", "35-44", "45-54", "55+"],
      dispatch = useDispatch(),
      { trackBoostOrderStarted, trackBoostOrderFailed } = useAnalytics(),
      [form] = Form.useForm(),
      { boostData } = useSelector((state) => ({ boostData: state.boostData })),
      [priceChange, setPriceChange] = useState(boostDetail.priceList[0].amount),
      [priceId, setPriceId] = useState(boostDetail?.priceList[0]?.priceId),
      onSubmitBoost = (metaData, priceData) => {
        try {
          //  console.log("boostDetail.metaData", metaData);
          let data = {};
          data.amount = priceData?.price; //parseInt(boostDetail.priceList.filter((row) => row.priceId === priceChange)[0].amount) / 100; //parseInt(boostDetail.price.replace("$", "")) * 100;
          data.boostType = boostDetail.productKey;
          data.priceId = priceData?.priceId; // parseInt(boostDetail.priceList.filter((row) => parseInt(row.amount) * 100 === priceChange)[0].priceId); // boostDetail.priceId;
          data.productId = boostDetail.productId;
          data.metaData = [metaData];
          data.trafficUrl = hasBoostFormValue.trafficUrl;
          delete data.productKey;
          delete data.price;
          let finalData = { ...data, ...hasBoostFormValue };
          if (finalData.boostType === "infinite_growth") {
            dispatch(
              createEvergreenBoostRequest({
                ...finalData,
                fullPackage: +finalData.amount === boostDetail.amountList.at(-1),
              })
            );
          } else {
            dispatch(createBoostRequest(finalData));
          }
        } catch (error) {
          console.log("create boost error", error);
        }
      },
      [hasBoostFormValue, setBoostFormValue] = useState({
        authCode: "",
        trafficUrl: "",
        countries:
          boostDetail.productKey === "sell_more_tickets"
            ? []
            : ["Australia", "Canada", "United States", "United Kingdom"],
        ageGroups: plainOptions,
      }),
      onChange = (e) => {
        let formData = { ...hasBoostFormValue };
        setBoostFormValue({ ...formData, [e.target.name]: e.target.value });
      },
      children = [];
    if (boostDetail.productKey === "sell_more_tickets") {
      for (let i = 0; i < boostCitiesList.length; i++) {
        children.push(
          <Option key={boostCitiesList[i].code || `boostCity${i}`} value={boostCitiesList[i].name}>
            {boostCitiesList[i].name}
          </Option>
        );
      }
    } else {
      for (let i = 0; i < boostCountryList.length; i++) {
        children.push(
          <Option key={boostCountryList[i].code} value={boostCountryList[i].name}>
            {boostCountryList[i].name}
          </Option>
        );
      }
    }
    const onCountryChange = (value) => {
        let formData = { ...hasBoostFormValue };
        setBoostFormValue({ ...formData, ["countries"]: value });
      },
      onAgeChange = (value) => {
        let formData = { ...hasBoostFormValue };
        setBoostFormValue({ ...formData, ["ageGroups"]: value });
      },
      marks = configureMarks();

    function configureMarks() {
      if (boostDetail.priceList) {
        return getRange(boostDetail.priceList);
      }
    }

    useEffect(() => {
      if (!boostData.error && Object.keys(boostData?.payload).length) {
        notification.success({
          message: "Success",
          description: boostData?.payload?.message,
          placement: "topRight",
        });
        const timer = setTimeout(() => {
          trackBoostOrderStarted(boostData?.payload?.data?.name, boostData?.payload?.data?.price);
          dispatch(createBoostReset());
          loadingUpdate(false);
          window.location.href = boostData?.payload?.data?.redirectUrl;
        }, 1000);

        return () => clearTimeout(timer);
      } else {
        if (boostData?.error?.message) {
          console.log("boostData?.payload?.error?.data?.price", boostData?.error);
          trackBoostOrderFailed({ amount: boostData?.error?.data?.price });
          notification.error({
            message: "Error",
            description: boostData?.error?.message,
            placement: "topRight",
          });
        }
      }
    }, [boostData]);

    useEffect(() => {
      validateFormValues(hasBoostFormValue, boostDetail?.metaData?.url);
    }, [hasBoostFormValue]);

    return (
      <Form
        name="normal_login"
        form={form}
        initialValues={{
          remember: true,
        }}
      >
        <Title level={4}>
          <span>Authorization Code </span>
        </Title>
        <Form.Item
          name="authCode"
          rules={[
            {
              required: true,
              message: "Please input Auth Code",
            },
          ]}
          hasFeedback
        >
          <FloatInput
            maxLength={100}
            required={true}
            type="text"
            size="large"
            label="Authorization Code"
            placeholder="Enter Authorization Code*"
            name="authCode"
            values={hasBoostFormValue.authCode}
            onChange={(e) => onChange(e)}
          />
        </Form.Item>
        <Text type="secondary">
          Your authorization code is a unique sequence of characters associated with your post. This
          code allows us to boost your post for a period of time determined by you. You can cancel
          the code at any time, and it does not give us access to your account.
        </Text>
        <Title level={4}>
          <span>
            Target {boostDetail.productKey === "sell_more_tickets" ? "Markets" : "Countries"}
          </span>
        </Title>
        <Form.Item
          name="countries"
          initialValue={hasBoostFormValue.countries}
          rules={[
            {
              required: true,
              message: "Plese select countries!",
            },
          ]}
          hasFeedback
        >
          <Select
            mode="tags"
            name="countries"
            placeholder="Where should we target?"
            onChange={onCountryChange}
            initialValue={hasBoostFormValue.countries}
          >
            {children}
          </Select>
        </Form.Item>
        <Title level={4}>
          <span>Target Ages</span>
        </Title>
        <Form.Item
          name="ageGroups"
          rules={[
            {
              required: true,
              message: "Please select age group!",
            },
          ]}
          className="checkbox-input"
          initialValue={plainOptions}
          hasFeedback
        >
          <CheckboxGroup options={plainOptions} value={plainOptions} onChange={onAgeChange} />
        </Form.Item>
        <Form.Item name={"productKey"} style={{ display: "none" }}>
          <Input type="hidden" defaultValue={boostDetail.productKey} name="productKey" />
        </Form.Item>
        {boostDetail?.metaData?.url && boostDetail?.metaData?.url !== "false" && (
          <>
            <Title level={4}>
              <span>
                {boostDetail.productKey === "infinite_growth" ? "Spotify" : "Landing Page"} URL
              </span>
            </Title>

            <Form.Item
              name="trafficUrl"
              hasFeedback
              rules={[
                {
                  pattern:
                    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&=]*)/,
                  message: "Please enter valid url",
                },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (
                      boostDetail?.metaData?.url &&
                      boostDetail?.metaData?.url === "true" &&
                      !value
                    ) {
                      return Promise.reject(["Please enter traffic url"]);
                    }
                    console.log(rule);
                    console.log(value);

                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <FloatInput
                type="text"
                size="large"
                label={
                  boostDetail?.metaData?.url === "optional"
                    ? `Traffic URL (${boostDetail?.metaData?.url})`
                    : "Traffic URL"
                }
                placeholder="Enter Traffic URL"
                name="trafficUrl"
                values={hasBoostFormValue.trafficUrl}
                onChange={(e) => onChange(e)}
              />
            </Form.Item>
          </>
        )}
        <Card className="mt10 boost-card">
          <div className="text-center">
            <Title level={3} className="text-center">
              {" "}
              Start your campaign
            </Title>
            <Title level={5}> Invest in your music career </Title>
          </div>
          <div className="mt4">
            <Title level={5}> Select your budget: </Title>
          </div>

          <Form.Item name="price" initialValue={priceChange}>
            <BudgetInfo
              source="boost"
              marks={marks}
              onSubmitData={(metaInfo, price) => {
                onSubmitBoost(metaInfo, price);
              }}
              loadingUpdate={(value) => loadingUpdate(value)}
              isLoading={isLoading}
              isDisabled={isDisabled}
              budgetInfo={boostDetail}
              defaultPrice={boostDetail.defaultPrice}
            />
          </Form.Item>
        </Card>
      </Form>
    );
  }
);

BoostInfoModal.displayName = "Boost";
export default BoostInfoModal;
