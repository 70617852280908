const offerStatusList = [ 
     {key: "sent", statusText: "Offer Sent", whoAmI: ["musician"]}, 
     {key: "sent", statusText: "Offer Received",whoAmI: ["creator"]}, 
     {key: "rejected", statusText:  "Offer Rejected",  whoAmI: ["musician", "creator"]}, 
     {key: "cancelled", statusText:"Offer Cancelled",  whoAmI:  ["musician", "creator"]},
     {key: "pending-shipment", statusText: "Shipment-In-Progress", whoAmI: ["creator"]}, 
     {key: "pending-shipment", statusText: "Shipment Required",  whoAmI: ["musician"]}, 
     {key: "pending-receipt", statusText: "Shipment-In-Transit", whoAmI: ["musician"]}, 
     {key: "pending-receipt", statusText: "Mark as Received",  whoAmI: ["creator"]}, 
     {key: "video-in-progress", statusText: "Video-In-Progress", whoAmI: ["musician"]}, 
     {key: "video-in-progress", statusText: "Video Required",  whoAmI: ["creator"]}, 
     {key: "video-submitted", statusText: "Review Required",  whoAmI: ["musician"]}, 
     {key: "video-submitted", statusText: "Review In-Progress", whoAmI: ["creator"]},
     {key: "video-approved", statusText: "Tiktok Upload-In-Progress",  whoAmI: ["musician"]}, 
     {key: "video-approved", statusText: "Tiktok Url Required", whoAmI: ["creator"]},
     {key: "completed", statusText: "Video Completed", whoAmI: ["musician", "creator"]},
     {key: "video-rejected", statusText: "Revision Requested",  whoAmI: ["creator"]},
     {key: "video-rejected", statusText: "Video-In-Progress",  whoAmI: ["musician"]},
     {key: "offer-expired", statusText: "Offer Expired",  whoAmI: ["musician", "creator"]},
];

export default offerStatusList;