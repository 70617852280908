import React, { useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { checkIfUserLoggedIn } from "./helper";
import NavMenu from "./components/NavMenu/index";
import "./App.scss";
import ThemeProvider from "./theme/ThemeProvider";
import useAnalytics from "./hooks/useAnalytics";


function App() {
  let navType = checkIfUserLoggedIn()?.userType === "musician" ? "musician" : checkIfUserLoggedIn()?.userType === "creator" ? "creator" : "default";
  const {trackUserProperties} = useAnalytics();
  useEffect(() => {
    const linkElem = document.createElement("link");
    linkElem.href = "https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.5.2/animate.min.css";
    linkElem.rel = "stylesheet";
    document.head.appendChild(linkElem);
  }, []);

  useEffect(() => {
    if(navType !== "default") {
    trackUserProperties(checkIfUserLoggedIn()?.userType);
    } else {
    trackUserProperties(null);
    }
    }, [navType]);

  return (
    <ThemeProvider>
      <Router>
        <NavMenu type={navType} localData={checkIfUserLoggedIn()} />
      </Router>
    </ThemeProvider>
  );
}
export default App;
