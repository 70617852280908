import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Table, Button, Typography, notification, Popconfirm, Spin, Card } from "antd";
import { getCardsRequest } from "../../redux/actions/list-card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleDollar, faCreditCard, faTrash } from "@fortawesome/pro-solid-svg-icons";
import { deleteCardRequest, deleteCardReset } from "../../redux/actions/delete-card";
import AddCardModal from "../../components/Card/AddCardModal";
import AntSpinner from "../../components/CommonWidgets/AntSpinner";
import NoCardDataFound from "../../components/Result/NoCardDataFound";
const { Title } = Typography;

const MarketerCards = (props) => {
  const dispatch = useDispatch(),
    [cardData, setCardData] = useState([]),
    [selectedTableRowKey, setSelectedTableRowKey] = useState(""),
    [isLoading, setLoading] = useState(false),
    { listCardData, deleteCardData } = useSelector((state) => ({ listCardData: state.listCardData, deleteCardData: state.deleteCardData })),
    deleteRow = (record) => {
      setLoading(true);
      dispatch(deleteCardRequest(record));
    },
    updateCardData = (data) => {
      setCardData(data);
    },
    [showAddCardModal, setAddCardModal] = useState(false),
    columns = [
      {
        title: "Card Type",
        dataIndex: "cardType",
        key: "cardType",
      },
      {
        title: "Expiration Month/Year",
        dataIndex: "expiryMonthYear",
        key: "expiryMonthYear",
      },
      {
        title: "Card Number",
        dataIndex: "cardNumber",
        key: "cardNumber",
      },
      {
        title: "Action",
        dataIndex: "action",
        key: "action",
        render: (text, record) => (
          <Popconfirm title="Do you want to delete this record?" okText={"Ok"} okButtonProps={{ loading: isLoading }} onConfirm={() => deleteRow(record._id)}>
            <span className="actionHover">
              <FontAwesomeIcon icon={faTrash} /> 
            </span>
          </Popconfirm>
        ),
      },
    ],
    rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        setSelectedTableRowKey(selectedRowKeys);
            if (props.from) {
          props.getSelectedCard(selectedRowKeys[0]);
        }
      },
    };

  useEffect(() => {
    setLoading(true);
    dispatch(getCardsRequest());
  }, []);

  useEffect(() => {
    if (!deleteCardData.error && Object.keys(deleteCardData?.payload).length) {
      setCardData(deleteCardData?.payload?.data);
      notification.success({ message: "Success", description: deleteCardData?.payload?.message, placement: "topRight" });
      dispatch(deleteCardReset());
      setLoading(false);
    } else {
      if (deleteCardData?.error?.message) {
        setLoading(false);
        notification.error({ message: "Error", description: deleteCardData?.error?.message, placement: "topRight" });
      }
    }
  }, [deleteCardData]);

  useEffect(() => {
    setSelectedTableRowKey(selectedTableRowKey);
  }, [selectedTableRowKey]);

  useEffect(() => {
    if (!listCardData.error && Object.keys(listCardData?.payload).length) {
      setCardData(listCardData?.payload?.data);
      setLoading(false);
      setSelectedTableRowKey([listCardData?.payload?.data[0]._id]);
      if (props.from) {
         props.getSelectedCard(listCardData?.payload?.data[0]._id);
      }
    } else {
      if (listCardData?.error?.message) {
        setLoading(false);
        // notification.error({ message: "Error", description: listCardData?.error?.message, placement: "topRight" });
      }
    }
  }, [listCardData]);
  return (
    <>
      <Card>
      {cardData && cardData.length > 0 && (
        <Button type="primary" size="large" onClick={() => setAddCardModal(true)}>
          <FontAwesomeIcon icon={faCreditCard} /> Add a Card
        </Button>
      )}
        <Title level={3}>
          <span>Saved Cards</span>
        </Title>
        <Spin indicator={AntSpinner} spinning={isLoading} size={"large"}>
          {cardData && cardData.length > 0 ? (
            <Table
              className="offer-table"
              columns={columns}
              dataSource={cardData}
              rowKey={"_id"}
              rowSelection={
                props.from
                  ? {
                      type: "radio",
                      selectedRowKeys: selectedTableRowKey,
                      ...rowSelection,
                    }
                  : ""
              }
              onRow={(record, rowIndex) => {
                return {
                  onClick: (event) => {
                    setSelectedTableRowKey([record._id]);
                    if (props.from) {
                      props.getSelectedCard(record._id);
                    }
                  }, // click row
                };
              }}
            />
          ) : (
            <NoCardDataFound showContent={isLoading}  startAdd={() => setAddCardModal(true)} />
          )}
        </Spin>
      </Card>
      {showAddCardModal && <AddCardModal open={showAddCardModal} updateTable={updateCardData} close={() => setAddCardModal(false)} />}
    </>
  );
};

export default MarketerCards;
