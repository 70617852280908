import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Modal, Radio, Space, Select, notification, Typography, Form, Row, Col } from "antd";
import { creatorPaymentSetUpRequest, creatorPaymentSetUpReset } from "../../redux/actions/creator-payment-activate";
import "../assets/styles/modal.scss";
import FloatInput from "../CommonWidgets/FloatInput";
import { checkIfUserLoggedIn } from "../../helper";
import stripeCountryList from "../../config/stripeCountries";
import { RightArrow } from "../CommonWidgets/FontIcons";
const { Title, Text } = Typography,
    { Option } = Select;

const CreatorPaymentModal = (props) => {
    const [form] = Form.useForm(),
        [value, setValue] = useState(1),
        dispatch = useDispatch(),
        [loading, setLoading] = useState(false),
        { creatorPaymentSetUpData } = useSelector((state) => ({ creatorPaymentSetUpData: state.creatorPaymentSetUpData })),
        onRadioChange = (e) => {
            setValue(e.target.value);
        },
        onCompleteProfile = () => {
            const finalData = hasPaymentAccountFormValue;
            finalData.email = checkIfUserLoggedIn()?.email;
            finalData.businessType = (value === 1) ? "individual" : "company";
            setLoading(true);
            dispatch(creatorPaymentSetUpRequest(finalData));

        },
        [hasPaymentAccountFormValue, setPaymentAccountFormValue] = useState({
            firstName: "",
            lastName: "",
            country: "US",
            companyName: ""
        }),
        onChange = (e) => {
            const formData = { ...hasPaymentAccountFormValue };
            if (e.target) {
                setPaymentAccountFormValue({ ...formData, [e.target.name]: e.target.value });
            } else {
                setPaymentAccountFormValue({ ...formData, ["country"]: e });
            }
        };

    useEffect(() => {
        if (!creatorPaymentSetUpData.error && Object.keys(creatorPaymentSetUpData?.payload).length) {
            setLoading(false);
            const timer = setTimeout(() => {
                setLoading(false);
                window.location.href = creatorPaymentSetUpData?.payload?.data?.expressLink;
                dispatch(creatorPaymentSetUpReset());
            }, 1000);
            return () => clearTimeout(timer);
        } else {
            if (creatorPaymentSetUpData?.error?.message) {
                notification.error({ message: "Error", description: creatorPaymentSetUpData?.error?.message, placement: "topRight" });
                setLoading(false);
            }
        }
    }, [creatorPaymentSetUpData]);


    return (
        <Modal open={props.open} mask={true} className="payment-modal-title" title="Complete your profile" width={600} centered onCancel={props.close} closable={false} footer={null}>

            <Form
                name="normal_login"
                initialValues={{
                    remember: true,
                }}
                form={form}
                onFinish={onCompleteProfile}
            >
                <Space direction="vertical" className="choose-type mb6 w-full">
                    <Title level={4}> Account Type</Title>
                    <Radio.Group onChange={onRadioChange} value={value} size="small">
                        <Radio value={1}>Individual</Radio>
                        <Radio value={2}>Company</Radio>
                    </Radio.Group>
                </Space>
                <Space direction="vertical" className="w-full">
                    <>
                        <Title level={4}>{value === 1 ? "Personal" : "Company"} Information</Title>
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>

                            {value === 1 ?
                                <>
                                    <Col span={12}>

                                        <Form.Item
                                            name={["user", "firstName"]}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Please input first ame",
                                                },
                                            ]}
                                            hasFeedback
                                        >
                                            <FloatInput size="large" label="First Name" placeholder="First Name" type="text" name="firstName" values={hasPaymentAccountFormValue.firstName} onChange={(e) => onChange(e)} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            className="mb0"
                                            name={["user", "lastName"]}
                                            hasFeedback
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Please input last name",
                                                },
                                            ]}
                                        >
                                            <FloatInput size="large" label="Last Name" placeholder="Last Name" type="text" name="lastName" values={hasPaymentAccountFormValue.lastName} onChange={(e) => onChange(e)} />
                                        </Form.Item>
                                    </Col>
                                </>

                                :

                                <Col span={12}>
                                    <Form.Item
                                        className="mb0"
                                        name={["user", "companyName"]}
                                        hasFeedback
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please input company name",
                                            },
                                        ]}
                                    >
                                        <FloatInput size="large" label="Company Name" placeholder="Company Name" type="text" name="companyName" values={hasPaymentAccountFormValue.companyName} onChange={(e) => onChange(e)} />
                                    </Form.Item>
                                </Col>
                            }

                            <Col span={12}>
                                <Form.Item
                                    name={["user", "country"]}
                                    hasFeedback
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please select  Country",
                                        },
                                    ]}
                                >
                                    <Select defaultValue="US" name="country" size={"large"} onChange={(e) => onChange(e)}>
                                        {stripeCountryList && stripeCountryList.map(item => {
                                            return <Option key={item.code} value={item.code}>{item.name}</Option>
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Form.Item>
                            <Title level={4}> Payment Information</Title>
                            <div className="stripe-information text-white">
                                <Text level={4}>
                                    We use Stripe to make sure you get paid on time and to keep your personal bank and details secure. Click <strong>Save and continue</strong> to set up your payments on Stripe.
                                </Text>
                            </div>

                        </Form.Item>
                        <Form.Item>
                            <Button
                                size="large"
                                block
                                onClick={() => onCompleteProfile()}
                                type={"primary"}
                                loading={loading}
                            >
                                Save and Continue <RightArrow />
                            </Button>
                        </Form.Item>
                    </>
                </Space>
            </Form>
        </Modal>
    );
};

export default CreatorPaymentModal;