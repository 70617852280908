export * from "./styles";
export const CACHEVARIABLES = {
  FIND_CREATORS_CACHE: "creatorslist",
  CACHE_TIME: 8640000,
};

export const METRICS_OPTIONS = [
  { value: "impressions", label: "Impressions" },
  { value: "clicks", label: "Clicks" },
  { value: "comments", label: "Comments" },
  { value: "follows", label: "Follows" },
  { value: "profile_visits_rate", label: "Profile Visits Rate" },
  { value: "profile_visits", label: "Profile Visits" },
  { value: "shares", label: "Shares" },
  { value: "ctr", label: "CTR" },
  { value: "conversion", label: "Conversion" },
  { value: "conversion_rate", label: "Conversion Rate" },
];

export const AGE_GROUPS = ["13-17", "18-24", "25-34", "35-44", "45-54", "55+"];

export const OBJECTIVES = ["awareness", "conversion", "follows"];
