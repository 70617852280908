import React, { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Form, Row, Col, Switch, Select, Spin } from "antd";
import ReactQuill from "react-quill";
import AntSpinner from "../CommonWidgets/AntSpinner";
import FloatInput from "../CommonWidgets/FloatInput";
import { getSoundsRequest } from "../../redux/actions/list-sound";
import SoundModal from "../Sound/SoundModal";
import "react-quill/dist/quill.snow.css";
import "../assets/styles/modal.scss";
import "../assets/styles/quill.scss";
import moment from "moment";
import { isFormInValid } from "../../helper";
const { Option } = Select;

const CreateInstantCampaign = forwardRef((props, ref) => {
  console.log(props);
  const [form] = Form.useForm(),
    dispatch = useDispatch(),
    [soundData, setSoundData] = useState([]),
    [isLoading, setLoading] = useState(false),
    [currentRecord, setCurrentRecord] = useState({}),
    [showAddSoundButton, setShowAddSoundButton] = useState(false),
    [showSoundModal, setShowSoundModal] = useState(false),
    { listSoundData } = useSelector((state) => ({ listSoundData: state.listSoundData })),
    [hasAddInstantCampaignFormValue, setAddInstantCampaignFormValue] = useState({
      offerTitle: props.setDataToPage?.offerTitle?.length > 0 ? props.setDataToPage?.offerTitle : "",
      offerDesc: props.setDataToPage?.offerDesc?.length > 0 ? props.setDataToPage?.offerDesc : "",
      sound: {
        id: props.setDataToPage?.sound?.id?.length > 0 ? props.setDataToPage?.sound?.id : "",
        title: props.setDataToPage?.sound?.title?.length > 0 ? props.setDataToPage?.sound?.title : "",
        link: props.setDataToPage?.sound?.link?.length > 0 ? props.setDataToPage?.sound?.link : "",
      },
    }),
    onChange = (e) => {
      let formData = { ...hasAddInstantCampaignFormValue };
      if (e?.target?.name) {
        setAddInstantCampaignFormValue({ ...formData, [e.target.name]: e.target.value });
      }
    },
    onOfferDescChange = (e) => {
      let formData = { ...hasAddInstantCampaignFormValue };
      setAddInstantCampaignFormValue({ ...formData, ["offerDesc"]: e });
    },
    handleSelectSoundChange = (value, row = {}) => {
      if (value === "add") {
        setShowSoundModal(true);
      } else {
        let formData = { ...hasAddInstantCampaignFormValue },
          findSoundDetails = {};
        if (row && row?._id) {
          findSoundDetails = row;
        } else {
          findSoundDetails = soundData && soundData.filter((items) => items.soundTitle === value)[0];
          console.log("findSoundDetails", soundData, findSoundDetails);
          if (!findSoundDetails) findSoundDetails = soundData[0];
          //   console.log("findSoundDetails", value, soundData[0]);
        }
        formData.sound = {
          id: findSoundDetails?.id ? findSoundDetails?.id : findSoundDetails?._id,
          title: findSoundDetails?.soundTitle,
          link: findSoundDetails?.soundLink,
        };
        setAddInstantCampaignFormValue(formData);
      }
    };

  useImperativeHandle(ref, () => ({
    onAddOffer() {
      form
        .validateFields()
        .then(() => {
          if (!isFormInValid(form)) {
            props.updateData(hasAddInstantCampaignFormValue);
            props.setNext();
          }
        })
        .catch((info) => {
          console.log(info);
        });
    },
  }));

  useEffect(() => {
    setAddInstantCampaignFormValue(hasAddInstantCampaignFormValue);
    if (hasAddInstantCampaignFormValue?.offerTitle?.length > 0 && hasAddInstantCampaignFormValue?.offerDesc?.length > 0 && hasAddInstantCampaignFormValue?.sound?.id?.length > 0) {
      // alert("here");
      props.isNextDisabled(false);
    } else {
      props.isNextDisabled(true);
    }
  }, [hasAddInstantCampaignFormValue]);

  useEffect(() => {
    //   setLoading(true);
    dispatch(getSoundsRequest());
  }, []);

  useEffect(() => {
    if (!listSoundData.error && Object.keys(listSoundData?.payload).length) {
      setShowAddSoundButton(false);
      setSoundData(listSoundData?.payload?.data);
      setLoading(false);
    } else {
      if (listSoundData?.error?.error === true) {
        setShowAddSoundButton(true);
        setLoading(false);
      }
      //   notification.error({ message: "Error", description: listSoundData?.error?.message, placement: "topRight" });
    }
  }, [listSoundData]);

  useEffect(() => {
    setSoundData(soundData);
  }, [soundData]);

  useEffect(() => {
    setCurrentRecord(currentRecord);
  }, [currentRecord]);

  useEffect(() => {
    setShowAddSoundButton(showAddSoundButton);
  }, [showAddSoundButton]);

  return (
    <Spin indicator={AntSpinner} spinning={isLoading} size={"large"}>
      <Form
        name="addCampaign"
        form={form}
        initialValues={{
          remember: true,
        }}
        className="createCampaignModal"
      >
        <>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={12}>
              <Form.Item
                name={["offer", "offerTitle"]}
                initialValue={hasAddInstantCampaignFormValue.offerTitle}
                rules={[
                  {
                    required: true,
                    message: "Please input campaign name",
                  },
                ]}
                hasFeedback
              >
                <FloatInput maxLength={100} required={true} type="text" size="large" label="Campaign Name" placeholder="Enter Campaign Name" name="offerTitle" values={hasAddInstantCampaignFormValue.offerTitle} onChange={(e) => onChange(e)} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name={["offer", "soundId"]}
                initialValue={hasAddInstantCampaignFormValue?.sound?.title}
                rules={[
                  {
                    required: true,
                    message: "Please select Sound",
                  },
                ]}
                hasFeedback
              >
                <Select size={"large"} name="soundId" onChange={handleSelectSoundChange} defaultValue={hasAddInstantCampaignFormValue?.sound?.id} placeholder={"Select Sound"} required={true}>
                  <Option key="" value="">
                    Select Sound
                  </Option>
                  {soundData &&
                    soundData.map((item, i) => {
                      return (
                        <Option key={item._id} value={item._id}>
                          {item.soundTitle}
                        </Option>
                      );
                    })}
                  <Option key="add" value="add">
                    Create New Sound
                  </Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Form.Item name={["offer", "offerDesc"]} initialValue={hasAddInstantCampaignFormValue.offerDesc}>
            <ReactQuill theme="snow" name="offerDesc" value={hasAddInstantCampaignFormValue.offerDesc} onChange={(e) => onOfferDescChange(e)} placeholder="Tell us more about your song! How does your song fit within TikTok creators content? What does your ideal video look like? If you leave this section blank, our creators will do what they do best - come up with great ideas to promote your song!" />
          </Form.Item>
        </>
      </Form>

      {showSoundModal === true && (
        <SoundModal
          open={showSoundModal}
          updateSoundData={(data) => setSoundData(data)}
          defaultRecord={(row) => {
            console.log("new row", row);
            handleSelectSoundChange(row.soundTitle);
          }}
          source="offer"
          close={() => {
            setShowSoundModal(false);
          }}
        />
      )}
    </Spin>
  );
});

CreateInstantCampaign.displayName = "CreateInstantCampaign";
export default CreateInstantCampaign;
