export const calculateMetricsForMultipleObjectives = (data, type = "mainDashboard") => {
  const sumedMetrics = data.reduce(
    (acc, current) => {
      acc.spend += current.spend;
      acc.clicks += +current.metrics.clicks;
      acc.conversion += +current.metrics.conversion;
      acc.engaged_view += +current.metrics.engaged_view;
      acc.impressions += +current.metrics.impressions;
      acc.video_play_actions += +current.metrics.video_play_actions;
      acc.follows += +current.metrics.follows;
      return acc;
    },
    {
      spend: 0,
      impressions: 0,
      engaged_view: 0,
      clicks: 0,
      conversion: 0,
      video_play_actions: 0,
      follows: 0,
    }
  );

  const metrics = getMetricsArray(sumedMetrics, type);

  return metrics;
};

const getMetricsArray = (metrics, type) => {
  const { spend, impressions, engaged_view, clicks, conversion, video_play_actions, follows } =
    metrics;
  switch (type) {
    case "mainDashboard": {
      return [
        {
          value: impressions,
          label: "Impressions",
          tooltip: "Ad placements on FYP",
        },
        {
          value: video_play_actions,
          label: "Views",
          tooltip: "How many times your ad was viewed",
        },
        {
          value: engaged_view,
          label: "Engaged Views",
          tooltip: "Engaged Views",
        },
        {
          value: clicks,
          label: "Clicks",
          tooltip: "Clicks on your ad",
        },
        {
          value: Number(impressions > 0 ? (spend * 1000) / impressions : 0),
          label: "CPM",
          tooltip: "Cost per 1k impressions",
          moneyIndicator: true,
        },
        {
          value: Number(video_play_actions > 0 ? (1000 * spend) / video_play_actions : 0),
          label: "CP1KV",
          tooltip: "Cost per 1k views",
          moneyIndicator: true,
        },
        {
          value: Number(engaged_view > 0 ? (1000 * spend) / engaged_view : 0),
          label: "CPeV",
          tooltip: "Cost per engaged view",
          moneyIndicator: true,
        },
        {
          value: Number(clicks > 0 ? spend / clicks : 0),
          label: "CPC",
          tooltip: "Cost per click",
          moneyIndicator: true,
        },
        {
          value: follows,
          label: "Followers",
          tooltip: "Followers you’ve directly on the ad",
        },
        {
          value: Number(follows > 0 ? spend / follows : 0),
          label: "CPF",
          tooltip: "Cost per follower",
          moneyIndicator: true,
        },
        {
          value: conversion,
          label: "Conversion",
          tooltip: "Conversions on your ad",
        },
        {
          value: Number(conversion > 0 ? spend / conversion : 0),
          label: "CPA",
          tooltip: "Cost per listener converted",
          moneyIndicator: true,
        },
      ];
    }

    case "creativesBreakdown": {
      return [
        {
          value: impressions,
          label: "Impressions",
        },
        {
          value: Number(impressions > 0 ? (spend * 1000) / impressions : 0),
          label: "CPM",
          moneyIndicator: true,
        },
        {
          value: Number(clicks > 0 ? spend / clicks : 0),
          label: "CPC",
          moneyIndicator: true,
        },
        {
          value: conversion,
          label: "Conversion",
        },
        {
          value: Number(conversion > 0 ? spend / conversion : 0),
          label: "CPA",
          moneyIndicator: true,
        },
        {
          value: Number(clicks > 0 ? (clicks / video_play_actions) * 100 : 0),
          label: "CTR",
          relativeIndicator: true,
        },
      ];
    }
  }
};
