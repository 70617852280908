import { apiCall } from "../../modules/api-call";
import { CONFIG } from "../../config/config";

export const loginRequest = (data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: "LOGIN_REQUEST", payload: {} });
      const { data: response } = await apiCall({
        method: "post",
        url: CONFIG.BACKEND_API_URL + "public/login",
        data,
        auth: 0,
      });
      if (response.error === false) {
        dispatch({ type: "LOGIN_REQUEST_SUCCESS", payload: response });
      } else {
        dispatch({ type: "LOGIN_REQUEST_ERROR", payload: response });
      }
    } catch (err) {
      let message = err.message;
      if(err?.message === 'Network Error'){
        message = "No Internet Connection";
      }
      dispatch({ type: "LOGIN_REQUEST_ERROR", payload: { message: message} });
    }
  };
};
