import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import "../index.scss";
import { Form, Button, Space, Typography, notification } from "antd";
import FloatInput from "../../CommonWidgets/FloatInput";
import { loginRequest } from "../../../redux/actions/login";
import { updateLocalStorage } from "../../../helper";
import { CONFIG } from "../../../config/config";
import { RightArrow } from "../../CommonWidgets/FontIcons";
import useAnalytics from "../../../hooks/useAnalytics";

const { Title, Text, Link } = Typography;

const Login = (props) => {
  const dispatch = useDispatch(),
  {trackLogin, trackLoginFailed} = useAnalytics(),
    history = useHistory(),
    [form] = Form.useForm(),
    [isLoading, setLoading] = useState(false),
    [hasLoginFormValue, setLoginFormValue] = useState({
      email: "",
      userPassword: "",
    }),
    onChange = (e) => {
      let formData = { ...hasLoginFormValue };
      setLoginFormValue({ ...formData, [e.target.name]: e.target.value });
    },
    { loginData } = useSelector((state) => ({ loginData: state.loginData })),
    onLogin = (data) => {
      setLoading(true);
      data.user.email = data.user.email.toLowerCase();
      dispatch(loginRequest(data.user));
    };

  useEffect(() => {
    if (!loginData.error && Object.keys(loginData?.payload).length) {
      let profileInfo = loginData?.payload?.data.userProfile;
      profileInfo.token = loginData?.payload?.data.token;
      window.localStorage.removeItem(CONFIG.LOCALSTORAGE_USER);
      updateLocalStorage(profileInfo);
      trackLogin("email");
      setLoading(false);
  
      if (profileInfo.userType === "creator") {
        window.location.href = "/offers";
      } else {
        window.localStorage.removeItem('login');
        window.location.href = "/musician/creators";
      }
    } else {
      if (loginData?.error?.message) {
        setLoading(false);
        trackLoginFailed("email", loginData?.error?.message);
        notification.error({ message: "Error", description: loginData?.error?.message, placement: "topRight" });
      }
    }
  }, [loginData]);

  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading]);

  return (
    <>
      <Title level={2} className="text-center">
        Sign In
      </Title>
      <Form
        form={form}
        name="normal_login"
        initialValues={{
          remember: true,
        }}
        onFinish={onLogin}
      >
          <Form.Item
            name={["user", "email"]}
            rules={[
              {
                type: "email",
                message: "The input is not valid E-mail",
              },
              {
                required: true,
                message: "Please input your Email",
              },
            ]}
            hasFeedback
          >
            <FloatInput size="large" label="Email" type="text" required={true} placeholder="Enter Email" name="email" values={hasLoginFormValue.email} onChange={(e) => onChange(e)} />
          </Form.Item>
          <Form.Item
            name={["user", "userPassword"]}
            rules={[
              {
                required: true,
                message: "Please input your Password",
              },
            ]}
            hasFeedback
          >
            <FloatInput required={true} size="large" label="Password" type="password" placeholder="Enter Password" name="userPassword" values={hasLoginFormValue.userPassword} onChange={(e) => onChange(e)} />
          </Form.Item>

          <Form.Item>
            <div className="text-right"><Link underline className="ml1" onClick={()=>props?.updateNewStep(3)}>
                      Forgot password
            </Link>
            </div>
          </Form.Item>
          <Form.Item shouldUpdate>
            {() => (
              <Button size="large" type="primary" block htmlType="submit" loading={isLoading} disabled={!form.isFieldsTouched(true) || !!form.getFieldsError().filter(({ errors }) => errors.length).length}>
                <span className="f18">
                  Sign In <RightArrow />{" "}
                </span>
              </Button>
            )}
          </Form.Item>
        <Form.Item className="text-center">
          <span className="text-white">
            <Text>Don’t have an account?</Text>
            <Link underline className="ml1" onClick={()=>props?.updateNewStep(2)}>
              Sign Up
            </Link>
          </span>
        </Form.Item>
      </Form>
    </>
  );
};
export default Login;
