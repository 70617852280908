import React from "react";
import "../assets/styles/modal.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBullseye } from "@fortawesome/pro-solid-svg-icons";
import { Card, Tag, Typography } from "antd";
import "./index.scss";
import Logo from "../Logo";
const { CheckableTag } = Tag;
const { Title, Text } = Typography;

const InstantCampaignHeaderInModal = (props) => {
  const metaData = props?.metaData[0];
  console.log(metaData?.metaInfo[Object.keys(metaData?.metaInfo)[0]]);
  console.log("metaData", metaData?.metaInfo[Object.keys(metaData?.metaInfo)]);
  let  headerData =  [];

  for (let i=0; i<Object.keys(metaData?.metaInfo).length; i++) {
    headerData.push({key: i, title:  Object.keys(metaData?.metaInfo)[i], value: metaData?.metaInfo[Object.keys(metaData?.metaInfo)[i]], icon: faBullseye});
  }

  return (
    <Card>
      <div className={"row-header-container "}>
        <div className={"header-item"}>
          <Logo />
        </div>
        <div className={"header-item"}>
          <>
            <Title level={4} className="m0">
              {" "}
              {"Managed Campaigns"}
            </Title>
            <Text className="secondary-text"> We will match your song with creators that you’ll love. We’ll make sure they’ll post using your Sound and instructions.</Text>

            <div className="view-info-container">
              {headerData.map((item, index) => {
                return (
                  <div className="view-info-item" key={item?.value}>
                    <p>
                      {item.icon && <FontAwesomeIcon icon={item.icon} />} {item?.value}
                    </p>
                    <h5>{item?.title}</h5>
                  </div>
                );
              })}
            </div>
          </>
        </div>
        <div className="header-item fw-500 align-self-start">
        ${metaData.budget}
        </div>
      </div>
    </Card>
  );
};

export default InstantCampaignHeaderInModal;
