import React, { useRef, useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { useSelector, useDispatch } from "react-redux";
import { notification } from "antd";
import "../assets/styles/modal.scss";
import CreateInstantCampaign from "../CreateInstantCampaign";
import CampaignConfirmationModal from "../CampaignConfirmationModal";
import OfferPreview from "../OfferPreview";
import { createInstantCampaignRequest, createInstantCampaignReset } from "../../redux/actions/create-instant-campaign";
import ListCards from "../Card/ListCards";
import InstantCampaignHeaderInModal from "../InstantCampaignHeaderInModal";
import useAnalytics from "../../hooks/useAnalytics";

const CreateInstantCampaignWizard = forwardRef((props, ref) => {
  console.log("mta props", props);
  const offerTable = `
    .offer-table {
      height: 260px !important;
      overflow: auto;
    }
  `;
  const [current, setCurrent] = React.useState(0),
    { trackInstantBookSuccessful, trackInstantBookError, trackInstantBookConfirmationPageReached, trackInstantBookModalPaymentPageOpened } = useAnalytics(),
    dispatch = useDispatch(),
    [finalData, setFinalData] = useState([]),
    [createOfferNextButtonStatus, setCreateOfferNextButtonStatus] = useState(true),
    { createInstantCampaignData } = useSelector((state) => ({ createInstantCampaignData: state.createInstantCampaignData })),
    [loading, setLoading] = useState(false),
    updateSelectedCard = (data) => {
      const finalList = { ...finalData };
      finalList.cardDetails = { _id: "" + data[0]?.stripeCardId, cardNumber: data[0]?.cardNumber, cvv: data[0]?.cvv, expiryMonthYear: data[0]?.expiryMonthYear, nameOnCard: data[0]?.nameOnCard, cardType: data[0]?.cardType };
      finalList.cardId = data[0]?.stripeCardId;
      finalList.price = "" + Math.round(props.selectedBudget[0].budget);
      finalList.priceId = props.selectedBudget[0].id;
      finalList.instantBookMetaData = props.selectedBudget;
      console.log("finalList", finalList);
      setFinalData(finalList);
    },
    oRef = useRef(),
    movetoNext = () => {
      setCurrent(current + 1);
      props.updateCurrentBookStep(current + 1);
      let newStep = current + 1;
      switch (newStep) {
        case 1:
          trackInstantBookModalPaymentPageOpened();
          break;
        case 3:
          trackInstantBookConfirmationPageReached();
          break;
        default:
          break;
      }
    },
    next = () => {
      switch (current) {
        case 0:
          oRef.current.onAddOffer();
          break;
        case 1:
          // cRef.current.onAddCard();
          break;
        default:
          break;
      }
    },
    prev = () => {
      setCurrent(current - 1);
      props.updateCurrentBookStep(current - 1);
    },
    onCreateOffer = () => {
      //  cRef.current.onAddCard();
      if (!finalData.price) {
        notification.error({ message: "Error", description: "Please select a card", placement: "topRight" });
        return false;
      }
      setLoading(true);
      delete finalData.cardDetails;
      console.log("offer data", finalData);
      dispatch(createInstantCampaignRequest(finalData));
      //  setCurrent(current + 1);
    },
    updateFinalData = (data) => {
      setFinalData(data);
    },
    steps = [
      {
        title: "Campaign Details",
        content: <CreateInstantCampaign wOpen={true} ref={oRef} setNext={() => movetoNext()} updateData={updateFinalData} setDataToPage={finalData} isNextDisabled={(status) => props.updateCreateOfferNextStatus(status)} currentNextButtonStatus={props.currentOfferNextButtonStatus} />,
      },
      {
        title: "Payment",
        content: <ListCards setNext={() => movetoNext()} getSelectedCard={updateSelectedCard} from={"offer"} isNextDisabled={(status) => props.updateCreateCardNextStatus(status)} currentNextButtonStatus={props.currentCardNextButtonStatus} />,
      },
      {
        title: "Confirmation",
        content: <OfferPreview previewData={finalData} source="instant" creatorName={props?.selectedCreator?.tiktokInfo?.nickName} setNext={() => movetoNext()} />,
      },
      {
        title: "Complete",
        content: <CampaignConfirmationModal />,
      },
    ];

  useEffect(() => {
    setFinalData(finalData);
  }, [finalData]);

  useEffect(() => {
    setLoading(loading);
  }, [loading]);

  useEffect(() => {
    if (!createInstantCampaignData.error && Object.keys(createInstantCampaignData?.payload).length) {
      notification.success({ message: "Success", description: createInstantCampaignData?.payload?.message, placement: "topRight" });
      setLoading(false);
      trackInstantBookSuccessful();
      setCurrent(current + 1);
     
      props.updateCurrentBookStep(current + 1);
      dispatch(createInstantCampaignReset());
    } else {
      console.log("createOfferData?.error?.message", createInstantCampaignData?.error?.message);
      if (createInstantCampaignData?.error?.message) {
        setLoading(false);
        trackInstantBookError();
        notification.error({ message: "Error", description: createInstantCampaignData?.error?.message, placement: "topRight" });
      }
    }
  }, [createInstantCampaignData]);
 
  useImperativeHandle(ref, () => ({
    onPrev() {
      prev();
    },
    onNext() {
      next();
    },
    onMoveToNext() {
      movetoNext();
    },
    onBookCreateOffer() {
      onCreateOffer();
    },
    onLoading() {
      return loading;
    },
    onCurrentStep() {
      return current;
    },
    onCreateOfferNextButtonStatus() {
      return createOfferNextButtonStatus;
    },
  }));

  return (
    <>
      <style>{offerTable}</style>
      <div>
        <InstantCampaignHeaderInModal metaData={props.selectedBudget} />
        <div className="steps-content mt6 ">{steps[current].content}</div>
      </div>
    </>
  );
});

CreateInstantCampaignWizard.displayName = "CreateInstantCampaignWizard";
export default CreateInstantCampaignWizard;
