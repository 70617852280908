/* eslint-disable no-case-declarations */
import React, { useState, useEffect } from "react";
import { Card, notification } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { changeOfferStatusRequest, changeOfferStatusReset } from "../../redux/actions/change-offer-status";
import MarketerCancelOfferAction from "./MarketerCancelOfferAction";
import CreatorOfferAction from "./CreatorOfferAction";
import CreatorUploadVideoAction from "./CreatorUploadVideoAction";
import MarketerReviewVideoAction from "./MarketerReviewVideoAction";
import ViewOfferVideo from "./ViewOfferVideo";
import moment from "moment";
import { checkIfUserLoggedIn } from "../../helper";
import MarketerUpdateShippingInfo from "./MarketerUpdateShippingInfo";
import ShippingAddressTile from "../ShippingAddress/ShippingAddressTile";
import CreatorMarkShipmentReceived from "./CreatorMarkShipmentReceived";
import CreatorPostTiktokVideoUrl from "./CreatorPostTiktokVideoUrl";
import useAnalytics from "../../hooks/useAnalytics";

const OfferAction = (props) => {
  const [loaderStatus, setLoaderStatus] = useState(true),
    [shippingAddress, setShippingAddress] = useState({}),
    {trackVideoSubmitted, trackRevisionRequested,  trackCampaignCompleted} = useAnalytics(),
    [newStatus, setNewStatus] = useState(),
    dispatch = useDispatch(),
    { changeOfferStatusData } = useSelector((state) => ({ changeOfferStatusData: state.changeOfferStatusData })),
    showActionBasedStatus = () => {
      const currentStatusRow = props?.status[0],
        currentStatus = currentStatusRow.key,
        offerRow = props?.data;
        console.log(currentStatusRow);
      let actionBlock, subActionBlock;
      switch (currentStatus) {
        case "sent":
          if (currentStatusRow.whoAmI.includes("musician")) {
            actionBlock = <MarketerCancelOfferAction marketerCancelOfferAction={(status, data) => updateOfferStatus(status, data)} status={currentStatus} loaderStatus={loaderStatus} />;
          } else {
            actionBlock = <CreatorOfferAction setSelectedAddressRow={(row) => setShippingAddress(row)} needShipping={offerRow?.requireShipping} creatorOfferAction={(status, data) => updateOfferStatus(status, data)} status={currentStatus} loaderStatus={loaderStatus} />;
            // subActionBlock = <ListShippingAddress setSelectedAddressRow={row => setShippingAddress(row)} selectedShippingAddressId={(id) => setSelectedShippingAddressId(id)} isAcceptButtonEnabled={() => setDisableAcceptButton(false)} />
          }
          break;
        case "pending-shipment":
          if (checkIfUserLoggedIn().userType === "musician") {
            let mappedAddress = offerRow?.videoRevisions?.filter((r) => r.currentStatus === currentStatus);
            actionBlock = <MarketerUpdateShippingInfo marketerShippingAction={(status, data) => updateOfferStatus(status, data)} status={currentStatus} loaderStatus={loaderStatus} />;
            if (mappedAddress && mappedAddress[0]) {
              subActionBlock = <ShippingAddressTile shippingAddressItem={mappedAddress[0]?.shippingAddress} />;
            }
          }
          break;
        case "pending-receipt":
          if (currentStatusRow.whoAmI.includes("creator")) {
            actionBlock = <CreatorMarkShipmentReceived markShipmentReceivedAction={(status, data) => updateOfferStatus(status, data)} status={currentStatus} loaderStatus={loaderStatus} />;
          }
          break;
        case "video-in-progress":
          if (currentStatusRow.whoAmI.includes("creator")) {
            actionBlock = <CreatorUploadVideoAction uploadAction={(status, data) => updateOfferStatus(status, data)} deadline={offerRow?.deadline} status={currentStatus} loaderStatus={loaderStatus} />;
          }
          if (currentStatusRow?.isOverDue && checkIfUserLoggedIn().userType === "musician") {
            actionBlock = <MarketerCancelOfferAction marketerCancelOfferAction={(status, data) => updateOfferStatus(status, data)} status={currentStatus} loaderStatus={loaderStatus} />;
          }
          break;
        case "video-submitted":
          const revisionCount = offerRow?.allowedRevisions ? Math.max(0, offerRow?.allowedRevisions - offerRow?.videoRevisions?.filter((r) => r.currentStatus === "video-rejected").length) : 0;
          if (currentStatusRow.whoAmI.includes("musician")) {
            actionBlock = <MarketerReviewVideoAction needTiktokUpload={offerRow?.needTiktokUpload} videoUrl={offerRow?.videoUrl} reviewAction={(status, data) => updateOfferStatus(status, data)} status={currentStatus} loaderStatus={loaderStatus} revisionCount={revisionCount} />;
          }
          break;
        case "video-rejected":
          if (currentStatusRow.whoAmI.includes("creator")) {
            const videoRejectFeedbacks = offerRow?.videoRevisions?.filter((r) => r.currentStatus === "video-rejected");
            const latestFeedback = videoRejectFeedbacks && videoRejectFeedbacks[0]?.rejectReason ? videoRejectFeedbacks[videoRejectFeedbacks.length - 1].rejectReason : "";
            actionBlock = <CreatorUploadVideoAction uploadAction={(status, data) => updateOfferStatus(status, data)} deadline={offerRow?.deadline} status={currentStatus} loaderStatus={loaderStatus} latestFeedback={latestFeedback} />;
          }
          break;
        case "video-approved":
          if (currentStatusRow.whoAmI.includes("creator")) {
            actionBlock = <CreatorPostTiktokVideoUrl creatorPostTiktokUrl={(status, data) => updateOfferStatus(status, data)} status={currentStatus} loaderStatus={loaderStatus} />;
          }
          break;
        case "completed":
          actionBlock = <ViewOfferVideo creator={offerRow?.creatorId.userName} videoUrl={offerRow.videoUrl} status={currentStatus} loaderStatus={loaderStatus} />;
          break;
      }

      return { actionBlock, subActionBlock };
    },
    updateOfferStatus = (status, data = null) => {
      if (status === "noaction") {
        props.closeModal();
        return true;
      }
      setLoaderStatus(true);
      let offerDetail = { offer: props?.data };

      let obj = {
        id: offerDetail?.offer?._id,
        currentStatus: status,
      };
      setNewStatus(status);
      switch (status) {
        case "pending-shipment":
          obj.shippingAddress = shippingAddress;
          break;
        case "pending-receipt":
          obj.trackingId = data.trackingId;
          obj.shippingCompanyName = data.shippingCompanyName;
          break;
        case "rejected":
          obj.rejectRemarks = data.message;
          break;
        case "video-rejected":
          obj.rejectReason = data.message;
          obj.videoUrl = offerDetail?.offer?.videoUrl;
          obj.deadline = moment().utc().add(offerDetail?.offer?.videoTimeExtension, "days").toDate();
          break;
        case "video-submitted":
          obj.videoUrl = data.videoUrl;
          obj.deadline = moment().utc().add(offerDetail?.offer?.videoTimeExtension, "days").toDate();
          break;
        case "completed":
          console.log(data);
          obj.chargeId = offerDetail?.offer.chargeId;
          obj.price = offerDetail?.offer.finalPrice;
          obj.connectId = offerDetail?.offer?.connectId;
          obj.creatorId = offerDetail?.offer?.creatorId?._id;
          obj.videoUrl = offerDetail?.offer?.videoUrl;
          if (data?.tiktokUrl) {
            obj.needTiktokUpload = true;
            obj.tiktokUrl = data.tiktokUrl;
          } 
          break;
        default:
      }
      dispatch(changeOfferStatusRequest(obj));
    };

  useEffect(() => {
    if (!changeOfferStatusData.error && Object.keys(changeOfferStatusData?.payload).length) {
      switch (newStatus) {
        case "video-submitted":
          trackVideoSubmitted();
        break;
        case "video-rejected":
        trackRevisionRequested();
        break;
        case "completed":
        trackCampaignCompleted();
        break;
        default:
          break;
      }
      setNewStatus("");
      props.updateRow(changeOfferStatusData?.payload?.data);
      props?.onTabSwitch("1");
      props?.updateOfferData(changeOfferStatusData?.payload?.data);
      //  props.closeModal();
      setLoaderStatus(false);
      dispatch(changeOfferStatusReset());
      notification.success({ message: "Success", description: changeOfferStatusData?.payload.message, placement: "topRight" });
    } else {
      if (changeOfferStatusData?.error?.message) {
        setLoaderStatus(false);
        notification.error({ message: "Error", description: changeOfferStatusData?.error?.message, placement: "topRight" });
      }
    }
  }, [changeOfferStatusData]);

  const displayBasedOnAction = showActionBasedStatus();

  return (
    <>
      {displayBasedOnAction?.subActionBlock && (
        <Card className="offer-action-container text-center" bordered={false}>
          {displayBasedOnAction?.subActionBlock}
        </Card>
      )}
      {displayBasedOnAction?.actionBlock && <div className="offer-action-container text-center mt3 action-border">{displayBasedOnAction?.actionBlock}</div>}
    </>
  );
};

export default OfferAction;