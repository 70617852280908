import { apiCall } from "../../modules/api-call";
import { CONFIG } from "../../config/config";

export const createBoostRequest = (data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: "CREATE_BOOST_REQUEST", payload: {} });
      const { data: response } = await apiCall({
        method: "POST",
        url: CONFIG.BACKEND_API_URL + "user/product/boost",
        auth: 1,
        data,
      });
      console.log("CREATE BOOST Request response => ", response);
      if (response && response.statusCode === 201 && response.error === false) {
        dispatch({ type: "CREATE_BOOST_REQUEST_SUCCESS", payload: response });
      } else {
        dispatch({ type: "CREATE_BOOST_REQUEST_ERROR", payload: response });
      }
    } catch (err) {
      console.log("CREATE_BOOST_REQUEST_ERROR error => ", err);
      dispatch({ type: "CREATE_BOOST_REQUEST_ERROR", payload: { message: "Something went wrong" } });
    }
  };
};

export const createBoostReset = () => {
  return async (dispatch) => {
    dispatch({ type: "CREATE_BOOST_REQUEST_RESET" });
  };
};
