import { createStore, applyMiddleware } from "redux";
import reducers from "../reducers";
import thunk from "redux-thunk";
import { createLogger } from "redux-logger";
import { CONFIG } from "../../config/config";
let middleware;
if (CONFIG.ENVIRONMENT === 'development') {
    middleware = applyMiddleware(thunk, createLogger());
} else {
    middleware = applyMiddleware(thunk);
}

export default createStore(reducers, middleware);
