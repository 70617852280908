const boostCitiesList = [
  { name: "Abilene-Sweetwater, TX" },
  { name: "Albany, GA" },
  { name: "Albany-Schenectady-Troy, NY" },
  { name: "Albuquerque-Santa Fe, NM" },
  { name: "Alexandria, LA" },
  { name: "Alpena, MI" },
  { name: "Amarillo, TX" },
  { name: "Anchorage, AL" },
  { name: "Atlanta, GA" },
  { name: "Augusta-Aiken, GA" },
  { name: "Austin, TX" },
  { name: "Bakersfield, CA" },
  { name: "Baltimore, MD" },
  { name: "Bangor, ME" },
  { name: "Baton Rouge, LA" },
  { name: "Beaumont-Port Arthur, TX" },
  { name: "Bend, OR" },
  { name: "Billings, MT" },
  { name: "Biloxi-Gulfport, LA" },
  { name: "Binghamton, NY" },
  { name: "Birmingham (Anniston-Tuscaloosa), AL" },
  { name: "Bluefield-Beckley-Oak Hill, WV" },
  { name: "Boise, ID" },
  { name: "Boston, MA" },
  { name: "Bowling Green, KY" },
  { name: "Buffalo, NY" },
  { name: "Burlington-Plattsburgh, VT" },
  { name: "Butte-Bozeman, MT" },
  { name: "Casper-Riverton, WY" },
  { name: "Cedar Rapids-Waterloo-Iowa City-Dubuque, IA" },
  { name: "Champaign-Springfield-Decatur, IL" },
  { name: "Charleston-Huntington, WV" },
  { name: "Charleston, SC" },
  { name: "Charlotte, NC" },
  { name: "Charlottesville, VA" },
  { name: "Chattanooga, TN" },
  { name: "Cheyenne-Scottsbluff, WY" },
  { name: "Chicago, IL" },
  { name: "Chico-Redding, CA" },
  { name: "Cincinnati, OH" },
  { name: "Clarksburg-Weston, WV" },
  { name: "Cleveland-Akron (Canton), OH" },
  { name: "Colorado Springs-Pueblo, CO" },
  { name: "Columbia-Jefferson City, MO" },
  { name: "Columbia, SC" },
  { name: "Columbus, GA (Opelika, AL)" },
  { name: "Columbus, OH" },
  { name: "Columbus-Tupelo-West Point-Houston, MS" },
  { name: "Corpus Christi, TX" },
  { name: "Dallas-Fort Worth, TX" },
  { name: "Davenport-Rock Island-Moline, IA-IL" },
  { name: "Dayton, OH" },
  { name: "Denver, CO" },
  { name: "Des Moines-Ames, IA" },
  { name: "Detroit, MI" },
  { name: "Dothan, AL" },
  { name: "Duluth-Superior, MN-WI" },
  { name: "Elmira (Corning), NY" },
  { name: "El Paso (Las Cruces), TX" },
  { name: "Erie, PA" },
  { name: "Eugene, OR" },
  { name: "Eureka, CA" },
  { name: "Evansville, IN" },
  { name: "Fairbanks, AK" },
  { name: "Fargo-Moorhead-Grand Forks, ND" },
  { name: "Flint-Saginaw-Bay City, MI" },
  { name: "Fort Myers-Naples, FL" },
  { name: "Fort Smith-Fayetteville-Springdale-Rogers, AR" },
  { name: "Fort Wayne, IN" },
  { name: "Fresno-Visalia, CA" },
  { name: "Gainesville, FL" },
  { name: "Glendive, MT" },
  { name: "Grand Junction-Montrose, CO" },
  { name: "Grand Rapids-Kalamazoo-Battle Creek, MI" },
  { name: "Great Falls, MT" },
  { name: "Green Bay-Appleton, WI" },
  { name: "Greensboro-High Point-Winston-Salem, NC" },
  { name: "Greenville-New Bern-Washington, NC" },
  { name: "Greenville-Spartanburg-Asheville-Anderson, TN-NC" },
  { name: "Greenwood-Greenville, MS" },
  { name: "Harlingen-Weslaco-Brownsville-McAllen, TX" },
  { name: "Harrisburg-Lancaster-Lebanon-York, PA" },
  { name: "Harrisonburg, VA" },
  { name: "Hartford-New Haven, CT" },
  { name: "Hattiesburg-Laurel, MS" },
  { name: "Helena, MT" },
  { name: "Honolulu, HI" },
  { name: "Houston, TX" },
  { name: "Huntsville-Decatur (Florence), AL" },
  { name: "Idaho Falls-Pocatello (Jackson), ID" },
  { name: "Indianapolis, IN" },
  { name: "Jackson, MS" },
  { name: "Jackson, TN" },
  { name: "Jacksonville, FL" },
  { name: "Johnstown-Altoona-State College, PA" },
  { name: "Jonesboro, AR" },
  { name: "Joplin-Pittsburg, MO-KS" },
  { name: "Juneau, AK" },
  { name: "Kansas City, MO-KS" },
  { name: "Knoxville, TN" },
  { name: "La Crosse-Eau Claire, WI" },
  { name: "Lafayette, IN" },
  { name: "Lafayette, LA" },
  { name: "Lake Charles, LA" },
  { name: "Lansing, MI" },
  { name: "Laredo, TX" },
  { name: "Las Vegas, NV" },
  { name: "Lexington, KY" },
  { name: "Lima, OH" },
  { name: "Lincoln-Hastings-Kearney, NE" },
  { name: "Little Rock-Pine Bluff, AR" },
  { name: "Los Angeles, CA" },
  { name: "Louisville, KY" },
  { name: "Lubbock, TX" },
  { name: "Macon, GA" },
  { name: "Madison, WI" },
  { name: "Mankato, MN" },
  { name: "Marquette, MI" },
  { name: "Medford-Klamath Falls, OR" },
  { name: "Memphis, TN" },
  { name: "Meridian, MS" },
  { name: "Miami-Fort Lauderdale, FL" },
  { name: "Milwaukee, WI" },
  { name: "Minneapolis-St. Paul, MN" },
  { name: "Minot-Bismarck-Dickinson (Williston), ND" },
  { name: "Missoula, MT" },
  { name: "Mobile-Pensacola (Navarre), AL-FL" },
  { name: "Monroe-El Dorado, LA-AR" },
  { name: "Monterey-Salinas, CA" },
  { name: "Montgomery-Selma, AL" },
  { name: "Myrtle Beach-Florence, SC" },
  { name: "Nashville, TN" },
  { name: "New Orleans, LA" },
  { name: "New York, NY" },
  { name: "Norfolk-Portsmouth-Newport News, VA-NC" },
  { name: "North Platte, NE" },
  { name: "Odessa-Midland, TX" },
  { name: "Oklahoma City, OK" },
  { name: "Omaha, NE" },
  { name: "Orlando-Daytona Beach-Melbourne, FL" },
  { name: "Ottumwa-Kirksville, IA-MO" },
  { name: "Paducah-Cape Girardeau-Harrisburg, KY-IL-MO" },
  { name: "Palm Springs, CA" },
  { name: "Panama City, FL" },
  { name: "Parkersburg, WV" },
  { name: "Peoria-Bloomington, IL" },
  { name: "Philadelphia, PA" },
  { name: "Phoenix (Prescott), AZ" },
  { name: "Pittsburgh, PA" },
  { name: "Portland-Auburn, ME" },
  { name: "Portland, OR" },
  { name: "Presque Isle, ME" },
  { name: "Providence-New Bedford, RI-MA" },
  { name: "Quincy-Hannibal-Keokuk, IL-MO-IA" },
  { name: "Raleigh-Durham (Fayetteville), NC" },
  { name: "Rapid City, SD" },
  { name: "Reno, NV" },
  { name: "Richmond-Petersburg, VA" },
  { name: "Roanoke-Lynchburg, VA" },
  { name: "Rochester, NY" },
  { name: "Rochester-Mason City-Austin, MN-IA" },
  { name: "Rockford, IL" },
  { name: "Sacramento-Stockton-Modesto, CA" },
  { name: "Salisbury, MD" },
  { name: "Salt Lake City, UT" },
  { name: "San Angelo, TX" },
  { name: "San Antonio, TX" },
  { name: "San Diego, CA" },
  { name: "San Francisco-Oakland-San Jose, CA" },
  { name: "Santa Barbara-Santa Maria-San Luis Obispo, CA" },
  { name: "Savannah, GA" },
  { name: "Seattle-Tacoma, WA" },
  { name: "Sherman-Ada, TX-OK" },
  { name: "Shreveport, LA" },
  { name: "Sioux City, IA" },
  { name: "Sioux Falls (Mitchell), SD" },
  { name: "South Bend-Elkhart, IN" },
  { name: "Spokane, WA" },
  { name: "Springfield-Holyoke, MA" },
  { name: "Springfield, MO" },
  { name: "St. Joseph, MO" },
  { name: "St. Louis, MO" },
  { name: "Syracuse, NY" },
  { name: "Tallahassee-Thomasville, FL-GA" },
  { name: "Tampa-St. Petersburg (Sarasota), FL" },
  { name: "Terre Haute, IN" },
  { name: "Toledo, OH" },
  { name: "Topeka, KS" },
  { name: "Traverse City-Cadillac, MI" },
  { name: "Tri-Cities, TN-VA" },
  { name: "Tucson (Sierra Vista), AZ" },
  { name: "Tulsa, OK" },
  { name: "Twin Falls, ID" },
  { name: "Tyler-Longview (Lufkin-Nacogdoches), TX" },
  { name: "Utica, NY" },
  { name: "Victoria, TX" },
  { name: "Waco-Temple-Bryan, TX" },
  { name: "Washington (Hagerstown), DC-MD" },
  { name: "Watertown, NY" },
  { name: "Wausau-Rhinelander, WI" },
  { name: "West Palm Beach-Fort Pierce, FL" },
  { name: "Wheeling-Steubenville, WV-OH" },
  { name: "Wichita Falls & Lawton, TX-OK" },
  { name: "Wichita-Hutchinson, KS" },
  { name: "Wilkes-Barre-Scranton-Hazleton, PA" },
  { name: "Wilmington, NC" },
  { name: "Yakima-Pasco-Richland-Kennewick, WA" },
  { name: "Youngstown, OH" },
  { name: "Yuma-El Centro, AZ" },
  { name: "Zanesville, OH" },
];

export default boostCitiesList;
