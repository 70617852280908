import React, { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Modal, Form, Space, Row, Upload, Button, notification } from "antd";
import { getSignedURLRequest } from "../../redux/actions/get-signed-url";
import { fileUploadRequest } from "../../redux/actions/fileUpload";
import { uploadToAwsRequest } from "../../redux/actions/upload-to-aws";
import { deleteImageRequest } from "../../redux/actions/delete-image";
import { checkIfUserLoggedIn, updateLocalStorage, trimSpace } from "../../helper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faUpload, faPlusCircle, faSpinner } from "@fortawesome/pro-regular-svg-icons";

const UploadProfilePhoto = forwardRef((props, ref) => {
  const [key, setKey] = useState(""),
    fileUserName = trimSpace(checkIfUserLoggedIn().userName),
    [loading, setLoading] = useState(false),
    [isDelLoading, setIsDelLoading] = useState(false),
    [uploadFile, setFile] = useState(null),
    [removeImage, setRemoveImage] = useState(false),
    [defaultFileList, setDefaultFileList] = useState([]),
    [imageUrl, setImageUrl] = useState(checkIfUserLoggedIn().profileImage ? checkIfUserLoggedIn().profileImage : ""),
    [previewImage, setPreviewImage] = useState(null),
    [previewVisible, setPreviewVisible] = useState(null),
    [previewTitle, setPreviewTitle] = useState(null),
    dispatch = useDispatch(),
    { fileUploadData, signedUrlData, uploadAwsData, deleteImageData } = useSelector((state) => ({ fileUploadData: state.fileUploadData, signedUrlData: state.signedUrlData, uploadAwsData: state.uploadAwsData, deleteImageData: state.deleteImageData })),
    uploadButton = (
      <div>
        {loading ? <FontAwesomeIcon icon={faSpinner} /> : <FontAwesomeIcon icon={faPlusCircle} />}
        <div className="mt2">
          Add Photo
          <br />
          <span>(Optional)</span>
        </div>
      </div>
    ),
    imgButton = (
      <div>
        <div className="mt2"> {checkIfUserLoggedIn().profileImage && <img className="img-cover image-rounded" src={checkIfUserLoggedIn().profileImage} alt="pic" width="70" height="70" />}</div>
      </div>
    ),
    deleteImage = () => {
      setIsDelLoading(true);
      dispatch(deleteImageRequest({ profileImage: "" }));
    },
    handleOnChange = ({ file, fileList, event }) => {
      setDefaultFileList(fileList);
    },
    handleSubmit = (event) => {
      if (uploadFile) {
        setLoading(true);
        if (signedUrlData) {
          if (uploadAwsData) {
            setImageUrl(signedUrlData.payload?.data?.signedUrl);
            dispatch(uploadToAwsRequest(signedUrlData.payload?.data?.fullSignedUrl, uploadFile));
          } else {
            setLoading(false);
          }
        }
      } else {
        setLoading(false);
      }
    },
    removeFile = () => {
      setRemoveImage(true);
      setKey("");
    },
    // Gets base64 of an image
    getBase64 = (file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    // Handles image previews
    handlePreview = async (file) => {
      if (!file.url && !file.preview) file.preview = await getBase64(file?.originFileObj);
      setPreviewImage(file?.url || file?.preview);
      setPreviewVisible(true);
      setPreviewTitle(file?.name || file?.url.substring(file?.url.lastIndexOf("/") + 1));
    },
    // Resets image previews
    handleCancel = () => setPreviewVisible(false);

  useEffect(() => {
    setImageUrl(imageUrl);
  }, [imageUrl]);

  useEffect(() => {
    if (!deleteImageData.error && Object.keys(deleteImageData?.payload).length) {
      setIsDelLoading(false);
      setKey("");
      updateLocalStorage(deleteImageData?.payload?.data);
    } else {
      if (deleteImageData?.error?.message) {
        setIsDelLoading(false);
        notification.error({ message: "Error", description: deleteImageData?.error?.message, placement: "topRight" });
      }
    }
  }, [deleteImageData]);

  useEffect(() => {
    if (!fileUploadData.error && Object.keys(fileUploadData?.payload).length) {
      notification.success({ message: "Success", description: fileUploadData?.payload?.message, placement: "topRight" });
      updateLocalStorage(fileUploadData?.payload?.data);
      setLoading(false);
      setImageUrl(fileUploadData?.payload?.data?.profileImage);
      setKey("");
    } else {
      if (fileUploadData?.error?.message) {
        setLoading(false);
      }
    }
  }, [fileUploadData]);

  useEffect(() => {
    if (!signedUrlData.error && Object.keys(signedUrlData?.payload).length) {
      setLoading(false);
    } else {
      setLoading(false);
      if (signedUrlData?.error?.message) {
        //
      }
    }
  }, [signedUrlData]);

  useEffect(() => {
    if (uploadAwsData.error === null) {
      if (signedUrlData && signedUrlData.payload?.data) {
        let data = {};
        data.profileImage = signedUrlData?.payload?.data?.signedUrl;
        setDefaultFileList(null);
        const timer = setTimeout(() => {
          dispatch(fileUploadRequest(data));
        }, 5000);
        return () => clearTimeout(timer);
      }
    } else {
      if (uploadAwsData?.error?.message) {
        setLoading(false);
      }
    }
  }, [uploadAwsData]);

  useImperativeHandle(ref, () => ({
    uploadPic() {
      handleSubmit();
    },
  }));

  return (
    <>
      <Row type="flex" justify="center" align="middle">
        <Space direction="vertical" className="main-innercenter-box-blue  text-center width-fix">
          <Form
            name="upload_image_form"
            className="login-form uploadPic"
            initialValues={{
              remember: true,
            }}
            onSubmit={handleSubmit}
          >
            <Form.Item
              name={["user", "avatar"]}
              rules={[
                {
                  required: true,
                  message: "Please upload profile image",
                },
              ]}
              hasFeedback
            >
              {checkIfUserLoggedIn()?.profileImage?.length > 0 ? (
                imgButton
              ) : (
                <Upload
                  name="avatar"
                  listType="picture-card"
                  defaultFileList={defaultFileList}
                  onChange={handleOnChange}
                  className="avatar-uploader"
                  onPreview={handlePreview}
                  maxCount={1}
                  onRemove={() => removeFile()}
                  customRequest={({ file, onError, onSuccess, onProgress }) => {
                    if (file.name) {
                      const fileType = file.type;
                      const fileName = file.name;
                      setFile(file);
                      const key = `${fileUserName}_${Math.random()}.${fileName}`;
                      setKey(key);
                      setLoading(true);
                      let data = {
                        fileName: key,
                        userName: fileUserName,
                      };
                      if (file && file.name.length >= 0) {
                        onSuccess(setLoading(false));
                        dispatch(getSignedURLRequest(data));
                      }
                    }
                  }}
                >
                  {uploadButton}
                </Upload>
              )}
              <div className="upload-btns">
                {checkIfUserLoggedIn() && checkIfUserLoggedIn().profileImage && (
                  <Button
                    type="primary"
                    className="mt6"
                    loading={isDelLoading}
                    onClick={(e) => {
                      e.preventDefault();
                      deleteImage();
                    }}
                  >
                    <FontAwesomeIcon icon={faTrash} /> Delete
                  </Button>
                )}

                {key && (
                  <Button className="ml5" type="primary" onClick={() => handleSubmit()} loading={loading}>
                    <FontAwesomeIcon icon={faUpload} /> Upload
                  </Button>
                )}
              </div>
            </Form.Item>
          </Form>
        </Space>
      </Row>

      {/* Displays image preview */}
      <Modal open={previewVisible} title={previewTitle} className={"image-preview-modal"} footer={null} onCancel={handleCancel} maskClosable={true}>
        <img alt="pic" className="w-full" src={previewImage} />
      </Modal>
    </>
  );
});
UploadProfilePhoto.displayName = "UploadProfilePhoto";
export default UploadProfilePhoto;
