import { Grid } from "antd";
const { useBreakpoint } = Grid;

const ScreenResolution = () => {
  const screens = useBreakpoint();

  let findRes = Object.entries(screens)
      .filter((screen) => !!screen[1])
      .map((screen) => screen[0]);
   
  return findRes;
};

export default ScreenResolution;
