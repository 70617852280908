import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight, faSpinner, faCircleQuestion } from "@fortawesome/pro-regular-svg-icons";

export const RightArrow = ()=> {
    return <FontAwesomeIcon icon={faArrowRight} className="ml1" />;
}

export const LeftArrow = ()=> {
    return <FontAwesomeIcon icon={faArrowLeft} />;
}

export const Spinner = ()=> {
    return <FontAwesomeIcon icon={faSpinner} />;
}

export const QuestionCircle = ()=> {
    return <FontAwesomeIcon icon={faCircleQuestion} />;
}