import React, { useState, useEffect } from "react";
import { Slider, Button, Typography, notification } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./index.scss";
import { faBullseye, faCircleDollar } from "@fortawesome/pro-solid-svg-icons";

import "./index.scss";
const { Title, Text } = Typography;

const BudgetInfo = (props) => {
  const marks = props.marks,
    finaMarker = () => {
      for (var k in marks) {
        if (marks[k] === `$${props.defaultPrice}`) {
          return [parseInt(k), marks[k]];
        }
      }
    },
    budgetInfoDetail = props.budgetInfo;
  const [priceChange, setPriceChange] = useState(
      props?.defaultPrice ? props?.defaultPrice : budgetInfoDetail?.priceList[0]?.amount
    ),
    [priceId, setPriceId] = useState(
      budgetInfoDetail?.priceList?.filter((row) => row.amount === props?.defaultPrice)[0]?.priceId
    ),
    [rowKey, setRowKey] = useState(0),
    getMetaInfoFromPrice = (index) => {
      let rowIndex =
        budgetInfoDetail?.priceList &&
        budgetInfoDetail?.priceList.findIndex(
          (row) => parseInt(row.amount) === parseInt(priceChange)
        );
      if (rowIndex !== -1) {
        const boostProductMetaData = budgetInfoDetail?.metaData;
        if (boostProductMetaData) {
          let values = budgetInfoDetail?.metaData[Object.keys(budgetInfoDetail?.metaData)[index]];

          if (values) {
            try {
              values = JSON.parse(values) ? JSON.parse(values)[rowIndex] : "";

              return { key: Object.keys(budgetInfoDetail?.metaData)[index], value: values };
            } catch (e) {
              console.log(e); // error in the above string (in this case, yes)!
              console.log("values", Object.keys(budgetInfoDetail?.metaData)[index], values);
              //  notification.error("Error", "Please check the metadata inputs");
              return { key: "", value: "" };
            }
          } else {
            return { key: "", value: "" };
          }
        } else {
          return { key: "", value: "" };
        }
      } else {
        return { priceId: null, metaInfo: [] };
      }
    },
    formatMetaData = () => {
      let finalData = [],
        finalObj = {};
      for (let i = 0; i < Object.keys(budgetInfoDetail?.metaData).length; i++) {
        finalData.push(getMetaInfoFromPrice(i));
      }
      if (finalData[0]) {
        finalData.map((item) => {
          finalObj[item.key] = item.value;
        });
        if (finalObj?.url) delete finalObj?.url;
      }
      return finalObj;
    },
    onPriceChange = (step) => {
      const filterMarks = marks[step],
        trimmedPrice = filterMarks && filterMarks.replace("$", "");
      const rowIndex = budgetInfoDetail.priceList.findIndex(
        (row) => parseInt(row.amount) === parseInt(trimmedPrice)
      );
      if (filterMarks && rowIndex !== -1) {
        console.log(
          "budgetInfoDetail.metaData",
          rowIndex,
          getMetaInfoFromPrice(0),
          budgetInfoDetail.priceList[rowIndex]?.priceId
        );
        setPriceChange(trimmedPrice);
        setPriceId(budgetInfoDetail.priceList[rowIndex]?.priceId);
        setRowKey(rowIndex);
      }
    };
  let priceMeta = [];
  priceMeta.push({
    title: "Budget",
    value: "$" + priceChange,
    icon: <FontAwesomeIcon icon={faCircleDollar} />,
  });
  for (let i = 0; i < Object.keys(budgetInfoDetail?.metaData).length; i++) {
    if (Object.keys(budgetInfoDetail?.metaData)[i] !== "url") {
      priceMeta.push({
        title: getMetaInfoFromPrice(i)?.key,
        value: getMetaInfoFromPrice(i)?.value,
        icon: <FontAwesomeIcon icon={faBullseye} />,
      });
    }
  }

  console.log("defaultMarker 123", finaMarker());
  console.log("marks", marks);
  let defaultMarkers = finaMarker();

  /* priceMeta = [
  {
    title: "Budget",
    value: "$" + priceChange,
    icon: <FontAwesomeIcon icon={faCircleDollar} />,
  },
  {
    title: getMetaInfoFromPrice(0)?.key,
    value: getMetaInfoFromPrice(0)?.value,
    icon: <FontAwesomeIcon icon={faBullseye} />,
  },
  {
    title: getMetaInfoFromPrice(1)?.key,
    value: getMetaInfoFromPrice(1)?.value,
    icon: <FontAwesomeIcon icon={faBullseye} />,
  }
]; */

  useEffect(() => {
    setPriceChange(props.defaultPrice);
  }, [priceChange]);

  useEffect(() => {
    getMetaInfoFromPrice();
    setPriceChange(priceChange);
    setPriceId(priceId);
  }, [priceChange, priceId]);

  return (
    marks && (
      <>
        <div className="range-slider">
          <Slider
            marks={marks}
            step={marks}
            tooltip={{ open: false }}
            onChange={onPriceChange}
            defaultValue={defaultMarkers}
          />
        </div>
        <div className="budget-info-container">
          {priceMeta
            .filter(
              (filterItem) =>
                filterItem?.title.toLowerCase() !== "order" &&
                filterItem?.title.toLowerCase() !== "url"
            )
            .map((item, i) => {
              return (
                <div className="budget-info-item" key={i}>
                  <Title level={i === 0 ? 1 : 3} className="m0">
                    {item?.value}
                  </Title>
                  <Text level={4}>
                    {" "}
                    {item?.icon} {item?.title}
                  </Text>
                </div>
              );
            })}
          <div className="budget-info-item justify-self-end btn-budget">
            <Button
              type="primary"
              disabled={props?.isDisabled}
              loading={props?.isLoading}
              htmlType="submit"
              size={"large"}
              className="btn-boost"
              onClick={() => {
                props.loadingUpdate(true);
                props.onSubmitData(formatMetaData(), { price: priceChange, priceId: priceId });
              }}
            >
              Set up campaign
            </Button>
          </div>
        </div>
      </>
    )
  );
};
export default BudgetInfo;
