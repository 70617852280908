import React from "react";
import { ConfigProvider, theme } from "antd";
import Colors from "./Colors";

function ThemeProvider({ children }) {
  const { primaryColor, darkText, lightText, deepPurple, mutedText } = Colors;
  return (
    <ConfigProvider
      theme={{
        hashed: false,
        token: {
          colorPrimary: primaryColor,
          colorBgBase: "#F7F8F9",
          borderRadius: 8,
          fontFamily: "Open Sans Variable, sans-serif",
        },
        components: {
          Menu: {},
          Typography: {
            colorLink: primaryColor,
            colorLinkHover: mutedText,
            colorText: "#000830",
            colorTextHeading: "#000830",
          },
          Tabs: {
            colorText: "#000830",
          },
          Button: {
            primaryShadow: "none",
          },
        },
      }}
    >
      {children}
    </ConfigProvider>
  );
}
export default ThemeProvider;

// --primary-color: #6B49D6 ;
//   --secondary-color:  #F5D06D;
//   --secondary-color-hovered: ;
//   --accent-color: #151515; /* #000000 ; */
//   --light-accent-color: #FFFFFF; /* #B3B3B3;*/
//   --border-modal-header-footer:#ffffff1a;
//   --opaque-black:  #15151590;
//   --opaque-white:  #FFFFFF90;
//   --black: #000000;
//   --off-background: #1d1d1d;
//   --dull-accent: #B3B3B3;
//   --border-radius: 8px;
//   --off-white: #B3B3B3;
//   --blue: #1767dd;
//   --gray: #cccccc;
//   --dim-white: rgba(var(--blue), 0.6);
//   --dim: 0.6;
//   --find-creator-modal-height: 70vh;
//   --offer-helper-title-color: #f5f5f530;
//   --delivery-container-color: #7363af;
//   --transition: .2s ease-in-out;
//   --box-shadow: 0px 8px 8px 4px rgba(var(--accent-color), var(--dim));
//   --card-box-shadow: 0px 8px 8px -3px rgba(var(--black), .3);
//   --error-color: #ff4d4f;
