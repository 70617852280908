import React, { useState, useEffect, forwardRef } from "react";
import { Space, Form, Button, Row, Col, Select } from "antd";
import FloatInput from "../../CommonWidgets/FloatInput";
import DeliverableContainer from "../../CommonWidgets/DeliverableContainer";
import "../../assets/styles/modal.scss";
import { isFormInValid } from "../../../helper";
const { Option } = Select;

const CreatorPostTiktokVideoUrl = forwardRef((props, ref) => {
  const [form] = Form.useForm(),
    newStatus = "completed",
    [clicked, setClicked] = useState(""),
    [formError, setFormError] = useState(false),
    [tiktokUrl, setTiktokUrl] = useState(""),
    clickedAction = () => {
      setClicked(newStatus);
      onManageUpdateTiktokVideoUrl();
    },
    onManageUpdateTiktokVideoUrl = () => {
      form
        .validateFields()
        .then((values) => {
          if (!isFormInValid(form)) {
            //   props.loadingUpdate(true);

            props.creatorPostTiktokUrl(newStatus, {tiktokUrl: tiktokUrl});
          }
        })
        .catch((info) => {
          setFormError(true);
        });
    };

  return (
    <DeliverableContainer
      contentType="Tiktok Video Url"
      content={
        <div className="mt3">
          <Form
            name="updateTiktokUrl"
            form={form}
            initialValues={{
              remember: true,
            }}
          >
            <Space direction="vertical" className="w-full">
              <Form.Item
                name={["tiktokInfo", "tiktokUrl"]}
                initialValue={tiktokUrl}
                rules={[
                  {
                    required: true,
                    message: "Please enter the tiktok Url",
                  },
                ]}
                hasFeedback
              >
                <FloatInput size="large" label="Tiktok Url" placeholder="https://tiktok.com/video" type="text" name="tiktokUrl" values={tiktokUrl} onChange={(e) => setTiktokUrl(e.target.value)} />
              </Form.Item>

              <Form.Item>
                <div className="offer-action">
                  <Button type="primary" loading={clicked === newStatus && !formError ? props?.loaderStatus : false} onClick={() => clickedAction(newStatus)}>
                    {" "}
                    Submit
                  </Button>
                </div>
              </Form.Item>
            </Space>
          </Form>
        </div>
      }
    />
  );
});

CreatorPostTiktokVideoUrl.displayName = "CreatorPostTiktokVideoUrl";
export default CreatorPostTiktokVideoUrl;
