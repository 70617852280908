import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Table, Button, Input, Typography, Card, Spin } from "antd";
import SoundModal from "../SoundModal";
import { getSoundsRequest } from "../../../redux/actions/list-sound";
import { deleteSoundRequest, deleteSoundReset } from "../../../redux/actions/delete-sound";
import { manageSoundRequest, manageSoundReset } from "../../../redux/actions/manage-sound";
import CheckWeb from "../../CommonWidgets/CheckWeb";
import AntSpinner from "../../CommonWidgets/AntSpinner";
import { formatDateTime } from "../../../helper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMusic } from "@fortawesome/pro-solid-svg-icons";
import SoundFilterModal from "../SoundFilterModal";
import NoDataFound from "../../Result/NoDataFound";
import { Event } from "../../../tracking";
// @ts-ignore

const { Search } = Input,
  { Title } = Typography;

const ListSounds = () => {
  const web = CheckWeb(),
    dispatch = useDispatch(),
    [isLoading, setIsLoading] = useState(false),
    [isDeleteLoading, setIsDeleteLoading] = useState(false),
    [showSoundModal, setShowSoundModal] = useState(false),
    [masterSoundData, setMasterSoundData] = useState([]),
    [currentRecord, setCurrentRecord] = useState({}),
    [soundData, setSoundData] = useState([]),
    { listSoundData, deleteSoundData, addOrEditSoundData } = useSelector((state) => ({ listSoundData: state.listSoundData, deleteSoundData: state.deleteSoundData, addOrEditSoundData: state.addOrEditSoundData })),
    deleteRow = (record) => {
      setIsDeleteLoading(true);
      dispatch(deleteSoundRequest(record));
    },
    updateSoundData = (data) => {
      setSoundData(data);
    },
    filterSoundData = (searchFields) => {
      if (searchFields) {
        let filteredData = masterSoundData;
        if (searchFields?.soundTitle) {
          filteredData = filteredData && filteredData.filter((items) => items.soundTitle.toLowerCase().includes(searchFields?.soundTitle?.toLowerCase()));
        }
        if (searchFields?.genre) {
          filteredData = filteredData && filteredData.filter((items) => items.genre && items.genre.toLowerCase().includes(searchFields?.genre.toLowerCase()));
        }
        console.log("searchFields", searchFields);
        setSoundData(filteredData);
      } else {
        setSoundData(masterSoundData);
      }
    },
    setColumns = (type) => {
      return [
        {
          title: "Sound",
          dataIndex: "soundTitle",
          key: "soundTitle",
        },
        {
          title: "Genre",
          dataIndex: "genre",
          key: "genre",
        },
        {
          title: "Created On",
          dataIndex: "createdAt",
          key: "createdAt",
          render: (date) => formatDateTime(date),
        },
        {
          title: "Link",
          dataIndex: "soundLink",
          key: "soundLink",
        },
      ];
    };

  useEffect(() => {
    setIsLoading(true);
    dispatch(getSoundsRequest());
  }, []);

  useEffect(() => {
    console.log("sound List detail state changed => ", deleteSoundData);
    if (!deleteSoundData.error && Object.keys(deleteSoundData?.payload).length) {
      setSoundData(deleteSoundData?.payload?.data);
      setMasterSoundData(deleteSoundData?.payload?.data);
      Event("delete_sound_success", deleteSoundData?.payload?.message, "SOUND_MODAL");
      setCurrentRecord({});
      setIsDeleteLoading(false);
      setShowSoundModal(false);
      dispatch(deleteSoundReset());
    } else {
      console.log("sdfdsf", deleteSoundData?.error?.message);
      if (deleteSoundData?.error?.message) {
        setIsLoading(false);
        Event("delete_sound_failure", deleteSoundData?.error?.message, "SOUND_MODAL");
        // notification.error({ message: "Error", description: deleteSoundData?.error?.message, placement: "topRight" });
      }
    }
  }, [deleteSoundData]);

  useEffect(() => {
    console.log("sound zzz detail state changed => ", listSoundData);
    if (!listSoundData.error && Object.keys(listSoundData?.payload).length) {
      setSoundData(listSoundData?.payload?.data);
      setMasterSoundData(listSoundData?.payload?.data);
      setIsLoading(false);
    } else {
      console.log("sdfdsf", listSoundData?.error?.message);
      if (listSoundData?.error?.message) {
        setIsLoading(false);
        // notification.error({ message: "Error", description: listSoundData?.error?.message, placement: "topRight" });
      }
    }
  }, [listSoundData]);

  useEffect(() => {
    console.log("boost detail state changed => ", addOrEditSoundData);
    if (!addOrEditSoundData.error && Object.keys(addOrEditSoundData?.payload).length) {
      setIsLoading(false);
      updateSoundData(addOrEditSoundData?.payload?.data);
      dispatch(manageSoundReset());
    } else {
      console.log("sdfdsf", addOrEditSoundData?.error?.message);
      if (addOrEditSoundData?.error?.message) {
        // notification.error({ message: "Error", description: addOrEditSoundData?.error?.message, placement: "topRight" });
        setIsLoading(false);
      }
    }
  }, [addOrEditSoundData]);

  return (
    <Spin indicator={AntSpinner} spinning={isLoading} size={"large"}>
      <Card>
        <div className="flex flex-row justify-content-between align-items-center">
          <div>
            <Title level={1} className={"mt2"}>
              <span>
                <FontAwesomeIcon icon={faMusic} /> Sound Library
              </span>
            </Title>
          </div>
          <div>
            <Button type="primary" onClick={() => setShowSoundModal(true)}>
              Add Sound
            </Button>
          </div>
        </div>

        <SoundFilterModal isSearchEnabled={!isLoading} filterSoundData={filterSoundData} />
      </Card>

      {soundData && soundData.length > 0 ? (
        <Table
          columns={setColumns("active")}
          dataSource={soundData.filter((items) => items.isArchived === false)}
          className={"sound-list mt5"}
          rowKey="_id"
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => {
                setShowSoundModal(true);
                setCurrentRecord(record);
              }, // click row
            };
          }}
        />
      ) : (
        <NoDataFound showContent={isLoading} />
      )}

      {showSoundModal === true && (
        <SoundModal
          open={showSoundModal}
          source="sound"
          close={() => {
            setCurrentRecord({});
            setShowSoundModal(false);
          }}
          updateSoundData={updateSoundData}
          selectedRecord={currentRecord}
          removeSound={deleteRow}
          isDeleteLoading={isDeleteLoading}
        />
      )}
    </Spin>
  );
};

export default ListSounds;
