import { apiCall } from "../../modules/api-call";
import { CONFIG } from "../../config/config";

export const deleteSoundRequest = (id) => {
  return async (dispatch) => {
    try {
      console.log("dta", id);
      dispatch({ type: "DELETE_SOUND_REQUEST", payload: id });
      const { data: response } = await apiCall({
        method: "delete",
        url: CONFIG.BACKEND_API_URL + "user/sound/" + id,
        auth: 1,
      });
      console.log("DELETE_SOUND response => ", response);
      if (response && response.statusCode === 200 && response.error === false) {
        dispatch({ type: "DELETE_SOUND_REQUEST_SUCCESS", payload: response });
      } else {
        dispatch({ type: "DELETE_SOUND_REQUEST_ERROR", payload: response });
      }
    } catch (err) {
      console.log("EDITProfileRequest error => ", err);
      dispatch({ type: "DELETE_SOUND_REQUEST_ERROR", payload: { message: "Something went wrong" } });
    }
  };
};

export const deleteSoundReset = () => {
  return async (dispatch) => {
    dispatch({ type: "DELETE_SOUND_REQUEST_RESET" });
  };
};
