const boostCountryList = [
  { "name": "Argentina", "code": "AR" },
  { "name": "Australia", "code": "AU" },
  { "name": "Austria", "code": "AT" },
  { "name": "Bahrain", "code": "BH" },
  { "name": "Belarus", "code": "BY" },
  { "name": "Belgium", "code": "BE" },
  { "name": "Brazil", "code": "BR" },
  { "name": "Cambodia", "code": "KH" },
  { "name": "Canada", "code": "CA" },
  { "name": "Chile", "code": "CL" },
  { "name": "Colombia", "code": "CO" },
  { "name": "Czechia", "code": "CZ" },
  { "name": "Denmark", "code": "DK" },
  { "name": "Egypt", "code": "EG" },
  { "name": "Finland", "code": "FI" },
  { "name": "France", "code": "FR" },
  { "name": "Germany", "code": "DE" },
  { "name": "Greece", "code": "GR" },
  { "name": "Hungary", "code": "HU" },
  { "name": "Indonesia", "code": "ID" },
  { "name": "Ireland", "code": "IE" },
  { "name": "Israel", "code": "IL" },
  { "name": "Italy", "code": "IT" },
  { "name": "Japan", "code": "JP" },
  { "name": "Kazakhstan", "code": "KZ" },
  { "name": "Korea", "code": "KR" },
  { "name": "Kuwait", "code": "KW" },
  { "name": "Malaysia", "code": "MY" },
  { "name": "Mexico", "code": "MX" },
  { "name": "Morocco", "code": "MA" },
  { "name": "Netherlands", "code": "NL" },
  { "name": "New Zealand", "code": "NZ" },
  { "name": "Norway", "code": "NO" },
  { "name": "Oman", "code": "OM" },
  { "name": "Pakistan", "code": "PK" },
  { "name": "Peru", "code": "PE" },
  { "name": "Philippines", "code": "PH" },
  { "name": "Poland", "code": "PL" },
  { "name": "Portugal", "code": "PT" },
  { "name": "Qatar", "code": "QA" },
  { "name": "Romania", "code": "RO" },
  { "name": "Russia", "code": "RU" },
  { "name": "Saudi Arabia", "code": "SA" },
  { "name": "Singapore", "code": "SG" },
  { "name": "South Africa", "code": "ZA" },
  { "name": "Spain", "code": "ES" },
  { "name": "Sweden", "code": "SE" },
  { "name": "Switzerland", "code": "CH" },
  { "name": "Taiwan", "code": "TW" },
  { "name": "Thailand", "code": "TH" },
  { "name": "Turkey", "code": "TR" },
  { "name": "Ukraine", "code": "UA" },
  { "name": "United Arab Emirates", "code": "AE" },
  { "name": "United Kingdom", "code": "GB" },
  { "name": "United States", "code": "US" },
  { "name": "Vietnam", "code": "VN" }
];

export default boostCountryList;