import React from "react";
import { Radio} from "antd";
import CardTile from "../CardTile";
import "./index.scss";

const CardFormatter = (props) => {
    const cardData = props?.cardData,
        onChange = (e) => {
            let selectedValue = e?.target && e.target.value ? e.target.value : e;
            props.setValue(selectedValue);
            if (props.from) {
                const selectedCardDetail = cardData && cardData.filter(row => row._id === selectedValue);
                props.updateChosenCard(selectedCardDetail);
            }
        };
    return (
        <Radio.Group onChange={onChange} value={props.value} className="card-formatter">
                {cardData && cardData.map((item, index) => {
                   return (<CardTile key={index} onChange={onChange} cardItem={item} from={"offer"}  selected={props.value === item?._id} />);
                })}
        </Radio.Group>
    );
};

export default CardFormatter;