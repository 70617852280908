import React, { useState } from "react";
import styles from "./index.module.scss";
import BoostStatisticModal from "../../BoostStatisticModal";
import Demographic from "./Demographic";
import CreativesInfo from "./CreativesInfo";
import Targeting from "./Targeting";
import Creatives from "./Creatives";

const DetailedInfo = ({ boostId, startDate, endDate, boostInfo, setBoostInfo }) => {
  const [openedModalType, setOpenedModalType] = useState(null);

  const handleClickModalButton = (type) => {
    setOpenedModalType(type);
  };

  return (
    <div className={styles.root}>
      <div className={styles.buttons}>
        <button
          onClick={() => handleClickModalButton("demographic")}
          className={styles.modalButton}
        >
          Demographic Breakdowns
        </button>
        <button onClick={() => handleClickModalButton("targetings")} className={styles.modalButton}>
          Edit Targeting
        </button>
        <button onClick={() => handleClickModalButton("creative")} className={styles.modalButton}>
          Creative Breakdown
        </button>
        <button
          onClick={() => handleClickModalButton("editCreative")}
          className={styles.modalButton}
        >
          Add / Change Creatives
        </button>
      </div>
      {openedModalType === "demographic" && (
        <BoostStatisticModal
          isOpen={openedModalType === "demographic"}
          type={openedModalType}
          handleClose={() => {
            setOpenedModalType(null);
          }}
          title="Demographic Breakdowns"
        >
          <Demographic
            boostId={boostId}
            startDate={startDate}
            endDate={endDate}
            isOpen={openedModalType === "demographic"}
          />
        </BoostStatisticModal>
      )}
      {openedModalType === "targetings" && (
        <BoostStatisticModal
          isOpen={openedModalType === "targetings"}
          type={openedModalType}
          handleClose={() => {
            setOpenedModalType(null);
          }}
          title="Edit your targeting"
        >
          <Targeting boostInfo={boostInfo} setBoostInfo={setBoostInfo} />
        </BoostStatisticModal>
      )}
      {openedModalType === "creative" && (
        <BoostStatisticModal
          isOpen={openedModalType === "creative"}
          type={openedModalType}
          handleClose={() => {
            setOpenedModalType(null);
          }}
          title="In Depth Metrics"
        >
          <CreativesInfo
            boostId={boostId}
            startDate={startDate}
            endDate={endDate}
            isOpen={openedModalType === "creative"}
          />
        </BoostStatisticModal>
      )}
      {openedModalType === "editCreative" && (
        <BoostStatisticModal
          isOpen={openedModalType === "editCreative"}
          type={openedModalType}
          handleClose={() => {
            setOpenedModalType(null);
          }}
          title="Add / Change Creatives"
        >
          <Creatives
            isOpen={openedModalType === "editCreative"}
            boostId={boostId}
            fullPackage={boostInfo.fullPackage}
          />
        </BoostStatisticModal>
      )}
    </div>
  );
};

export default DetailedInfo;
