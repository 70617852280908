const searchFromRange = [
    { label: "1,000", value: 1000 },
    { label: "3,000", value: 3000 },
    { label: "5,000", value: 5000 },
    { label: "10,000", value: 10000 },
    { label: "15,000", value: 15000 },
    { label: "25,000", value: 25000 },
    { label: "35,000", value: 35000 },
    { label: "50,000", value: 50000 },
    { label: "75,000", value: 75000 },
    { label: "100,000", value: 100000 },
    { label: "175,000", value: 175000 },
    { label: "250,000", value: 250000 },
    { label: "350,000", value: 350000 },
    { label: "500,000", value: 500000 },
    { label: "1,000,000", value: 1000000 },
    { label: "2,000,000", value: 2000000 },
    { label: "3,000,000", value: 3000000 }
];
const searchToRange = [
    { label: "3,000", value: 3000 },
    { label: "5,000", value: 5000 },
    { label: "10,000", value: 10000 },
    { label: "15,000", value: 15000 },
    { label: "25,000", value: 25000 },
    { label: "35,000", value: 35000 },
    { label: "50,000", value: 50000 },
    { label: "75,000", value: 75000 },
    { label: "100,000", value: 100000 },
    { label: "175,000", value: 175000 },
    { label: "250,000", value: 250000 },
    { label: "350,000", value: 350000 },
    { label: "500,000", value: 500000 },
    { label: "1,000,000", value: 1000000 },
    { label: "2,000,000", value: 2000000 },
    { label: "3,000,000", value: 3000000 },
    { label: "3,000,000+", value: 999999999999999 }
];
function getCostFrom() {
    let costFrom = [];
    for (let i=0; i<2000; i+=100) {
        costFrom.push({label: i.toLocaleString(), value: i});
    }
    return costFrom;
}



function getCostTo() {
  let costFrom = [];
  for (let i=100; i<=2000; i+=100) {
      costFrom.push({label: i.toLocaleString(), value: i});
  }
  console.log("cost sdf", costFrom);
  return costFrom;
}

const searchFromCostRange = getCostFrom();
const searchToCostRange = getCostTo();


const engagementRates = [
    {"label": ">1 %", value:1},
    {"label": ">2 %", value:2},
    {"label": ">3 %", value:3},
    {"label": ">4 %", value:4},
    {"label": ">5 %", value:5},
    {"label": ">6 %", value:6},
    {"label": ">7 %", value:7},
    {"label": ">8 %", value:8},
    {"label": ">9 %", value:9},
    {"label": ">10 %", value:10},
]

export  { searchFromRange, searchToRange, searchFromCostRange, searchToCostRange, engagementRates };