import { apiCall } from "../../modules/api-call";
import { CONFIG } from "../../config/config";

export const getShippingAddressesRequest = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: "LIST_SHIPPING_ADDRESSES_REQUEST", payload: {} });
      const { data: response } = await apiCall({
        method: "get",
        url: CONFIG.BACKEND_API_URL + "creator/shipping/address",
        auth: 1,
      });
      if (response && response.statusCode === 200 && response.error === false) {
        dispatch({ type: "LIST_SHIPPING_ADDRESSES_REQUEST_SUCCESS", payload: response });
      } else {
        dispatch({ type: "LIST_SHIPPING_ADDRESSES_REQUEST_ERROR", payload: response });
      }
    } catch (err) {
      dispatch({ type: "LIST_SHIPPING_ADDRESSES_REQUEST_ERROR", payload: {message: err?.response?.message ? err?.response?.message :err.message} });
    }
  };
};

export const getShippingAddressReset = () => {
  return async (dispatch) => {
    dispatch({ type: "LIST_SHIPPING_ADDRESSES_REQUEST_RESET" });
  };
};
