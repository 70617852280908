import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Card, Space, Input, Typography, List, Row, Col, Button, notification } from "antd";
import Lottie from "lottie-react";
import { PlayCircleOutlined, ProfileOutlined, ShareAltOutlined, EyeOutlined } from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartSimple } from "@fortawesome/pro-solid-svg-icons";
import animationData from "../../components/assets/animations/loader.json";
import { analyzeSoundRequest, analyzeSoundReset } from "../../redux/actions/analyze";
import CheckWeb from "../../components/CommonWidgets/CheckWeb";
import { numberFormatter } from "../../helper";
import "./index.scss";
//import "./assets/styles/search.scss";
// @ts-ignore

const { Search } = Input,
  { Title } = Typography;

const Analyze = () => {
  const web = CheckWeb(),
    dispatch = useDispatch(),
    { analyzeData } = useSelector((state) => ({ analyzeData: state.analyzeData })),
    [isLoading, setIsLoading] = useState(false),
    [showAnalyzeData, setAnalyzeData] = useState({}),
    [showData, setShowData] = useState(false),
    [searchValue, setSearchValue] = useState(""),
    indexZeroStyle = "analyzePink",
    onSearch = (value) => {
      setSearchValue(value);
      setIsLoading(true);
      dispatch(analyzeSoundRequest({ soundLink: value }));
    },
    checkData = () => {
      setIsLoading(true);
      dispatch(analyzeSoundRequest({ soundLink: searchValue }));
    },
    defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    },
    fillData = () => {
      const overViewData = [
          {
            key: "1",
            title: "# of videos made with your sound",
            value: numberFormatter(showAnalyzeData?.soundVideoCount),
          },
          {
            key: "2",
            title: "Total views of videos with your sound",
            value: numberFormatter(showAnalyzeData?.views),
          },
          {
            key: "3",
            title: "Total comments of videos with your sound",
            value: numberFormatter(showAnalyzeData?.comments),
          },
          {
            key: "4",
            title: "Rate of engagement of videos with your sound",
            value: numberFormatter(showAnalyzeData?.engagementRateStr) + "%",
          },
        ],
        campaignData = [
          {
            key: "1",
            title: "$ spent promoting this sound",
            value: "NA",
          },
          {
            key: "2",
            title: "Paid video views",
            value: "NA",
          },
          {
            key: "3",
            title: "Paid video view cost (per 1000)",
            value: "NA",
          },
          {
            key: "4",
            title: "Total CPM for campaign",
            value: "NA",
          },
        ];
      return { overViewData: overViewData, campaignData: campaignData };
    };

  useEffect(() => {
    console.log("analyse detail state changed => ", analyzeData);
    if (!analyzeData.error && Object.keys(analyzeData?.payload).length) {
      setIsLoading(false);
      setAnalyzeData(analyzeData?.payload?.data);
      setShowData(true);
      dispatch(analyzeSoundReset());
    } else {
      setShowData(false);
      if (analyzeData.error) {
        setIsLoading(false);
        notification.error({ message: "Error", description: analyzeData?.error?.message, placement: "topRight" });
      }
    }
  }, [analyzeData]);

  useEffect(() => {
    setShowData(showData);
  }, [showData]);

  useEffect(() => {
    setSearchValue(searchValue);
  }, [searchValue]);

  return (
    <>
      <Title level={1}>
        <span><FontAwesomeIcon icon={faChartSimple} /> Analyze your TikTok Sound</span>
      </Title>
      <Row className="mb-25">
        <Col xl={{ span: 22 }} lg={{ span: 16 }} xs={{ span: 24 }}>
          <Search size="large" placeholder="Search by Music Feed Url" onSearch={onSearch} onChange={(e) => setSearchValue(e.target.value)} enterButton className="search" />
        </Col>
        <Col xl={{ span: 2 }} lg={{ span: 8 }} xs={{ span: 24 }} className="text-left" style={!web ? { marginTop: "15px" } : { marginTop: "0" }}>
          <Button style={{ marginLeft: "20px" }} type="primary" className="search-btn" loading={isLoading} disabled={searchValue.length <= 0} onClick={() => checkData()}>
            Analyze
          </Button>
        </Col>
      </Row>
      {isLoading && <Lottie loop={true} width={400} height={400} animationData={animationData} autoplay={true} rendererSettings='xMidYMid slice' />}

      {showData && (
        <>
          <Title level={3} className="mt-25">
            Overview
          </Title>
          <List
            grid={{
              gutter: 16,
              xs: 1,
              sm: 2,
              md: 3,
              lg: 3,
              xl: 4,
              xxl: 4,
            }}
            dataSource={fillData().overViewData}
            className="analyse-card-wrap"
            renderItem={(item) => (
              <List.Item key={item.key} style={!web ? { width: "100%" } : { width: "75%" }}>
                <Card className="analyse-card" bordered={false}>
                  <Space direction="vertical" style={{ width: "100%", justifyContent: "space-between" }}>
                    <Title level={4}>{item?.value}</Title>
                    <Card.Meta className="mb-5" description={item?.title} />
                  </Space>
                </Card>
              </List.Item>
            )}
          />
          <Title level={3} className="mt-25">
            PushTok Campaigns
          </Title>
          <List
            grid={{
              gutter: 16,
              xs: 1,
              sm: 2,
              md: 3,
              lg: 3,
              xl: 4,
              xxl: 4,
            }}
            dataSource={fillData().campaignData}
            renderItem={(item) => (
              <List.Item key={item.key} style={!web ? { width: "100%" } : { width: "75%" }}>
                <Card bordered={false} className="analyse-card">
                  {/* <Space
                direction="vertical"
                style={{ width: '100%', justifyContent: 'space-between' }}
              > */}
                  <Title level={4}>{item?.value}</Title>
                  <Card.Meta className="mb-5" description={item?.title} />
                  {/* </Space> */}
                </Card>
              </List.Item>
            )}
          />
          <Row>
            <Col xl={{ span: 6 }} lg={{ span: 24 }} xs={{ span: 24 }}>
              <Title level={3} className="mt-25">
                <EyeOutlined />
              </Title>
              <Title level={3} className="mt-25">
                Top 5 viewed Hashtags
              </Title>
              <List
                grid={{
                  gutter: 16,
                  xs: 1,
                  sm: 1,
                  md: 1,
                  lg: 1,
                  xl: 1,
                  xxl: 1,
                }}
                dataSource={showAnalyzeData?.topFiveViewedHashTags}
                renderItem={(item, i) => (
                  <List.Item key={item.tag} style={!web ? { width: "100%" } : { width: "75%" }}>
                    <Title level={5} className={i === 0 ? indexZeroStyle : ""} style={{ lineHeight: "1" }}>
                      {item?.tag}
                    </Title>
                  </List.Item>
                )}
              />
            </Col>
            <Col xl={{ span: 6 }} lg={{ span: 24 }} xs={{ span: 24 }}>
              <Title level={3} className="mt-25">
                <PlayCircleOutlined className="white-text" />
              </Title>
              <Title level={3} className="mt-25">
                Top 5 used Hashtags
              </Title>
              <List
                grid={{
                  gutter: 16,
                  xs: 1,
                  sm: 1,
                  md: 1,
                  lg: 1,
                  xl: 1,
                  xxl: 1,
                }}
                dataSource={showAnalyzeData?.topFiveUsedHashTags}
                renderItem={(item, i) => (
                  <List.Item key={item.tag}>
                    <Title level={5} className={i === 0 ? indexZeroStyle : ""} style={{ lineHeight: "1" }}>
                      {item?.tag}
                    </Title>
                  </List.Item>
                )}
              />
            </Col>
            <Col xl={{ span: 6 }} lg={{ span: 24 }} xs={{ span: 24 }}>
              <Title level={3} className="mt-25 analyzeIcon">
                <ProfileOutlined />
              </Title>
              <Title level={3} className="mt-25">
                Most viewed TikToks by
              </Title>
              <List
                grid={{
                  gutter: 16,
                  xs: 1,
                  sm: 1,
                  md: 1,
                  lg: 1,
                  xl: 1,
                  xxl: 1,
                }}
                dataSource={showAnalyzeData?.mostViewedTikToksByCreators}
                renderItem={(item, i) => (
                  <List.Item key={item.unique_id}>
                    <Title level={5} className={i === 0 ? indexZeroStyle : ""} style={{ lineHeight: "1" }}>
                      {item?.unique_id}
                    </Title>
                  </List.Item>
                )}
              />
            </Col>
            <Col xl={{ span: 6 }} lg={{ span: 24 }} xs={{ span: 24 }}>
              <Title level={3} className="mt-25">
                <ShareAltOutlined />
              </Title>
              <Title level={3} className="mt-25">
                Most shared TikToks by
              </Title>
              <List
                grid={{
                  gutter: 16,
                  xs: 1,
                  sm: 1,
                  md: 1,
                  lg: 1,
                  xl: 1,
                  xxl: 1,
                }}
                dataSource={showAnalyzeData?.mostSharedTikToksByCreators}
                renderItem={(item, i) => (
                  <List.Item key={item.unique_id}>
                    <Title level={5} className={i === 0 ? indexZeroStyle : ""} style={{ lineHeight: "1" }}>
                      {item?.unique_id}
                    </Title>
                  </List.Item>
                )}
              />
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default Analyze;