import React from "react";
import { Typography, Radio, List, Card } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCreditCard } from "@fortawesome/pro-solid-svg-icons";
import { faCcVisa, faCcMastercard } from "@fortawesome/free-brands-svg-icons";
import "../../assets/styles/modal.scss";
import "./index.scss";
import Colors from "../../../theme/Colors";
const { Title, Text } = Typography;

const CardTile = (props) => {
  const item = props?.cardItem;
  const cardType = item?.cardType.toLowerCase();
  let cardIcon = "";
  switch (cardType) {
    case "visa":
      cardIcon = faCcVisa;
      break;
    case "mastercard":
      cardIcon = faCcMastercard;
      break;
    default:
      cardIcon = faCreditCard;
      break;
  }

  const getCardDetail = (row) => {
    return (
      <>
        <div className="text-right">
          <Text>Exp {row?.expiryMonthYear}</Text>
          <br />
          <span className="card-dot">
            {"●●●● ●●●● ●●●● "}
            <Text>{row?.cardNumber}</Text>
          </span>
        </div>
      </>
    );
  };
  return (
    <Card
      className="card-list-item"
      style={{
        border: props?.selected ? `1px solid ${Colors.primaryColor}` : undefined,
      }}
      key={item?._id}
    >
      <List.Item
        onClick={() => props?.onChange(item?._id)}
        style={
          props?.preventSelection
            ? {
                cursor: "unset",
              }
            : undefined
        }
      >
        <List.Item.Meta
          avatar={props?.from === "offer" && <Radio value={item?._id}></Radio>}
          title={
            <div className="flex align-items-center">
              {<FontAwesomeIcon icon={cardIcon} />}
              <Title level={5}>{item?.nameOnCard}</Title>
            </div>
          }
          className="flex"
        />
        {getCardDetail(item)}
      </List.Item>
    </Card>
  );
};

export default CardTile;
