import { apiCall } from "../../modules/api-call";
import { CONFIG } from "../../config/config";

export const getBoostProductsRequest = (obj) => {
  return async (dispatch) => {
    try {
      dispatch({ type: "LIST_PRODUCTS_BOOST_REQUEST", payload: {} });
      const { data: response } = await apiCall({
        method: "get",
        url: `${CONFIG.BACKEND_API_URL}user/products`,
        auth: 1,
      });
      console.log("get boost api response => ", response, typeof response);
      if (response && response.statusCode === 200 && response.error === false) {
        dispatch({ type: "LIST_PRODUCTS_BOOST_REQUEST_SUCCESS", payload: response });
      } else {
        dispatch({ type: "LIST_PRODUCTS_BOOST_REQUEST_ERROR", payload: response });
      }
    } catch (err) {
      console.log("error in boost REQUEST => ", err.response);
      dispatch({ type: "LIST_PRODUCTS_BOOST_REQUEST_ERROR", payload: { message: "Something went wrong" } });
    }
  };
};

export const getBoostProductsReset = () => {
  return async (dispatch) => {
    dispatch({ type: "LIST_PRODUCTS_BOOST_REQUEST_RESET" });
  };
};
