import React, { useState, useEffect } from "react";
import { Card, Typography, Spin } from "antd";
import { checkIfUserLoggedIn } from "../../helper";
import AntSpinner from "../CommonWidgets/AntSpinner";
import { Animated } from "react-animated-css";
import "./index.scss";
import NumberSuffix from "number-suffix";
import ImageRenderer from "../CommonWidgets/ImageRenderer";
const { Title, Text, Link } = Typography;

const CreatorDashboard = (props) => {
  const [getCreatorData, setCreatorDashboardData] = useState(props?.dashboardData);
  useEffect(() => {
    setCreatorDashboardData(props?.dashboardData);
  }, [props]);

  return (
    <Animated animationIn="fadeIn" isVisible={true}>
      <Title className="dashboard-title">
        <ImageRenderer imageUrl={checkIfUserLoggedIn()?.profileImage} isAvatar={true} className="mb2" />
        {`${checkIfUserLoggedIn().tiktokHandle}`}
      </Title>
      <Text>View your current offers, manage your campaigns, or access your payments!</Text>
      <div className="dash-card-wrap">
        <Card className="dash-offer-card center" bordered={false}>
          <div className={"dashboard-box-amount"}>
            <Title>{getCreatorData && getCreatorData?.breadsMade?.amount ? (getCreatorData?.breadsMade?.amount > 1000 ? "$" + NumberSuffix.format(getCreatorData?.breadsMade?.amount, { precision: 1 }) : "$" + Math.round(getCreatorData?.breadsMade?.amount)) : "$" + 0}</Title>
          </div>
          <div className={"dashboard-box-bread"}>
            <Text>Bread Made</Text>
          </div>
          <div>
            {getCreatorData?.dashboardLink && getCreatorData?.dashboardLink.length > 0 ? (
              <>
                <div>
                  <Link underline rel="noreferrer" target={"_blank"} href={getCreatorData?.dashboardLink}>
                    View Payouts
                  </Link>
                </div>
              </>
            ) : (
              <>
                <div className={"text-white disabled"}>View Payouts</div>
              </>
            )}
          </div>
        </Card>
        <Card className="dash-offer-card center" bordered={false}>
          <div className={"dashboard-box-amount"}>
            <Title>{getCreatorData && getCreatorData?.totalInProgressVideosMade ? getCreatorData?.totalInProgressVideosMade : 0}</Title>
          </div>
          <div className={"dashboard-box-bread"}>
            <Text>In Progress Offers</Text>
          </div>
        </Card>
        <Card className="dash-offer-card center" bordered={false}>
          <div className={"dashboard-box-amount"}>
            <Title>{getCreatorData && getCreatorData?.totalVideosMade ? getCreatorData?.totalVideosMade : 0}</Title>
          </div>
          <div className={"dashboard-box-bread"}>
            <Text>Videos Made</Text>
          </div>
        </Card>
      </div>
    </Animated>
  );
};

export default CreatorDashboard;
