import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";
import { Menu, Dropdown, notification, Typography } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ModalHeader from "../ModalHeader/index";
import {
  faBars,
  faUserCircle,
  faLock,
  faMagnifyingGlassChart,
  faHandshakeAngle,
  faMusic,
  faUser,
  faCircleDollar,
  faRightFromBracket,
  faRocketLaunch,
  faChartSimple,
} from "@fortawesome/pro-solid-svg-icons";
import { callLogout, checkIfUserLoggedIn, updateLocalStorage } from "../../helper";
import Profile from "../../pages/Profile";
import ModalContainer from "../ModalContainer/index";
import MarketerCards from "../../pages/MarketerCards";
import FindCreators from "../../pages/FindCreators/index";
import ListOffers from "../../pages/ListOffers";
import Logo from "../Logo";
import CheckWeb from "../CommonWidgets/CheckWeb";
import "./index.scss";
import ForgotPassword from "../Auth/ForgotPassword";
import CreatorPaymentModal from "../CreatorPaymentModal";
import Boost from "../../pages/Boost";
import SetPassword from "../../pages/SetPassword";
import SubNav from "../SubNav";
import Analyze from "../../pages/analyze";
import ListSounds from "../../components/Sound/ListSounds";
import AuthModal from "../AuthModal";

import {
  changeVerifyStatusRequest,
  changeVerifyStatusReset,
} from "../../redux/actions/check-verify-status";
const { Text } = Typography;
const NavMenu = (props) => {
  const mRef = React.createRef(),
    isLogin = window.localStorage.getItem("login"),
    [showAuthModal, setShowAuthModal] = useState(isLogin ? isLogin === "true" : false),
    dispatch = useDispatch(),
    { verifyData } = useSelector((state) => ({ verifyData: state.verifyData }));
  let currentRoute = window.location.href
    .split("/")
    .splice(3, window.location.href.split("/").length)
    .join("/")
    .toString();
  const [current, setCurrent] = useState(currentRoute || "musician/creators");
  const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);
  const [isMarketerPaymentsModalOpen, setIsMarketerPaymentsModalOpen] = useState(false);
  // Listen for changes to the url
  useEffect(() => {
    if (current !== currentRoute) setCurrent(currentRoute);
  }, [current, currentRoute]);
  console.log({ showAuthModal });

  const [showPaymentModal, setShowPaymentModal] = useState(false),
    // [isLoading, setLoading] = useState(false),
    [showSubMenu, setShowSubMenu] = useState(true),
    [isVisible, setVisible] = useState(false),
    takeAction = () => {
      const localData = checkIfUserLoggedIn();
      if (localData?.accountStatus) {
        switch (localData?.accountStatus) {
          case "new":
            setShowPaymentModal(true);
            break;
          case "incomplete":
            window.location.href = localData?.accountUrl;
            break;
          case "activated":
            notification.success({
              message: "Success",
              description: "Congratulations, Your account is verified",
              placement: "topRight",
            });
            setShowSubMenu(false);
            break;
          case "rejected":
            notification.error({
              message: "Error",
              description:
                "Your Stripe account has been disabled, please reach out to support for more information",
              placement: "topRight",
            });
            break;
          case "pending":
            notification.error({
              message: "",
              description: "Your account is in review mode. Please check after sometime",
              placement: "topRight",
            });
            break;
        }
      }
    };
  let [menuItems, setMenuItems] = useState([]),
    [leftMenuItems, setLeftMenuItems] = useState([
      {
        key: "logo",
        value: (
          <>
            <Logo />
            <Link to="/musician/creators" />
          </>
        ),
      },
      {
        key: "musician/creators",
        value: (
          <>
            <span>
              <FontAwesomeIcon icon={faMagnifyingGlassChart} /> Find Creators
            </span>
            <Link to="/musician/creators" />
          </>
        ),
      },
      {
        key: "boost",
        value: (
          <>
            <span>
              <FontAwesomeIcon icon={faRocketLaunch} /> Boost
            </span>
            <Link to="/boost" />
          </>
        ),
      },
      {
        /*  {
        key: "analyze",
        value: (
          <>
           <Link to="/analyze">
                <FontAwesomeIcon icon={faChartSimple} />
                <Text>Analyze</Text>
              </Link>
          </>
        ),
      }, */
      },
    ]);
  let [rightMenuItems, setRightMenuItems] = useState([
    {
      key: "login",
      value: (
        <Link
          className="no-underline"
          to="#"
          onClick={() => {
            setShowAuthModal(true);
          }}
        >
          <FontAwesomeIcon icon={faLock} />
          <Text> Sign In</Text>
        </Link>
      ),
    },
  ]);

  useEffect(() => {
    const { type } = props;

    if (type === "musician") {
      setMenuItems([
        {
          key: "musician/profile",
          value: (
            <>
              <Link
                to="#"
                onClick={() => {
                  setVisible(false);
                  setIsProfileModalOpen(true);
                }}
              >
                <FontAwesomeIcon icon={faUser} />
                <Text>My Account</Text>
              </Link>
            </>
          ),
        },
        {
          key: "musician/card",
          value: (
            <>
              <Link
                to="#"
                onClick={() => {
                  setVisible(false);
                  setIsMarketerPaymentsModalOpen(true);
                }}
              >
                <FontAwesomeIcon icon={faCircleDollar} />
                <Text>Payment</Text>
              </Link>
            </>
          ),
        },
        {
          key: "logout",
          value: (
            <>
              <Link to="/" onClick={() => callLogout()}>
                <FontAwesomeIcon icon={faRightFromBracket} />
                <Text>Sign out</Text>
              </Link>
            </>
          ),
        },
      ]);

      setLeftMenuItems([
        {
          key: "mlogo",
          value: (
            <>
              <Logo />
              <Link to="/musician/creators" />
            </>
          ),
        },
        {
          key: "musician/creators",
          value: (
            <>
              <span>
                <FontAwesomeIcon icon={faMagnifyingGlassChart} /> Find Creators
              </span>
              <Link to="/musician/creators" />
            </>
          ),
        },
        {
          key: "boost",
          value: (
            <>
              <span>
                <FontAwesomeIcon icon={faRocketLaunch} /> Boost
              </span>
              <Link to="/boost" />
            </>
          ),
        },
      ]);

      setRightMenuItems([
        /* {
          key: "musician/creators",
          value: (
            <>
              <span>
                <FontAwesomeIcon icon={faMagnifyingGlassChart} /> Find Creators
              </span>
              <Link to="/musician/creators" />
            </>
          ),
        }, */
        {
          key: "offers",
          value: (
            <>
              <span>
                <FontAwesomeIcon icon={faHandshakeAngle} /> Campaigns
              </span>
              <Link to="/offers" />
            </>
          ),
        },
        {
          key: "musician/sounds",
          value: (
            <>
              <span>
                <FontAwesomeIcon icon={faMusic} /> Sounds
              </span>
              <Link to="/musician/sounds" />
            </>
          ),
        },
      ]);
    }

    if (type === "creator") {
      setMenuItems([
        {
          key: "creator/profile",
          value: (
            <>
              <span>
                <FontAwesomeIcon icon={faUser} /> My Account
              </span>
              <Link
                to="#"
                onClick={() => {
                  setVisible(false);
                  setIsProfileModalOpen(true);
                }}
              />
            </>
          ),
        },
        !checkIfUserLoggedIn()?.stripeConnectId && {
          key: "creator/card",
          value: (
            <>
              <span>
                <FontAwesomeIcon icon={faCircleDollar} /> Payment
              </span>
              <Link to="#" onClick={() => takeAction()} />
            </>
          ),
        },
        {
          key: "logout",
          value: (
            <>
              <Link to="/" onClick={() => callLogout()}>
                <FontAwesomeIcon icon={faRightFromBracket} />
                <Text>Sign out</Text>
              </Link>
            </>
          ),
        },
      ]);

      setLeftMenuItems([
        {
          key: "clogo",
          value: (
            <>
              <Logo />
              <Link to="/offers" />
            </>
          ),
        },
        {
          key: "boost",
          value: (
            <>
              <span>
                <FontAwesomeIcon icon={faRocketLaunch} /> Boost
              </span>
              <Link to="/boost" />
            </>
          ),
        },
      ]);

      setRightMenuItems([
        {
          key: "offers",
          value: (
            <>
              <span>
                <FontAwesomeIcon icon={faHandshakeAngle} /> Campaigns
              </span>
              <Link to="/offers" />
            </>
          ),
        },
      ]);
    }
  }, [props.type]);

  useEffect(() => {
    setShowSubMenu(showSubMenu);
  }, [showSubMenu]);

  useEffect(() => {
    if (checkIfUserLoggedIn() && checkIfUserLoggedIn().userType === "creator")
      dispatch(changeVerifyStatusRequest());
  }, []);

  useEffect(() => {
    if (!verifyData.error && Object.keys(verifyData?.payload).length) {
      let verifyInfo = verifyData?.payload?.data,
        localStorageData = checkIfUserLoggedIn(),
        localData = { ...localStorageData };
      localData.accountStatus = verifyInfo?.accountStatus;
      localData.accountUrl = verifyInfo?.accountUrl;
      localData.userType = verifyInfo?.userType;
      localData.tiktokInfo = verifyInfo?.tiktokInfo;
      updateLocalStorage(localData);
      if (verifyInfo?.accountStatus !== "activated") {
        setShowSubMenu(true);
      }
    } else {
      console.log("verifyData?.payload?.data", verifyData?.error);
      if (verifyData?.error?.message) {
        //    setLoading(false);

        if (verifyData?.error?.error === true) {
          setShowSubMenu(true);
        }
        dispatch(changeVerifyStatusReset());
        notification.error({
          message: "Error",
          description: verifyData?.error?.message,
          placement: "topRight",
        });
      }
    }
  }, [verifyData]);

  const handleClick = (e) => {
    setCurrent(e.key);
    if (e.key === "10") {
      setVisible(!isVisible);
    }
  };

  const getReponsiveRightMenu = () => {
    let finalMenu = leftMenuItems.filter((item, i) => i !== 0);
    return [...finalMenu, ...rightMenuItems, ...menuItems];
  };

  const dropMenu = (
    <Menu onClick={(e) => handleClick(e)} selectedKeys={[current]} style={{ minWidth: "130px" }}>
      {menuItems.map((item, i) => (
        <Menu.Item key={item.key}>{item.value}</Menu.Item>
      ))}
    </Menu>
  );

  const getWebMenu = (
    <Menu
      className="music-nav-menu container"
      style={{ borderBottom: 0 }}
      onClick={(e) => handleClick(e)}
      mode="horizontal"
      selectedKeys={[current]}
    >
      {leftMenuItems.map((item, i) => {
        return (
          <Menu.Item key={item.key} style={{ float: "left" }} className={i === 0 ? "pl0" : ""}>
            {item.value}
          </Menu.Item>
        );
      })}
      {rightMenuItems.map((item, i) => {
        return (
          <Menu.Item key={item.key} style={{ marginLeft: i === 0 ? "auto" : null }}>
            {item.value}
          </Menu.Item>
        );
      })}

      {props.type !== "default" && (
        <Menu.Item key="10" className="user-avatar pr0">
          <Dropdown
            overlay={dropMenu}
            trigger={["click"]}
            placement="bottom"
            open={isVisible}
            onOpenChange={() => setVisible(!isVisible)}
          >
            <FontAwesomeIcon icon={faUserCircle} className={"user-fa"} />
          </Dropdown>
        </Menu.Item>
      )}
    </Menu>
  );
  const responsiveDropMenu = (
    <Menu style={{ display: "flex", flexDirection: "column", minWidth: "141px" }}>
      {getReponsiveRightMenu().map(function (item, i) {
        return (
          <Menu.Item key={i + 10} style={{ float: "left" }}>
            {item.value}
          </Menu.Item>
        );
      })}
    </Menu>
  );
  const getResponsiveMenu = (
    <Menu
      className="music-nav-menu container"
      style={{ borderBottom: 0 }}
      onClick={(e) => handleClick(e)}
      mode="horizontal"
    >
      <Menu.Item key={leftMenuItems[0].key} style={{ float: "left" }}>
        {leftMenuItems[0].value}
      </Menu.Item>
      <Menu.Item key={1}>
        <Dropdown overlay={responsiveDropMenu} trigger={["click"]} placement="bottom">
          <FontAwesomeIcon icon={faBars} ref={mRef} />
        </Dropdown>
      </Menu.Item>
    </Menu>
  );

  // Check's a user's auth type, then returns the page that should render
  const getAuthType = (defaultRoute) => {
    switch (checkIfUserLoggedIn().userType) {
      case "creator":
        return () => <Redirect to="/offers" />;
      case "musician":
        return () => <Redirect to="/musician/creators" />;
      default:
        return defaultRoute;
    }
  };

  return (
    <>
      <Router>
        <div className={"header-bg noselect"}>
          <div className="menu-bg">{!CheckWeb() ? getResponsiveMenu : getWebMenu}</div>
        </div>
        {verifyData?.payload?.data &&
          checkIfUserLoggedIn() &&
          checkIfUserLoggedIn().userType === "creator" &&
          verifyData?.payload?.data?.accountStatus !== "activated" && (
            <div className={"sub-nav-wrapper noselect"}>
              <SubNav
                takeSteps={() => takeAction()}
                latestStatus={verifyData?.payload?.data}
                errorStatus={verifyData?.error}
                showHideSubNav={(value) => setShowSubMenu(value)}
              />
            </div>
          )}

        <div className={`noselect`} style={{ minHeight: 380 }}>
          <div className={"page-wrapper container"}>
            {/* Routes that should swap w/ auth */}

            {/* <Route path="/login" component={getAuthType(Auth)} menuItem="login" />
            <Route path="/register" component={getAuthType(Auth)} menuItem="signUp" /> */}
            <Route
              path="/forgot-password"
              component={getAuthType(ForgotPassword)}
              menuItem="forgotPassword"
            />
            <Route
              path="/set-password"
              component={getAuthType(SetPassword)}
              menuItem="setPassword"
            />
            <Route path="/musician/creators" component={FindCreators} menuItem="findCreators" />
            <Route path="/boost" component={Boost} menuItem="boost" />
            <Route path="/analyze" component={Analyze} menuItem="analyze" />
            {/* General Routes */}
            <Route path="/offers" component={ListOffers} menuItem="listOffers" />

            {/* Musician Specific */}
            {checkIfUserLoggedIn() && checkIfUserLoggedIn().userType === "musician" && (
              <>
                {/* <Route path="/musician/creators" component={FindCreators} menuItem="findCreators" /> */}
                <Route path="/musician/sounds" component={ListSounds} menuItem="listSounds" />
              </>
            )}
            {/*   <Route path="/" component={getAuthType(Auth)} exact={true} /> */}
            {(!checkIfUserLoggedIn()?.userType ||
              checkIfUserLoggedIn()?.userType === "musician") && (
              <Route path="/" component={FindCreators} exact={true} />
            )}

            {/*   {checkIfUserLoggedIn()?.userType === "creator" && <Route path="/offers" component={ListOffers} menuItem="listOffers" />} */}
          </div>
        </div>
      </Router>

      {showPaymentModal === true && checkIfUserLoggedIn().userType === "creator" && (
        <CreatorPaymentModal open={true} close={() => setShowPaymentModal(false)} />
      )}

      {showAuthModal === true && (
        <AuthModal open={showAuthModal} close={() => setShowAuthModal(false)} />
      )}

      {checkIfUserLoggedIn() && (
        <>
          {isProfileModalOpen && (
            <ModalContainer
              isOpen={isProfileModalOpen}
              title={
                <ModalHeader
                  titleIcon={faUser}
                  modalTitle="My Account"
                  close={() => {
                    setIsProfileModalOpen(false);
                  }}
                />
              }
              footer={null}
            >
              <Profile />
            </ModalContainer>
          )}

          {checkIfUserLoggedIn().userType === "musician" && (
            <>
              {isMarketerPaymentsModalOpen && (
                <ModalContainer
                  isOpen={isMarketerPaymentsModalOpen}
                  title={
                    <ModalHeader
                      titleIcon={faCircleDollar}
                      modalTitle="Payments"
                      close={() => {
                        setIsMarketerPaymentsModalOpen(false);
                      }}
                    />
                  }
                  footer={null}
                  width={600}
                >
                  <MarketerCards />
                </ModalContainer>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default NavMenu;
