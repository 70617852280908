import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Typography, notification, Spin, Card } from "antd";
import { getInstantBookProductRequest, getInstantBookProductReset } from "../../redux/actions/instant-book-product";
import { checkIfUserLoggedIn, getRange } from "../../helper";
import "./index.scss";
import AntSpinner from "../CommonWidgets/AntSpinner";
import BudgetInfo from "../CommonWidgets/BudgetInfo";
import CreateInstantCampaignModal from "../CreateInstantCampaignModal";
import CampaignConfirmationModal from "../CampaignConfirmationModal";
import ScreenResolution from "../CommonWidgets/ScreenResolution";
import useAnalytics from "../../hooks/useAnalytics";
import AuthModal from "../AuthModal";
const { Text, Title } = Typography;

const InstantCampaign = (props) => {
  const dispatch = useDispatch(),
    { trackInstantBookModalOpened } = useAnalytics(),
    getResolution = ScreenResolution(),
    [productData, setProductData] = useState({}),
    [showAuthModal, setShowAuthModal] = useState(false),
    [showInstantCampaignSuccessModal, setShowInstantCampaignSuccessModal] = useState(false),
    [isBtnLoading, setBtnLoading] = useState(false),
    [isProductLoading, setProductLoading] = useState(false),
    [metaData, setSelectedMetaData] = useState([]),
    [showInstantCampaignModal, setShowInstantCampaignModal] = useState(false),
    { instantBookProductData } = useSelector((state) => ({ instantBookProductData: state.instantBookProductData })),
    modifyMetaData = (metaInfo, priceData) => {
      if (!checkIfUserLoggedIn()?.userType) {
        setShowAuthModal(true);
        setBtnLoading(false);
      } else {
        setSelectedMetaData([{ budget: priceData?.price, id: priceData?.priceId, metaInfo }]);

        const timer = setTimeout(() => {
          trackInstantBookModalOpened();
          setShowInstantCampaignModal(true);
        }, 250);
        return () => clearTimeout(timer);
      }
    },
    marks = configureMarks();

  function configureMarks() {
    if (productData && productData?.priceList) {
      let priceListing = productData?.priceList,
        size = priceListing.length;
      console.log("getResolution[getResolution.length]-1", getResolution.length, getResolution[getResolution.length - 1]);
      if (size > 10) {
        switch (getResolution[getResolution.length - 1]) {
          case "xs":
          case "sm":
            size = size / 4;
            break;
          case "md":
            size = size / 3;
            break;
          case "lg":
          case "xl":
            size = size / 2;
            break;
        }

        let ratio = Math.ceil(priceListing.length / size),
          arr = priceListing.filter(function (value, index, ar) {
            return index % ratio == 0;
          });
        priceListing = arr;
      }
      return getRange(priceListing);
    }
  }

  useEffect(() => {
    dispatch(getInstantBookProductRequest());
  }, []);

  useEffect(() => {
    if (!instantBookProductData.error && Object.keys(instantBookProductData?.payload).length) {
      //   notification.success({ message: "Success", description: instantBookProductData?.payload?.message, placement: "topRight" });
      setProductLoading(false);
      console.log(instantBookProductData?.payload?.data);
      setProductData(instantBookProductData?.payload?.data[0]);
      dispatch(getInstantBookProductReset());
    } else {
      if (instantBookProductData?.error?.message) {
        setProductLoading(false);
        notification.error({ message: "Error", description: instantBookProductData?.error?.message, placement: "topRight" });
      }
    }
  }, [instantBookProductData]);

  useEffect(() => {
    setProductData(productData);
  }, [productData]);

  useEffect(() => {
    setSelectedMetaData(metaData);
  }, [metaData]);

  return (
    <>
      {productData && (
        <div className="instant-card-container">
          <Card className="card-instant-booking text-center">
            <div className="text-center">
              <Title level={1} className="mb0">
                <span>{productData?.name}</span>
                <Spin indicator={AntSpinner} spinning={isProductLoading} size={"large"} className={"creator-spin ml3"}></Spin>
              </Title>
              <div className="instant-book-desc">
                <Text className="helper-text">{productData?.description}</Text>
              </div>
              <div className={"mt5"}>
                <Title level={3}>{!productData ? "Instant Campaign" : "Select your budget"}</Title>
                <div className="book-budget-container">
                  {productData?.name?.length > 0 && (
                    <BudgetInfo
                      source="instant"
                      marks={marks}
                      group={0}
                      onSubmitData={(metaInfo, price) => {
                        modifyMetaData(metaInfo, price);
                      }}
                      loadingUpdate={(value) => setBtnLoading(value)}
                      isLoading={isBtnLoading}
                      isDisabled={false}
                      budgetInfo={productData}
                      updateMetaData={(data) => setSelectedMetaData(data)}
                    />
                  )}
                </div>
                {showInstantCampaignModal && <CreateInstantCampaignModal open={showInstantCampaignModal} close={() => setShowInstantCampaignModal(false)} chosenMetaData={metaData} loadingUpdate={(value) => setBtnLoading(value)} />}
              </div>
            </div>
          </Card>
        </div>
      )}
      {showInstantCampaignSuccessModal && (
        <CampaignConfirmationModal
          open={showInstantCampaignSuccessModal}
          close={() => {
            setShowInstantCampaignSuccessModal(false);
            window.location.href = "/musician/creators";
          }}
          data={metaData}
        />
      )}
      {showAuthModal && <AuthModal open={showAuthModal} close={() => setShowAuthModal(false)} />}
    </>
  );
};
export default InstantCampaign;
