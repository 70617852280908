import React, { useEffect, useState } from "react";
import { notification, Space, Button, Select, Form, Modal, Popconfirm } from "antd";
import { useSelector, useDispatch } from "react-redux";
import FloatInput from "../../CommonWidgets/FloatInput";
import ModalContainer from "../../ModalContainer";
import ModalHeader from "../../ModalHeader";
import { manageSoundRequest, manageSoundReset } from "../../../redux/actions/manage-sound";
import "./index.scss";
import { isFormInValid } from "../../../helper";
import { faMusic } from "@fortawesome/pro-solid-svg-icons";
import useAnalytics from "../../../hooks/useAnalytics";
const { Option } = Select;
 
const SoundModal = (props) => {
  const [isLoading, setLoading] = useState(false),
  {trackAddSound, trackAddSoundFailed} = useAnalytics(),
    dispatch = useDispatch(),
    { addOrEditSoundData } = useSelector((state) => ({ addOrEditSoundData: state.addOrEditSoundData })),
    [form] = Form.useForm(),
    onManageSound = () => {
      console.log("h", hasSoundFormValue);
      form
        .validateFields()
        .then((values) => {
          if (!isFormInValid(form)) {
            setLoading(true);
            dispatch(manageSoundRequest(hasSoundFormValue));
          }
        })
        .catch((info) => {
          console.log("Validate Failed:", info);
        });
    },
    handleSelectChange = (value) => {
      let formData = { ...hasSoundFormValue };
      setSoundFormValue({ ...formData, ["genre"]: value });
    },
    genreOptions = ["Hip Hop", "Pop", "Rock", "Indie", "Alternative", "Metal", "R&B", "EDM", "Punk", "Reggae", "Funk", "Ska", "World Music", "Dubstep", "Latin", "Trap", "Country", "Americana", "Folk", "Jazz", "Blues", "Ambient", "Classical", "Hardcore", "Afrobeat"],
    [hasSoundFormValue, setSoundFormValue] = useState({
      soundTitle: props.selectedRecord ? props.selectedRecord.soundTitle : "",
      soundLink: props.selectedRecord ? props.selectedRecord.soundLink : "",
      genre: props.selectedRecord ? props.selectedRecord.genre : "",
      id: props.selectedRecord ? props.selectedRecord._id : "",
    }),
    onChange = (e) => {
      let formData = { ...hasSoundFormValue };
      setSoundFormValue({ ...formData, [e.target.name]: e.target.value });
    };

  useEffect(() => {
    setSoundFormValue(hasSoundFormValue);
  }, [hasSoundFormValue]);

  useEffect(() => {
    console.log("sound  detail state changed => ", addOrEditSoundData);
    if (!addOrEditSoundData.error && Object.keys(addOrEditSoundData?.payload).length) {
      notification.success({ key: Math.random(), message: "Success", description: addOrEditSoundData?.payload?.message, placement: "topRight" });
      if (!props.selectedRecord?._id) {
        trackAddSound("add_sound");
        form.resetFields();
      }
      setSoundFormValue({
        soundTitle: "",
        soundLink: "",
        genre: "",
      });
      setLoading(false);
      dispatch(manageSoundReset());
      props.updateSoundData(addOrEditSoundData?.payload?.data);
      if (props?.source ==="offer") {
        props.defaultRecord(addOrEditSoundData?.payload?.data[0]);
     }
    
     props.close();
     
     
    } else {
      console.log("sdfdsf", addOrEditSoundData?.error?.message);
      if (addOrEditSoundData?.error?.message) {
        if (!props.selectedRecord?._id) {
          trackAddSoundFailed("add_sound", addOrEditSoundData?.error?.message);
        }
        notification.error({ message: "Error", description: addOrEditSoundData?.error?.message, placement: "topRight" });
        setLoading(false);
      }
    }
  }, [addOrEditSoundData]);

  return (
    props.open && (
      <ModalContainer
        isOpen={props.open}
        title={
          <ModalHeader
            titleIcon={faMusic}
            modalTitle="Add Sound"
            close={() => {
              props.close();
            }}
          />
        }
        footer={[
          <div className={`flex flex-row ${props.selectedRecord?._id ? "justify-content-between" : "justify-content-end"}`} key="delete">
           {props.selectedRecord?._id && (
        
            <Popconfirm title="Do you want to delete this record?" okText={"Ok"} okButtonProps={{ loading: props.isDeleteLoading }} onConfirm={() => props.removeSound(props.selectedRecord._id)}>
              <Button className={"white-btn"} style={{ float: "left" }} size={"large"}>
                Delete
              </Button>
            </Popconfirm>
          )}
          <Button type="primary" key="btnSoundSubmit" size={"large"}  loading={isLoading} onClick={() => onManageSound()}>
            Submit
          </Button>
          </div>
        ]}
        width={1000}
      >
        <div className="mt10">
          <Form
            name="manageSound"
            form={form}
            initialValues={{
              remember: true,
            }}
 
            onFinish={onManageSound}
          >
            <Space direction="vertical" style={{ width: "100%" }}>
              <Form.Item
                name={["sound", "soundTitle"]}
                label="Sound Title"
                className={"form-item"}
                initialValue={hasSoundFormValue.soundTitle}
                rules={[
                  {
                    required: true,
                    message: "Please enter sound title",
                  },
                ]}
                hasFeedback
              >
                <FloatInput size="large" maxLength={100} required={true} type="text" placeholder="Sound Title" label="Sound Title" name="soundTitle" values={hasSoundFormValue?.soundTitle} onChange={(e) => onChange(e)} />
              </Form.Item>

              <Form.Item
                name={["sound", "soundLink"]}
                label="Sound Link"
                initialValue={hasSoundFormValue.soundLink}
                className={"form-item"}
                rules={[
                  {
                    required: true,
                    message: "Please enter sound link",
                  },
                ]}
                hasFeedback
              >
                <FloatInput type="text" size="large" label="Sound Link" placeholder="Sound Link*" name="soundLink" values={hasSoundFormValue.soundLink} onChange={(e) => onChange(e)} />
              </Form.Item>
              <Form.Item className={"form-item"} required={true} label="Genre" name={["sound", "genre"]} onChange={(e) => handleSelectChange(e)} defaultValue={hasSoundFormValue.genre}>
                <Select required={true} name="genre" size={"large"} onChange={(e) => handleSelectChange(e)} defaultValue={hasSoundFormValue.genre} placeholder="Select Genre">
                 <Option value="" key="">Select Genre</Option>
                  {genreOptions.map((item, i) => {
                    return (
                      <Option value={item} key={item}>
                        {item}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Space>
          </Form>
        </div>
      </ModalContainer>
    )
  );
};

export default SoundModal;