import React, { useState, useEffect } from "react";
import { Typography, Card, Spin } from "antd";
import styles from "./index.module.scss";
import FilterButtons from "../FilterButtons";
import CampaignInfoTile from "../CampaignInfoTile";
import DetailedInfo from "../DetailedInfo";
import moment from "moment";
import { apiCall } from "../../../modules/api-call";
import { CONFIG } from "../../../config/config";
import { calculateMetricsForMultipleObjectives } from "../../../helpers/calculateMetricsForMultipleObjectives";
import { LazyDownloadPDFButton } from "./downloadPdf";

const EMPTY_METRICS = [
  {
    value: 0,
    label: "Impressions",
    tooltip: "Ad placements on FYP",
  },
  {
    value: 0,
    label: "Views",
    tooltip: "How many times your ad was viewed",
  },
  {
    value: 0,
    label: "Engaged Views",
    tooltip: "Engaged Views",
  },
  {
    value: 0,
    label: "Clicks",
    tooltip: "Clicks on your ad",
  },
  {
    value: 0,
    label: "CPM",
    tooltip: "Cost per 1k impressions",
    moneyIndicator: true,
  },
  {
    value: 0,
    label: "CP1KV",
    tooltip: "Cost per 1k views",
    moneyIndicator: true,
  },
  {
    value: 0,
    label: "CPeV",
    tooltip: "Cost per engaged view",
    moneyIndicator: true,
  },
  {
    value: 0,
    label: "CPC",
    tooltip: "Cost per click",
    moneyIndicator: true,
  },
  {
    value: 0,
    label: "Followers",
    tooltip: "Followers you’ve directly on the ad",
  },
  {
    value: 0,
    label: "CPF",
    tooltip: "Cost per follower",
    moneyIndicator: true,
  },
  {
    value: 0,
    label: "Conversion",
    tooltip: "Conversions on your ad",
  },
  {
    value: 0,
    label: "CPA",
    tooltip: "Cost per listener converted",
    moneyIndicator: true,
  },
];

const BoostCampaignCard = ({ data }) => {
  const [activeFilters, setActiveFilters] = useState(["awareness", "conversion"]);
  const [summedData, setSummedData] = useState([]);
  const [boostInfo, setBoostInfo] = useState(data);
  const [dateFilterValue, setDateFilterValue] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleClickFilter = (type) => {
    setActiveFilters((prev) => {
      if (prev.includes(type)) {
        return activeFilters.filter((filterType) => filterType !== type);
      } else {
        return [...prev, type];
      }
    });
  };

  const getBoostInfo = async ({ startDate, endDate }) => {
    setLoading(true);
    try {
      const { data: response } = await apiCall({
        method: "GET",
        url:
          CONFIG.BACKEND_API_URL +
          `user/get-boost-info?boostId=${boostInfo.boostId}&startDate=${startDate}&endDate=${endDate}`,
        auth: 1,
      });

      setBoostInfo(response.data);
    } catch (error) {
      setBoostInfo(data);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (dateFilterValue) {
      const [startDate, endDate] = dateFilterValue;
      if (startDate && endDate) {
        const correctStartDate = moment(startDate["$d"]).format("YYYY-MM-DD");
        const correctEndDate = moment(endDate["$d"]).format("YYYY-MM-DD");
        getBoostInfo({ startDate: correctStartDate, endDate: correctEndDate });
      }
    } else {
      setBoostInfo(data);
    }
  }, [dateFilterValue]);

  useEffect(() => {
    if (boostInfo) {
      if (activeFilters.length === 0) {
        return setSummedData(EMPTY_METRICS);
      } else {
        if (activeFilters.length === 1) {
          return setSummedData(
            boostInfo.fullMetricsForUserDashboard[activeFilters[0]] || EMPTY_METRICS
          );
        } else {
          const filteredAds =
            activeFilters.length === 3
              ? boostInfo.ads
              : boostInfo.ads.filter((ad) => activeFilters.includes(ad.filter));
          const sumedMetrics = calculateMetricsForMultipleObjectives(filteredAds);
          return setSummedData(sumedMetrics);
        }
      }
    }
  }, [activeFilters, boostInfo]);

  return (
    <>
      <Card className={styles.root}>
        <div className={styles.headerWrapper}>
          <Typography.Text className={styles.title}>
            Infinite Growth - <span>{moment(boostInfo.purchased).format("YYYY-MM-DD")}</span>
          </Typography.Text>
          <LazyDownloadPDFButton
            className={styles.downloadButton}
            title={`Infinite Growth - ${moment(boostInfo.purchased).format("YYYY-MM-DD")}`}
            activeFilters={activeFilters}
            startDate={dateFilterValue?.[0]?.["$d"] || null}
            endDate={dateFilterValue?.[1]?.["$d"] || null}
            metrics={summedData}
          />
          <FilterButtons
            handleClick={handleClickFilter}
            activeFilters={activeFilters}
            dateFilterValue={dateFilterValue}
            setDateFilterValue={setDateFilterValue}
          />
        </div>
        {loading ? (
          <div className={styles.loaderWrapper}>
            <Spin size="large" />
          </div>
        ) : (
          <>
            <CampaignInfoTile metrics={summedData} />
            <DetailedInfo
              boostId={boostInfo.boostId}
              startDate={dateFilterValue?.[0]?.["$d"] || null}
              endDate={dateFilterValue?.[1]?.["$d"] || null}
              boostInfo={boostInfo}
              setBoostInfo={setBoostInfo}
            />
          </>
        )}
      </Card>
    </>
  );
};

export default BoostCampaignCard;
