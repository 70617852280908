import React, { useRef, useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { useSelector, useDispatch } from "react-redux";
import { notification } from "antd";
import "../assets/styles/modal.scss";
import CreateOffer from "../CreateOffer";
import OfferConfirmation from "../OfferConfirmation";
import OfferPreview from "../OfferPreview";
import { createOfferRequest, createOfferReset } from "../../redux/actions/create-offer";
import ListCards from "../Card/ListCards";
import UserHeaderInModal from "../UserHeaderInModal";
import useAnalytics from "../../hooks/useAnalytics";

const FindCreatorOfferWizard = forwardRef((props, ref) => {
  const offerTable = `
    .offer-table {
      height: 260px !important;
      overflow: auto;
    }
  `;
  const [current, setCurrent] = React.useState(0),
  { trackDirectBookSuccessful, trackDirectBookError, trackDirectBookConfirmationPageReached, trackDirectBookModalPaymentPageOpened } = useAnalytics(),
    dispatch = useDispatch(),
    [finalData, setFinalData] = useState([]),
    [createOfferNextButtonStatus, setCreateOfferNextButtonStatus] = useState(true),
    { createOfferData } = useSelector((state) => ({ createOfferData: state.createOfferData })),
    [loading, setLoading] = useState(false),
    updateSelectedCard = (data) => {
      const finalList = { ...finalData };
      finalList.cardDetails = { _id: "" + data[0]?.stripeCardId, cardNumber: data[0]?.cardNumber, cvv: data[0]?.cvv, expiryMonthYear: data[0]?.expiryMonthYear, nameOnCard: data[0]?.nameOnCard, cardType: data[0]?.cardType };
      finalList.cardId = data[0]?.stripeCardId;
      finalList.creatorId = props.selectedCreator._id;
      finalList.price = "" + Math.round(props.selectedCreator.tiktokInfo?.price);
      finalList.connectId = props.selectedCreator?.stripeConnectId;
      setFinalData(finalList);
    },
    oRef = useRef(),
    movetoNext = () => {
      setCurrent(current + 1);
      props.updateCurrentBookStep(current + 1);
      let newStep =  current+1;
      switch (newStep) {
        case 1:
          trackDirectBookModalPaymentPageOpened();
          break;
        case 3:
          trackDirectBookConfirmationPageReached();
          break;
        default:
          break;
      }
    },
    next = () => {
      switch (current) {
        case 0:
          oRef.current.onAddOffer();
          break;
        case 1:
          // cRef.current.onAddCard();
          break;
        default:
          break;
      }
    },
    prev = () => {
      setCurrent(current - 1);
      props.updateCurrentBookStep(current - 1);
    },
    onCreateOffer = () => {
      //  cRef.current.onAddCard();
      if (!finalData.price) {
        notification.error({ message: "Error", description: "Please select a card", placement: "topRight" });
        return false;
      }
      setLoading(true);
      delete finalData.cardDetails;
      console.log("offer data", finalData);
      dispatch(createOfferRequest(finalData));
      //  setCurrent(current + 1);
    },
    updateFinalData = (data) => {
      setFinalData(data);
    },

    steps = [
      {
        title: "Offer Details",
        content: <CreateOffer ref={oRef} setNext={() => movetoNext()} updateData={updateFinalData} setDataToPage={finalData} isNextDisabled={(status) => props.updateCreateOfferNextStatus(status)} currentNextButtonStatus={props.currentOfferNextButtonStatus} />,
      },
      {
        title: "Payment",
        content: <ListCards setNext={() => movetoNext()} getSelectedCard={updateSelectedCard} from={"offer"} isNextDisabled={(status) => props.updateCreateCardNextStatus(status)} currentNextButtonStatus={props.currentCardNextButtonStatus} />,
      },
      {
        title: "Confirmation",
        content: <OfferPreview previewData={finalData} source="offer" creatorName={props.selectedCreator?.tiktokInfo?.nickName} setNext={() => movetoNext()} />,
      },
      {
        title: "Complete",
        content: <OfferConfirmation creatorName={props.selectedCreator?.tiktokInfo?.nickName} />,
      },
    ],
    formatCreatorData = () => {
      const creatorData = props?.selectedCreator;
      return {
        userName: creatorData?.tiktokHandle,
        profileImage: creatorData?.tiktokInfo?.profileImage,
        creatorInfo: {
          tiktokHandle: creatorData?.tiktokHandle,
          followers: creatorData?.tiktokInfo?.totalFollowers,
          likes: creatorData?.tiktokInfo?.totalLikes,
          videoCount: creatorData?.tiktokInfo?.videoCount,
          tags: creatorData?.tags
        },
        price: creatorData?.tiktokInfo?.price
      }
    };

  useEffect(() => {
    setFinalData(finalData);
  }, [finalData]);


  useEffect(() => {
    if (!createOfferData.error && Object.keys(createOfferData?.payload).length) {
      notification.success({ message: "Success", description: createOfferData?.payload?.message, placement: "topRight" });
      setLoading(false);
      trackDirectBookSuccessful();
      setCurrent(current + 1);
      props.updateCurrentBookStep(current + 1);
      dispatch(createOfferReset());
    } else {
      console.log("createOfferData?.error?.message", createOfferData?.error?.message);
      if (createOfferData?.error?.message) {
        trackDirectBookError();
        setLoading(false);
        notification.error({ message: "Error", description: createOfferData?.error?.message, placement: "topRight" });
      }
    }
  }, [createOfferData]);

  useImperativeHandle(ref, () => ({
    onPrev() {
      prev();
    },
    onNext() {
      next();
    },
    onMoveToNext() {
      movetoNext();
    },
    onBookCreateOffer() {
      onCreateOffer();
    },
    onLoading() {
      return loading;
    },
    onCurrentStep() {
      return current;
    },
    onCreateOfferNextButtonStatus() {
      return createOfferNextButtonStatus;
    }

  }));

  return (
    <>
      <style>{offerTable}</style>
      <div>
        <UserHeaderInModal userData={formatCreatorData()} />
        <div className="steps-content mt6 ">{steps[current].content}</div>
      </div>

    </>
  );
});

FindCreatorOfferWizard.displayName = "FindCreatorOfferWizard";
export default FindCreatorOfferWizard;
