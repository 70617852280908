import React, { useState, useEffect } from "react";
import { Typography} from "antd";
import UploadVideo from "../../UploadVideo";
import "./index.scss";
import DeliverableContainer from "../../CommonWidgets/DeliverableContainer";
const { Title, Text } = Typography;

const CreatorUploadVideoAction = (props) => {
      const  [videoSending, setVideoSending] = useState(false);

    const sendVideo = (status, data) => {
        setVideoSending(true);
        props.uploadAction(status, data);
    };


    useEffect(() => {
        setVideoSending(videoSending);
    }, [videoSending]);

    return (
        <div>
            <Title level={4}>{"Upload Video"}</Title>
            {props?.latestFeedback ?
                <>
                 <div>
                    <DeliverableContainer content={ props?.latestFeedback} contentType="Feedback" ></DeliverableContainer>
                    {/* <p className="content-description feedback-text">{props?.latestFeedback}</p> */}
                </div>
                    
                    <p>Please review the feedback and upload a new video below</p>
                </>
                : <Text>Please upload your content below</Text>
            }
            <div className="review-video">
                <UploadVideo
                    updateStatus={(status, data) => props.uploadAction(status, data)}
                    videoUploadStatus={videoSending}
                    sendVideo={sendVideo}
                />
            </div>
        </div>
    );
}

export default CreatorUploadVideoAction; 