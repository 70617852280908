import React, { useState } from "react";
import { Typography, Button } from "antd";
import { LeftArrow, RightArrow } from "../../CommonWidgets/FontIcons";
import "./index.scss";
const { Title, Text } = Typography;

const MarketerCancelOfferAction = (props) => {
    const [clicked, setClicked] = useState(""),
        clickedAction = (data) => {
            setClicked(data?.newStatus);
            switch (data?.newStatus) {
                case "cancelled":
                    props.marketerCancelOfferAction("cancelled");
                    break;

                default:
                    break;
            }
            return true;
        };

    return (
        <div>
            <Title level={4}>{"Do you want to cancel this offer?"}</Title>
            <Text>Your offer will be cancelled with this action</Text>
            <div className="offer-action">
                <div>
                    <Button type={"default"} onClick={() => props.marketerCancelOfferAction("noaction")}><LeftArrow /> No</Button>
                </div>
                <div>
                    <Button type={"primary"} loading={clicked === "cancelled" ? props?.loaderStatus : false} onClick={() => clickedAction({ newStatus: "cancelled" })}>Yes<RightArrow /> </Button>
                </div>
            </div>

        </div>
    );
}

export default MarketerCancelOfferAction; 