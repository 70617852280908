import { combineReducers } from "redux";
import changePasswordData from "./change-password";
import createAccountData from "./create-account";
import fileUploadData from "./file-upload";
import signedUrlData from "./get-signed-url";
import uploadAwsData from "./upload-to-aws";
import loginData from "./login";
import logoutData from "./logout";
import profileData from "./profile";
import forgotPasswordData from "./forgot-password";
import setPasswordData from "./set-password";
import listCardData from "./list-card";
import createCardData from "./create-card";
import deleteCardData from "./delete-card";
import listCreatorData from "./list-creator";
import creatorPaymentSetUpData from "./creator-payment-activate";
import toggleFavoriteData from "./marketer-favorite";
import listOfferData from "./list-offer";
import listOfferChatData from "./list-offer-chat";
import createOfferData from "./create-offer";
import changeOfferStatusData from "./change-offer-status";
import creatorDashboardData from "./creator-dashboard";
import listPaymentData from "./list-payment";
import deleteImageData from "./delete-image";
import createChatData from "./create-chat";
import listUserChatData from "./list-user-chat";
import updatePaymentSetUpData from "./update-payment-activate";
import verifyData from "./check-verify-status";
import creatorPayoutData from "./creator-payout";
import listShippingAddressData from "./list-shipping-address";
import createShippingAddressData from "./create-shipping-address";
import boostData from "./boost";
import listProductsBoostData from "./list-products-boost";
import updateBoostData from "./update-boost";
import analyzeData from "./analyze";
import listSoundData from "./list-sound";
import addOrEditSoundData from "./manage-sound";
import deleteSoundData from "./delete-sound";
import instantBookProductData from "./instant-book-product";
import createInstantCampaignData from "./create-instant-campaign";
import userEvergreenBoostsData from "./evergreen-boosts";
//import setSignedUrlData from './set-signedurl';

const rootReducer = (state, action) => {
  if (action.type === "LOGOUT") {
    state = undefined;
  }
  return appReducer(state, action);
};

const appReducer = combineReducers({
  changePasswordData,
  createAccountData,
  signedUrlData,
  uploadAwsData,
  fileUploadData,
  loginData,
  logoutData,
  profileData,
  setPasswordData,
  createCardData,
  listCardData,
  deleteCardData,
  listCreatorData,
  creatorPaymentSetUpData,
  toggleFavoriteData,
  listOfferData,
  listOfferChatData,
  createOfferData,
  changeOfferStatusData,
  creatorDashboardData,
  listPaymentData,
  deleteImageData,
  forgotPasswordData,
  createChatData,
  listUserChatData,
  updatePaymentSetUpData,
  verifyData,
  creatorPayoutData,
  listShippingAddressData,
  createShippingAddressData,
  boostData,
  listProductsBoostData,
  updateBoostData,
  analyzeData,
  listSoundData,
  addOrEditSoundData,
  deleteSoundData,
  instantBookProductData,
  createInstantCampaignData,
  userEvergreenBoostsData,
  // setSignedUrlData
});

export default rootReducer;
