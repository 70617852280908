import { apiCall } from "../../modules/api-call";

export const uploadToAwsRequest = (uploadUrl, data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: "UPLOAD_AWS_REQUEST", payload: {} });
      const { data: response } = await apiCall({
        method: "PUT",
        url: uploadUrl,
        data,
        auth: 0,
        headers: {
          "Content-Type": data.type,
        },
      });
      if (!response) {
        dispatch({ type: "UPLOAD_AWS_REQUEST_SUCCESS", payload: response });
      } else {
        dispatch({ type: "UPLOAD_AWS_REQUEST_ERROR", payload: response });
      }
    } catch (err) {
      dispatch({ type: "UPLOAD_AWS_REQUEST_ERROR", payload: { message: err?.response?.message ? err?.response?.message :err.message } });
    }
  };
};

export const uploadToAwsReset = () => {
  return async (dispatch) => {
    dispatch({ type: "UPLOAD_AWS_REQUEST_RESET" });
  };
};
