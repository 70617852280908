import React from "react";
import { Image, Avatar } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle } from "@fortawesome/pro-solid-svg-icons";

const ImageRenderer = (props) => {
  const height = props.height;
  const width = props.width;
  const imageUrl = props.imageUrl,
    isAvatar = props.isAvatar,
    className = props.className ? props.className : " mr3";

  if (!isAvatar) {
    if (imageUrl?.length > 0) {
      return <Image src={imageUrl} height={height} width={width} preview={false} alt="image url" className={className} />;
    } else {
      return <FontAwesomeIcon icon={faUserCircle} className={className} />;
    }
  } else {
    if (imageUrl?.length > 0) {
      return <Avatar size={height || width} src={imageUrl} alt="img" className={"mr3 img-border"} />;
    } else {
      return <Avatar className={"p0"} size={height || width} icon={<FontAwesomeIcon icon={faUserCircle} className="user-fa"  />} alt="img"/>;
    }
  }
};
export default ImageRenderer;
