import React, { useEffect, useState } from "react";
import { Tabs } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle, faHistory, faTasks } from "@fortawesome/pro-regular-svg-icons";
import { checkIfUserLoggedIn } from "../../helper";
import "../assets/styles/modal.scss";
import OfferTimeline from "../OfferTimeline";
import UserHeaderInModal from "../UserHeaderInModal";
import BriefPreview from "../BriefPreview";
import OfferStatusHelper from "../OfferStatusHelper";
import offerStatusList from "../../config/offerStatus";
import OfferAction from "../OfferAction";
import moment from "moment";
import actionStatusList from "../../config/actionStatus";
import InstantCampaignHeaderInModal from "../InstantCampaignHeaderInModal";

const OfferDetailsModal = (props) => {
  console.log("props?.data", props?.data);
  const [tabIndex, setTabIndex] = useState("1"),
    [offerInfo, setOfferInfo] = useState(props?.data),
    [actionTabStatus, setActionTabStatus] = useState(false),
    [currentOfferStatusRow, setCurrentOfferStatusRow] = useState(null),
    [loaderStatus, setLoaderStatus] = useState(false),
    onTabChange = (activeKey) => {
      setTabIndex(activeKey);
    },
    formatUserData = (item) => {
      const userType = checkIfUserLoggedIn()?.userType;
      if (userType === "musician") {
        return {
          userName: item?.creatorId?.tiktokHandle,
          profileImage: item?.creatorId?.tiktokInfo?.profileImage,
          creatorInfo: {
            tiktokHandle: item?.creatorId?.tiktokHandle,
            followers: item?.totalFollowers,
            likes: item?.totalLikes,
            videoCount: item?.totalVideos,
            tags: item?.creatorId?.tags,
          },
          price: item?.finalPrice,
        };
      } else {
        return {
          userName: item?.marketerId?.userName,
          profileImage: item?.marketerProfileImage,
          creatorInfo: {},
          price: item?.finalPrice,
        };
      }
    },
    checkIfDeadlineCrossed = () => {
      const result = moment(offerInfo?.deadline, "YYYY/MM/DD").isBefore(moment());
      if ((offerInfo?.currentStatus === "video-in-progress") === "sent" || (result && offerInfo?.currentStatus === "video-in-progress" && offerInfo?.whoAmI === "musician")) {
        return true;
      } else {
        return false;
      }
    },
    fetchCurrentStatus = () => {
      const currentOfferStatusFromDb = offerInfo?.currentStatus,
        offerStatusRow = offerStatusList.filter((statusRow) => statusRow.key === currentOfferStatusFromDb && statusRow.whoAmI.includes(checkIfUserLoggedIn().userType)),
        actionStatusData = actionStatusList.filter((actionStatusRow) => actionStatusRow?.key === offerStatusRow[0].key && actionStatusRow.whoAmI.includes(checkIfUserLoggedIn().userType));
      const isOverDue = checkIfDeadlineCrossed();
      offerStatusRow[0].hasAction = isOverDue ? isOverDue : actionStatusData[0]?.key ? true : false;
      offerStatusRow[0].isOverDue = currentOfferStatusFromDb === "sent" && offerInfo?.whoAmI === "musician" ? true : isOverDue;
      return offerStatusRow;
    },
    updateActionStatus = (status) => {
      const updateNewStatus = fetchCurrentStatus(status);
      setCurrentOfferStatusRow(updateNewStatus);
    };

  useEffect(() => {
    setCurrentOfferStatusRow(currentOfferStatusRow);
  }, [currentOfferStatusRow]);

  useEffect(() => {
    setCurrentOfferStatusRow(fetchCurrentStatus());
  }, [offerInfo]);

  useEffect(() => {
    setTabIndex(tabIndex);
  }, [tabIndex]);

  useEffect(() => {
    setActionTabStatus(actionTabStatus);
  }, [actionTabStatus]);

  const items = [
    {
      label: (
        <span>
          <FontAwesomeIcon icon={faInfoCircle} />
          Information
        </span>
      ),
      key: "1",
      children: (
        <>
          {offerInfo?.offerType === "instant" ? <InstantCampaignHeaderInModal metaData={props?.data?.instantBookMetaData} /> : <UserHeaderInModal userData={formatUserData(offerInfo)} />}
          {offerInfo?.offerType === "normal" && <OfferStatusHelper data={offerInfo} actionTab={false} status={currentOfferStatusRow} onTabSwitch={() => onTabChange("3")} disableActionTab={(val) => setActionTabStatus(val)} manualUpdateStatus={(status) => updateActionStatus(status)} />}
          <BriefPreview data={offerInfo} source={offerInfo?.offerType} />
        </>
      ),
    },
    {
      label: (
        <span>
          <FontAwesomeIcon icon={faHistory} />
          Timeline
        </span>
      ),
      key: "2",
      children: <OfferTimeline data={offerInfo} />,
    },
    {
      label: (
        <span>
          <FontAwesomeIcon icon={faTasks} />
          Action
        </span>
      ),
      key: "3",
      disabled: currentOfferStatusRow && !currentOfferStatusRow[0]?.hasAction,
      children: (
        <>
         {offerInfo?.offerType === "normal" &&   <OfferStatusHelper data={offerInfo} status={currentOfferStatusRow} actionTab={true} />}
          {currentOfferStatusRow && currentOfferStatusRow[0]?.hasAction && <OfferAction data={offerInfo} status={currentOfferStatusRow} loaderStatus={loaderStatus} updateRow={props?.updateRow} onTabSwitch={() => onTabChange("1")} closeModal={props?.closeModal} updateOfferData={(data) => setOfferInfo(data)} />}
        </>
      ),
    },
  ];
  return <Tabs activeKey={tabIndex} type="card" size={"large"} className="pushtok-tab" onChange={(tabKey) => onTabChange(tabKey, 1)} items={currentOfferStatusRow && items} />;
};

export default OfferDetailsModal;
