
import React from "react";
import { Image, Result } from "antd";
import Logo from "../../Logo";
import "../index.scss";

const NoDataFound = (props) => {
    const title = props.showContent === false ? "No Data Found" : ""; 
    return (
        !props.showContent ?
            <Result
                icon={<Logo />}
                title={title}
            />
            :
            ""
        
    );
};
export default NoDataFound;