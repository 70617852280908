import React from "react";
import styles from "./index.module.scss";
import { DatePicker } from "antd";
import moment from "moment";

const { RangePicker } = DatePicker;

const FilterButtons = ({ activeFilters, handleClick, dateFilterValue, setDateFilterValue }) => {
  const disabledDate = (current) => {
    return current && current > moment().endOf("day");
  };
  return (
    <div className={styles.root}>
      <div className={styles.buttonsWrapper}>
        <button
          onClick={() => handleClick("awareness")}
          className={`${styles.button} ${
            activeFilters.includes("awareness") ? styles.active : null
          }`}
        >
          Awareness Ads
        </button>
        <button
          onClick={() => handleClick("conversion")}
          className={`${styles.button} ${
            activeFilters.includes("conversion") ? styles.active : null
          }`}
        >
          Conversion Ads
        </button>
        <button
          onClick={() => handleClick("follows")}
          className={`${styles.button} ${activeFilters.includes("follows") ? styles.active : null}`}
        >
          Follower Ads
        </button>
        <div className={styles.dateFilterWrapper}>
          <RangePicker
            popupClassName={styles.datePickerPanels}
            value={dateFilterValue}
            onCalendarChange={(value) => setDateFilterValue(value)}
            disabledDate={disabledDate}
          />
        </div>
      </div>
    </div>
  );
};

export default FilterButtons;
