import React from "react";
import styles from "./index.module.scss";
import { ReactComponent as QuestionSVG } from "../../assets/svg/questionSvg.svg";
import { Tooltip } from "antd";
import { numberWithCommas } from "../../../helper";
import "overlayscrollbars/overlayscrollbars.css";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";

const CampaignInfoTile = ({ metrics }) => {
  return (
    <div className={styles.root}>
      <OverlayScrollbarsComponent className={styles.tile}>
        {metrics.slice(0, 6).map((item, i) => {
          return (
            <div key={i} className={styles.tileItemWrapper}>
              <div className={styles.tileItem}>
                <p className={styles.tileItemValue}>
                  {item?.moneyIndicator
                    ? `$${item.value.toFixed(2)}`
                    : numberWithCommas(item.value)}
                  <div className={styles.tooltipIcon}>
                    <Tooltip
                      arrow={false}
                      placement="bottom"
                      title={item.tooltip}
                      overlayClassName={styles.tooltip}
                      overlayInnerStyle={{
                        border: "none",
                      }}
                    >
                      <QuestionSVG />
                    </Tooltip>
                  </div>
                </p>
                <span className={styles.tileItemLabel}>{item.label}</span>
              </div>
            </div>
          );
        })}
      </OverlayScrollbarsComponent>
      <OverlayScrollbarsComponent className={styles.tile}>
        {metrics.slice(6, 12).map((item, i) => {
          return (
            <div key={i} className={styles.tileItemWrapper}>
              <div className={styles.tileItem}>
                <p className={styles.tileItemValue}>
                  {item?.moneyIndicator ? `$${item.value.toFixed(2)}` : item.value}
                  <div className={styles.tooltipIcon}>
                    <Tooltip
                      arrow={false}
                      placement="bottom"
                      title={item.tooltip}
                      overlayClassName={styles.tooltip}
                      overlayInnerStyle={{
                        border: "none",
                      }}
                    >
                      <QuestionSVG />
                    </Tooltip>
                  </div>
                </p>
                <span className={styles.tileItemLabel}>{item.label}</span>
              </div>
            </div>
          );
        })}
      </OverlayScrollbarsComponent>
    </div>
  );
};

export default CampaignInfoTile;
