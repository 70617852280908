import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Form, Button, Card, Typography, notification } from "antd";
import FloatInput from "../../CommonWidgets/FloatInput";
import { forgotPasswordRequest } from "../../../redux/actions/forgot-password";
import { RightArrow } from "../../CommonWidgets/FontIcons";
const { Title } = Typography;

const ForgotPassword = () => {
  const dispatch = useDispatch(),
    [loading, setLoading] = useState(false),
    [form] = Form.useForm(),
    [myEmail, setEmail] = useState(""),
    { forgotPasswordData } = useSelector((state) => ({ forgotPasswordData: state.forgotPasswordData })),
    onForgotPassword = (data) => {
      data.user.email = data.user.email.toLowerCase();
      setLoading(true);
      dispatch(forgotPasswordRequest(data.user));
    };

  useEffect(() => {
    if (!forgotPasswordData.error && Object.keys(forgotPasswordData?.payload).length) {
      setLoading(false);
      notification.success({ message: "Success", description: forgotPasswordData?.payload?.message, placement: "topRight" });
    } else {
      if (forgotPasswordData?.error?.message) {
        setLoading(false);
        notification.error({ message: "Error", description: forgotPasswordData?.error?.message, placement: "topRight" });
      }
    }
  }, [forgotPasswordData]);

  return (
    <>
        <Title level={2} className="text-center">
          Forgot Password
        </Title>
        <Form
          name="normal_login"
          form={form}
          initialValues={{
            remember: true,
          }}
          onFinish={onForgotPassword}
        >
          <Form.Item
            name={["user", "email"]}
            rules={[
              {
                type: "email",
                message: "The input is not valid E-mail",
              },
              {
                required: true,
                message: "Please input your Email",
              },
            ]}
            hasFeedback
          >
            <FloatInput required={true} size="large" label="Email" placeholder="Email" type="text" name="email" values={myEmail} onChange={(e) => setEmail(e.target.value)} />
          </Form.Item>

          <Form.Item shouldUpdate>
            {() => (
              <Button size="large" type="primary" block htmlType="submit" loading={loading} disabled={!form.isFieldsTouched(true) || !!form.getFieldsError().filter(({ errors }) => errors.length).length}>
                Submit <RightArrow />
              </Button>
            )}
          </Form.Item>
        </Form>
    </>
  );
};
export default ForgotPassword;
