import React, { useEffect } from "react";
import AntSpinner from "../../components/CommonWidgets/AntSpinner";
import { Table, Typography, Spin, notification, Form, Card } from "antd";
import BoostCampaignCard from "./BoostCampaignCard";
import { useDispatch, useSelector } from "react-redux";
import { getUserEvergreenBoosts } from "../../redux/actions/evergeen-boost";
import { Carousel } from "antd";
import { RightOutlined, LeftOutlined } from "@ant-design/icons";
import styles from "./index.module.scss";
import { ReactComponent as ArrowSVG } from "../assets/svg/carouselArrow.svg";

const { Title, Text } = Typography;

const BoostCampaigns = () => {
  const dispatch = useDispatch();
  const { loading, userBoosts } = useSelector((state) => state.userEvergreenBoostsData);

  useEffect(() => {
    dispatch(getUserEvergreenBoosts());
  }, []);

  return (
    <div className={styles.root}>
      <Spin className={styles.wrapper} indicator={AntSpinner} spinning={loading} size={"large"}>
        <Title level={1} className={styles.title}>
          Boost Campaigns
        </Title>
        {!!userBoosts?.length && (
          <Carousel
            dots={false}
            arrows
            nextArrow={<ArrowSVG className={styles.arrow} />}
            prevArrow={<ArrowSVG className={styles.arrow} />}
          >
            {userBoosts.map((boost) => (
              <BoostCampaignCard key={boost.id} data={boost} />
            ))}
          </Carousel>
        )}
      </Spin>
    </div>
  );
};

export default BoostCampaigns;
