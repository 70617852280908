import { apiCall } from "../../modules/api-call";
import { CONFIG } from "../../config/config";

export const updateBoostRequest = (id, boostType) => {
  return async (dispatch) => {
    try {
      dispatch({ type: "UPDATE_BOOST_REQUEST", payload: {} });
      const { data: response } = await apiCall({
        method: "POST",
        url: CONFIG.BACKEND_API_URL + "user/product/boost/update",
        auth: 0,
        data: { sessionId: id, boostType },
      });
      console.log("UPDATE BOOST Request response => ", response);
      if (response && response.statusCode === 200 && response.error === false) {
        dispatch({ type: "UPDATE_BOOST_REQUEST_SUCCESS", payload: response });
      } else {
        dispatch({ type: "UPDATE_BOOST_REQUEST_ERROR", payload: response });
      }
    } catch (err) {
      console.log("UPDATE_BOOST_REQUEST_ERROR error => ", err);
      dispatch({
        type: "UPDATE_BOOST_REQUEST_ERROR",
        payload: { message: "Something went wrong" },
      });
    }
  };
};

export const updateBoostReset = () => {
  return async (dispatch) => {
    dispatch({ type: "UPDATE_BOOST_REQUEST_RESET" });
  };
};
