import React, {useState, useEffect} from "react";
import { Typography } from "antd";
import "./index.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleExclamation, faXmarkCircle } from "@fortawesome/pro-solid-svg-icons";
import { checkIfUserLoggedIn } from "../../helper";
const { Text } = Typography;

const SubNav = (props) => {
    console.log("abcd props", props);
    const 
        accountStatus = props?.errorStatus?.error=== true ? "nonCompatible" : props?.latestStatus?.accountStatus ? props?.latestStatus?.accountStatus : checkIfUserLoggedIn()?.accountStatus,
        [showSubNav, setShowSubNav] = useState(true),
        setMessage = () => {
             let messageText = "";
            switch (accountStatus) {
            case "new":
                messageText = "To get paid, please complete your account verification with Stripe.";
            break;
            case "incomplete":
              
                messageText = "Stripe needs more information to properly pay you. Click here";
            break;
            case "rejected":
                messageText = "Your Stripe account has been disabled, please reach out to support for more information";
            break;
            case "nonCompatible":
                messageText = "This account is not compatible with this version of the platform.";
            break;
            default:
                
                break;
           }
           return messageText;
        };

        useEffect(() => {
         //   alert(props?.latestStatus?.accountStatus);
           if (accountStatus === "new" || accountStatus === "incomplete" || accountStatus === "rejected") {
               setShowSubNav(true);
               props.showHideSubNav(true);
           } else {
            setShowSubNav(false);
            props.showHideSubNav(false);
           }
          }, []);

          useEffect(() => {
            setShowSubNav(showSubNav);
          }, [showSubNav]);
        
    return (

        showSubNav && (
            <div className={"d-sub-nav clickable container cursor-pointer"}  onClick={()=>props.takeSteps()}>
                <div>
                    <Text className={"text-white"}>
                    <FontAwesomeIcon icon={faCircleExclamation} />  {setMessage()}
                    </Text>
                </div>
                <div>
                <Text className={"text-white"}>  <FontAwesomeIcon icon={faXmarkCircle} className={"clickable text-white"} onClick={(e) => {e.stopPropagation(); setShowSubNav(false); return false;}} /></Text>
                </div>
            </div>
        )
    );
};
export default SubNav;
