import React, { useState, useEffect, forwardRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Upload, Button } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/pro-regular-svg-icons";
import { uploadToAwsRequest } from "../../redux/actions/upload-to-aws";
//import { deleteImageRequest } from "../redux/actions/delete-image";
import { getSignedURLRequest, signedURLReset } from "../../redux/actions/get-signed-url";
import { checkIfUserLoggedIn, trimSpace } from "../../helper";
import VideoPlayer from "../VideoPlayer";
import "./index.scss";
import { RightArrow } from "../CommonWidgets/FontIcons";

const UploadVideo = forwardRef((props, ref) => {

const  fileUserName = trimSpace(checkIfUserLoggedIn().userName),
    [loading, setLoading] = useState(false),
    [uploadFile, setFile] = useState(null),
   [defaultFileList, setDefaultFileList] = useState(null),
    [videoUrl, setVideoUrl] = useState(""),

    dispatch = useDispatch(),
    { signedUrlData, uploadAwsData } = useSelector((state) => ({ signedUrlData: state.signedUrlData, uploadAwsData: state.uploadAwsData })),
    uploadButton = (
      <div>
        <Button loading={loading}  type="primary" size="large" className="btn-upload" icon={<FontAwesomeIcon icon={faUpload} />}>Upload</Button>
      </div>
    ),

    handleOnChange = ({ file=null, fileList, event }) => {
      setDefaultFileList(fileList);
      setLoading(true);
    },
    removeFile = () => {
    //  setRemoveImage(true);
    //  setKey("");
    },

    handlePreview = () => {

    };

  useEffect(() => {
    setVideoUrl(videoUrl);
  }, [videoUrl]);

  useEffect(() => {
    dispatch(signedURLReset());
  },[]);

  useEffect(() => {
    if (!signedUrlData.error && Object.keys(signedUrlData?.payload).length) {
       dispatch(uploadToAwsRequest(signedUrlData.payload?.data?.fullSignedUrl, uploadFile));
 
    } else {
      if (signedUrlData?.error?.message) {
        //
      }
    }
  }, [signedUrlData]);

  useEffect(() => {
    if (uploadAwsData.payload.length <=0) {
      if (signedUrlData && signedUrlData.payload?.data) {
       setVideoUrl(signedUrlData?.payload?.data?.signedUrl);
       setDefaultFileList(null);
    
      }
    } else {
      setVideoUrl("");
      if (uploadAwsData?.error?.message) {
        setLoading(false);
      }
    }
  }, [uploadAwsData]);



  return (
    <>

      {videoUrl?.length > 0 ?
          <div className="player-container">
            <>
            <VideoPlayer videoUrl={videoUrl} height={"260px"} />
                <Button
                  type="primary"
                  className="mt4"
                  size="large"
                  loading={props?.videoUploadStatus}
                  onClick={() => {props.sendVideo('video-submitted', { videoUrl: signedUrlData.payload?.data?.signedUrl })}}
                >
                  Submit <RightArrow/>
                 </Button>
                </>
          </div>
         :
              <Upload
                name="avatar"
                accept="video/*"
                
                defaultFileList={defaultFileList}
                onChange={handleOnChange}
                onPreview={handlePreview}
                maxCount={1}
                onRemove={() => removeFile()}
                customRequest={({ file }) => {
                  if (file.name) {
                    const fileType = file.type;
                    const fileName = file.name;
                    setFile(file);
                    const key = `${fileUserName}_${Math.random()}.${fileName}`;
                   // setKey(key);
                    setLoading(true);
                    let data = {
                      fileName: key,
                      userName: fileUserName,
                    };
                      if (file && file.name.length >= 0) {
                     // onSuccess(setLoading(false));
                    
                       dispatch(getSignedURLRequest(data));

                    }
                  }
                }}
              >
                {uploadButton}
              </Upload>
      }
    </>
  );
});
UploadVideo.displayName="UploadVideo";
export default UploadVideo;