import React, { forwardRef } from "react";
import "../assets/styles/modal.scss";
import CardTile from "../Card/CardTile";
import BriefPreview from "../BriefPreview";

const OfferPreview = forwardRef((props, ref) => {
  const { previewData } = props;
  return (
    <>
      <BriefPreview data={previewData} source={props?.source} />
      <CardTile cardItem={previewData?.cardDetails} from={"preview"} preventSelection={true} />
    </>
  );
});

OfferPreview.displayName = "OfferPreview";
export default OfferPreview;
