import ReactGA from "react-ga4";
import {CONFIG} from "./config/config";

export const initGA = () => {
    // Initialize UA
  //  ReactGA.initialize(CONFIG.TRACKING_ID);
    // Initialize GA4 - Add your measurement ID
    ReactGA.initialize(CONFIG.MEASUREMENT_ID);
    console.log("qqq");

};

export const PageView = () => {
  ReactGA.pageview(window.location.pathname + window.location.search);
};

/**
 * Event - Add custom tracking event.
 * @param {string} category 
 * @param {string} action 
 * @param {string} label 
 */
export const Event = (action, category, label) => {
  console.log("event", category, action, label);
  console.log("wwww");

 /* ReactGA.event({
    name: category,
    params: {first_field_name: "lakshmi"},
    category: category,
    action: action
  });
*/
  // Send GA4 Event
  ReactGA.event({
    category: category,
    action: action,
    label: label
  });
  };