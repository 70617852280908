
import React from "react";
import {  Result, Button } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCreditCard } from "@fortawesome/pro-regular-svg-icons";
import "../index.scss";

const NoShippingAddressDataFound = (props) => {
    const title = props.showContent === false ? "Add Your First Address" : ""; 
    return (
        !props.showContent ?
            <Result
                icon={<FontAwesomeIcon icon={faCreditCard}  className="card-result" />}
                title={title}
                extra={[
                    <Button  key="addAddress" type="primary" size="large" onClick={()=>props.startAdd()}>
                   <FontAwesomeIcon icon={faCreditCard} /> Add a Shipping Address
                  </Button>
                ]}
            />
            :
            ""
        
    );
};
export default NoShippingAddressDataFound;