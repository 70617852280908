import React, { useState } from "react";
import { Typography, Button } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/pro-regular-svg-icons";
import VideoPlayer from "../../VideoPlayer";
import "./index.scss";

const { Title, Text } = Typography;

const ViewOfferVideo = (props) => {
  const [downloadLoader, setDownloadLoader] = useState(false),
    onDownloadFile = (fileUrl, callback = () => { }) => {
      let xhr = new XMLHttpRequest();
      xhr.open("GET", fileUrl, true);
      xhr.responseType = "blob";
      xhr.onload = function () {
        let urlCreator = window.URL || window.webkitURL;
        let videoUrl = urlCreator.createObjectURL(this.response);
        let tag = document.createElement("a");
        tag.href = videoUrl;
        tag.target = "_blank";
        tag.download = fileUrl?.split("/")[5] || "Video";
        document.body.appendChild(tag);
        tag.click();
        document.body.removeChild(tag);
        setTimeout(() => {
          callback();
        }, 500);
      };
      xhr.onerror = () => {
        callback();
      };
      xhr.send();
    };

  return (
    <div>
      <Title level={4}>{"View Video"}</Title>
      <Text>{props?.creator} has completed your video</Text>
      <div className="review-video">
        <div>
        <VideoPlayer videoUrl={props?.videoUrl} height={"260px"} />
        </div>
        <div>
          <Button type={"primary"}
            loading={downloadLoader}
            onClick={(evt) => {
              evt.stopPropagation();
              setDownloadLoader(true);
              onDownloadFile(props?.videoUrl, () => {
                setDownloadLoader(false);
              });
            }}
          ><FontAwesomeIcon icon={faDownload} /> Download Video</Button>
        </div>

      </div>

    </div>
  );
}

export default ViewOfferVideo; 