import React, { forwardRef } from "react";
import { Card, Typography } from "antd";
import moment from "moment";
import "../assets/styles/modal.scss";
import "./index.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTruck, faMusic } from "@fortawesome/pro-solid-svg-icons";
import { rawHtml } from "../../helper";
import Colors from "../../theme/Colors";
const { Title, Text } = Typography;

const BriefPreview = forwardRef((props, ref) => {
  const previewData = props?.data,
    revisionCount = previewData?.allowedRevisions ? Math.max(0, previewData?.allowedRevisions - previewData?.videoRevisions?.filter((r) => r.currentStatus === "video-rejected").length) : 2,
    metaData = (props?.source== "instant") ? [] :  [
      {
        label: "Approve Offer By",
        text: moment(previewData?.approveBy).format("MM/DD/YYYY"),
      },
      {
        label: "Deadline",
        text: moment(previewData?.deadline).format("MM/DD/YYYY"),
      },
      {
        label: "Revisions",
        text: revisionCount,
      },
    ];
  return (
    <>
      <Card className={"preview-container"}>
        <div className="brief-header">
          <Title level={4}>{previewData?.offerTitle}</Title>
          <Text><FontAwesomeIcon icon={faMusic} /> <a target="_blank" rel="noreferrer" href={previewData?.sound?.link}>{previewData?.sound.title}</a></Text>
          {metaData.map((item, index) => {
            return (
              <div key={item?.label}>
                <Text type="secondary">{item?.label}: </Text>
                <Text className="content-text">{item?.text}</Text>
              </div>
            );
          })}
        </div>
        <div>
          <p className="content-text content-description text-align-none" dangerouslySetInnerHTML={rawHtml(previewData?.offerDesc)}></p>
        </div>

        {previewData?.requireShipping && (
          <div>
            <FontAwesomeIcon icon={faTruck} style={{ color: Colors.primaryColor, fontWeight: 600 }} />
            <Text style={{ color: Colors.primaryColor, fontWeight: 600 }}>This offer requires shipping</Text>
          </div>
        )}
      </Card>
    </>
  );
});
BriefPreview.displayName = "BriefPreview";
export default BriefPreview;
