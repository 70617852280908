import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from "@fortawesome/pro-solid-svg-icons";
import { Typography } from "antd";
import Colors from "../../theme/Colors";

const { Text } = Typography;

const ShippingAddress = ({ item }) => {
  let addressIcon = <FontAwesomeIcon icon={faLocationDot} className={"location-icon"} />;
  return (
    <>
      <div className="flex flex-column">
        <Text className="label-text mb0">
          {item?.receiverName}
        </Text>
        {item?.companyName && item?.companyName.length > 0 && <Text style={{ color: Colors.mutedText }}>{item?.companyName}</Text>}
      </div>
      <div className="mt2">
        <Text>{addressIcon}</Text>
        <Text>
          {item?.addressLine1} {item?.addressLine2},{item?.city}, {item?.state} {item?.zipCode}
        </Text>
      </div>
    </>
  );
};
export default ShippingAddress;
