import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Form, Button, Card, Typography, notification } from "antd";
import FloatInput from "../../components/CommonWidgets/FloatInput";
import { setPasswordRequest } from "../../redux/actions/set-password";
import { isFormInValid } from "../../helper";
import { RightArrow } from "../../components/CommonWidgets/FontIcons";
const { Title } = Typography;

const SetPassword = () => {
  const dispatch = useDispatch(),
    [form] = Form.useForm(),
    [loading, setLoading] = useState(false),
    { setPasswordData } = useSelector((state) => ({ setPasswordData: state.setPasswordData })),
    onSetPassword = (data) => {
      form
        .validateFields()
        .then(() => {
          if (!isFormInValid(form)) {
               let query = new URLSearchParams(window.location.search),
              pwdHash = query.get("prt");
              data.hash = pwdHash;
            delete data.confirmPassword;
            setLoading(true);
            dispatch(setPasswordRequest(data));
          } else {
            notification.error({ message: "Error", description: "Please fix the errors", placement: "topRight" });
          }
        })
        .catch((info) => {
        });
    },
    [hasSetPasswordFormValue, setPasswordFormValue] = useState({
      confirmPassword: "",
      userPassword: "",
    }),
    onChange = (e) => {
      let formData = { ...hasSetPasswordFormValue };
      setPasswordFormValue({ ...formData, [e.target.name]: e.target.value });
    };

  useEffect(() => {
    if (!setPasswordData.error && Object.keys(setPasswordData?.payload).length) {
      setLoading(false);
      notification.success({ message: "Success", description: setPasswordData?.payload?.message, placement: "topRight" });
      const timer = setTimeout(() => {
        window.location.href = "/";
      }, 2000);
      return () => clearTimeout(timer);
    } else {
      if (setPasswordData?.error?.message) {
        setLoading(false);
        notification.error({ message: "Error", description: setPasswordData?.error?.message, placement: "topRight" });
      }
    }
  }, [setPasswordData]);

  return (
    <>
      <Card className="password-container" bordered={false}>
        <Title>Set New Password</Title>
        <Form
          name="normal_login"
          initialValues={{
            remember: true,
          }}
          onFinish={onSetPassword}
        >
          <Form.Item
            name="userPassword"
            rules={[
              {
                required: true,
                message: "Please input your password",
              },
              {
                pattern: new RegExp(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/),
                message: "Minimum eight characters, at least one letter, one number and one special character:",
              },
            ]}
            hasFeedback
          >
            <FloatInput size="large" label="Password" placeholder="Password" type="password" name="userPassword" values={hasSetPasswordFormValue.userPassword} onChange={(e) => onChange(e)} />
          </Form.Item>
          <Form.Item
            className="mb0"
            name="confirmPassword"
            // label="Confirm Password"
            dependencies={["userPassword"]}
            hasFeedback
            rules={[
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("userPassword") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error("The two passwords that you entered do not match"));
                },
              }),
            ]}
          >
            <FloatInput size="large" label="Confirm Password" placeholder="Confirm Password" type="password" name="confirmPassword" values={hasSetPasswordFormValue.confirmPassword} onChange={(e) => onChange(e)} />
          </Form.Item>
          <Form.Item>
            <Button size="large" type="primary" block htmlType="submit" loading={loading}>
              Submit  <RightArrow/>
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </>
  );
};
export default SetPassword;
