import React from "react";
import { Result, Button, Modal } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart, faCheck, faMagnifyingGlassChart } from "@fortawesome/pro-solid-svg-icons";

const CampaignConfirmationModal = (props) => {
  console.log("campaign cnfirm", props.data);
  return (
      <Result
        icon={<FontAwesomeIcon icon={faHeart} className="card-result" />}
        subTitle={
          <>
            <div>Your offer was sent to the <b>Pushtok Team</b>. Please check your email for the confirmation message.</div>
          </>
        }
        extra={[
          <Button key="viewCampaigns" type="primary" size="large" onClick={() => (window.location.href = "/offers")}>
            <FontAwesomeIcon icon={faCheck} /> View Campaigns
          </Button>,
        ]}
      />

  );
};
export default CampaignConfirmationModal;
