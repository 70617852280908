import React from "react";
import { Typography } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleExclamation, faBan, faTimer, faCheck } from "@fortawesome/pro-solid-svg-icons";
import { capitalize } from "../../../helper";
import moment from "moment";

const { Text } = Typography;

const StatusItem = ({ record, status }) => {
  const { whoAmI, currentStatus } = record,
     actionRow = record?.videoRevisions?.filter((r) => r.currentStatus === status),
     updatedAt = actionRow[actionRow.length - 1]?.updatedAt,
     displayUpdatedDate = moment(updatedAt).utc().fromNow();

  let icon;
  let statusText;

  switch (currentStatus) {
    case "pending-shipment":
      if (whoAmI === "musician") {
        icon = faCircleExclamation;
        statusText = "Requires Shipment";
      } else {
        icon = faTimer;
        statusText = "Shipment In-Progress";
      }
      break;
    case "pending-receipt":
      if (whoAmI === "creator") {
        icon = faCircleExclamation;
        statusText = "Shipment Received";
      } else {
        icon = faTimer;
        statusText = "Shipment In-Transit";
      }
      break;
    case "video-in-progress":
      if (whoAmI === "creator") {
        icon = faCircleExclamation;
        statusText = "Requires Video";
      } else {
        icon = faTimer;
        statusText = "Video In-Progress";
      }
      break;
    case "cancelled":
      icon = faBan;
      statusText = "Cancelled";
      break;
    case "offer-expired":
      icon = faBan;
      statusText = "Offer Expired";
      break;
    case "sent":
      if (whoAmI === "creator") {
        icon = faCircleExclamation;
        statusText = "Offer Received";
      } else {
        icon = faTimer;
        statusText = "Offer Sent";
      }
      break;
    case "video-submitted":
      if (whoAmI === "creator") {
        icon = faTimer;
        statusText = "Review In-Progress";
      } else {
        icon = faCircleExclamation;
        statusText = "Review Required";
      }
      break;

    case "video-rejected":
      if (whoAmI === "creator") {
        icon = faCircleExclamation;
        statusText = "Requires Video";
      } else {
        icon = faTimer;
        statusText = "Video In-Progress";
      }
      break;
    case "rejected":
      icon = faBan;
      statusText = "Offer Rejected";
      break;
    case "video-approved":
        if (whoAmI === "creator") {
          icon = faCircleExclamation;
          statusText = "Requires Tiktok Url";
        } else {
          icon = faTimer;
          statusText = "Tiktok Upload In-Progress";
        }
        break;
    case "completed":
      icon = faCheck;
      statusText = "Completed";
      break;
    default:
      statusText = capitalize(currentStatus);
      break;
  }

  const additionalClassName = icon === faBan ? "incomplete-campaign" : icon === faCheck ? "complete-campaign" : "";

  return (
    <div className="flex align-items-center">
      <FontAwesomeIcon icon={icon} className={`status-icon ${additionalClassName}`} bounce={icon === faCircleExclamation} spin={icon === faTimer} />
      <div className="flex flex-column">
        <Text>{statusText}</Text>
        <Text className={"secondary-text"}>{displayUpdatedDate}</Text>
      </div>
    </div>
  );
};

export default StatusItem;
