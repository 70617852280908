
import React from "react";
import { Button, Result, Modal } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/pro-regular-svg-icons";

const BoostSuccessModal = (props) => {
    return (
            <Result
             icon={<FontAwesomeIcon icon={faCircleCheck} />}
             className={"result-tick"}
                status="success"
                title="Your Boost has been successfully submitted!"
            />
    );
};
export default BoostSuccessModal;