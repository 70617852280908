import React, { useRef, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Typography, Spin, Row, Col } from "antd";
import { getCardsRequest } from "../../../redux/actions/list-card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCancel, faCreditCard, faSave } from "@fortawesome/pro-solid-svg-icons";
import AntSpinner from "../../CommonWidgets/AntSpinner";
import AddCardModal from "../AddCardModal";
import NoCardDataFound from "../../Result/NoCardDataFound";
import CardFormatter from "../CardFormatter";
import "./index.scss";
const { Title } = Typography;

const ListCards = (props) => {
  const dispatch = useDispatch(),
    cRef = useRef(),
    [cardData, setCardData] = useState([]),
    [value, setValue] = useState(""),
    [isDataLoading, setDataLoading] = useState(false),
    [isLoading, setLoading] = useState(false),
    [innerStep, setInnerStep] = useState(1),
    { listCardData } = useSelector((state) => ({ listCardData: state.listCardData })),
    updateLoadingData = (isLoad) => {
      setDataLoading(isLoad);
    },
    updateCardData = (data) => {
      props.getSelectedCard([data[0]]);
      setValue(data[0]._id);
      setCardData(data);
      props.isNextDisabled(false);
      setInnerStep(1);
    },
    updateChosenCard = (data) => {
      props.getSelectedCard(data);
    };

  useEffect(() => {
    setLoading(true);
    dispatch(getCardsRequest());
  }, []);

  useEffect(() => {
    if (!listCardData.error && Object.keys(listCardData?.payload).length) {
      setCardData(listCardData?.payload?.data);
      setLoading(false);

      if (props.from) {
        setValue(listCardData?.payload?.data[0]._id);
        props.isNextDisabled(false);
        props.getSelectedCard([listCardData?.payload?.data[0]]);
      }
    } else {
      if (listCardData?.error?.message) {
        setLoading(false);
        props.isNextDisabled(true);
        // notification.error({ message: "Error", description: listCardData?.error?.message, placement: "topRight" });
      }
    }
  }, [listCardData]);

  useEffect(() => {
    setCardData(cardData);
  }, [cardData]);

  return (
    <>
      <div>
        {props.from === "offer" ? (
          <div className="card-title-container pb3">
            <div className="card-title-item">
              <span className="label-text">PAYMENTS</span>
              <div>Please select a card to proceed with the campaign process</div>
            </div>
            {cardData && cardData.length > 0 && (
            <div className="card-title-item">
              <Button type="primary" size="large" onClick={() => setInnerStep(2)} className={"right"}>
                <FontAwesomeIcon icon={faCreditCard} /> Add a Card
              </Button>
            </div>
            )}
          </div>
        ) : (
          <Title level={3}> PAYMENT</Title>
        )}
      </div>

      <Spin indicator={AntSpinner} spinning={isLoading} size={"large"}>
        {cardData && cardData.length > 0 ? <CardFormatter cardData={cardData} setValue={(value) => setValue(value)} value={value} from={"offer"} updateChosenCard={(data) => updateChosenCard(data)} /> : listCardData.error && <NoCardDataFound showContent={isLoading} startAdd={() => setInnerStep(2)} />}
      </Spin>

    {innerStep === 2  && <AddCardModal open={true} updateTable={updateCardData} close={() => setInnerStep(1)} />}
    </>
  );
};

export default ListCards;
