import React from "react";
import { Image } from "antd";

const Logo = () => {
  return (
    <>
      <Image className="img-fluid" style={{ width: "100px", padding: '10px' }} src={process.env.PUBLIC_URL + "/img/logo.png"} alt="Pushtok" preview={false} />
    </>
  );
};
export default Logo;
