import React from "react";
import ReactPlayer from "react-player";
import "./index.scss";

const VideoPlayer = (props) => {
    const {height, videoUrl} = props;
  return (
    <>
      <ReactPlayer controls className="react-player" height={height} width="100%" url={videoUrl} />
    </>
  );
};
export default VideoPlayer;
