import React, { useContext, useEffect } from "react";
import packageJson from "../../package.json";

// Private enumeration of event names
const events = {
  login: "login",
  loginFailed: "login_failed",
  register: "sign_up",
  registerFailed: "sign_up_failed",
  addSound: "add_sound_success",
  addSoundFailed: "add_sound_failed",
  addCard: "add_card_success",
  addCardFailed: "add_card_failed",
  boostModalOpened: "select_item",
  boostOrderStarted: "boost_order_started",
  boostOrderCompleted: "boost_order_completed",
  boostOrderFailed: "boost_order_failed",
  instantBookModalOpened: "instant_book_modal_opened",
  instantBookPaymentPageReached: "instant_book_payment_page_reached",
  instantBookConfirmationPageReached: "instant_book_confirmation_page_reached",
  instantBookSuccessful: "instant_book_success",
  instantBookError: "instant_book_error",
  instantBookOrderInterrupted: "instant_book_order_interrupted",
  directBookModalOpened: "direct_book_modal_opened",
  directBookPaymentPageReached: "direct_book_payment_page_reached",
  directBookConfirmationPageReached: "direct_book_confirmation_page_reached",
  directBookSuccessful: "direct_book_success",
  directBookError: "direct_book_error",
  directBookOrderInterrupted: "direct_book_order_interrupted",
  campaignsViewed: "campaigns_viewed",
  videoSubmitted: "video_submitted",
  revisionRequested: "revision_requested",
  campaignCompleted: "campaign_completed",
};

const useAnalytics = () => {
  const trackUserProperties = (userProps) => {
    window.gtag("set", "user_properties", {
      ...userProps,
    });
  };

  // Private generic method to push events to GA4
  const sendEvent = (name, dimensions = {}) => {
    //Sends the actual event
    window.gtag("event", name, {
      ...dimensions,
      webVersion: packageJson.version,
    });
  };

  const trackRegister = (method) => {
    sendEvent(events.register, {
      method,
    });
  };

  const trackRegisterFailed = (method) => {
    sendEvent(events.registerFailed, {
      method,
    });
  };

  const trackLogin = (method) => {
    sendEvent(events.login, {
      method,
    });
  };

  const trackLoginFailed = (method, error) => {
    sendEvent(events.loginFailed, {
      method,
      error,
    });
  };

  const trackAddCard = (method) => {
    sendEvent(events.addCard, {
      method,
    });
  };

  const trackAddCardFailed = (method, error) => {
    sendEvent(events.addCardFailed, {
      method,
      error,
    });
  };

  const trackAddSound = (method) => {
    sendEvent(events.addSound, {
      method,
    });
  };

  const trackAddSoundFailed = (method, error) => {
    sendEvent(events.addSoundFailed, {
      method,
      error,
    });
  };

  const trackBoostModalOpened = (data) => {
    sendEvent(events.boostModalOpened, {
      item_list_id: data.productKey,
      item_list_name: "Boost product",
      items: [
        {
          item_id: data.productId,
          item_name: data.name,
        },
      ],
    });
  };

  const trackBoostOrderStarted = (name, price) => {
    sendEvent(events.boostOrderStarted, {
      productName: name,
      amount: price,
    });
  };

  const trackBoostOrderFailed = (data) => {
    sendEvent(events.boostOrderFailed, data);
  };
  const trackBoostOrderCompleted = (data) => {
    sendEvent(events.boostOrderCompleted, data);
  };

  const trackInstantBookModalOpened = () => {
    sendEvent(events.instantBookModalOpened);
  };

  const trackInstantBookModalPaymentPageOpened = () => {
    sendEvent(events.instantBookPaymentPageReached);
  };

  const trackInstantBookConfirmationPageReached = () => {
    sendEvent(events.instantBookConfirmationPageReached);
  };

  const trackInstantBookError = () => {
    sendEvent(events.instantBookError);
  };

  const trackInstantBookSuccessful = () => {
    sendEvent(events.instantBookSuccessful);
  };

  const trackInstantBookOrderInterrupted = (step) => {
    sendEvent(events.instantBookOrderInterrupted, {
      step: step,
    });
  };

  const trackDirectBookModalOpened = () => {
    sendEvent(events.directBookModalOpened);
  };

  const trackDirectBookModalPaymentPageOpened = () => {
    sendEvent(events.directBookPaymentPageReached);
  };

  const trackDirectBookConfirmationPageReached = () => {
    sendEvent(events.directBookConfirmationPageReached);
  };

  const trackDirectBookError = () => {
    sendEvent(events.directBookError);
  };

  const trackDirectBookSuccessful = () => {
    sendEvent(events.directBookSuccessful);
  };

  const trackDirectBookOrderInterrupted = (step) => {
    sendEvent(events.directBookOrderInterrupted, {
      step: step,
    });
  };

  const trackCampaignsViewed = (campaignStatus) => {
    sendEvent(events.campaignsViewed, {
      campaignStatus: campaignStatus
    });
  };

  const trackVideoSubmitted = () => {
    sendEvent(events.videoSubmitted);
  };

  const trackRevisionRequested = () => {
    sendEvent(events.revisionRequested);
  };

  const trackCampaignCompleted = () => {
    sendEvent(events.campaignCompleted);
  };

  return {
    trackUserProperties,
    trackLogin,
    trackLoginFailed,
    trackRegister,
    trackRegisterFailed,
    trackAddCard,
    trackAddCardFailed,
    trackAddSound,
    trackAddSoundFailed,
    trackBoostModalOpened,
    trackBoostOrderStarted,
    trackBoostOrderFailed,
    trackBoostOrderCompleted,
    trackInstantBookModalOpened,
    trackInstantBookModalPaymentPageOpened,
    trackInstantBookConfirmationPageReached,
    trackInstantBookError,
    trackInstantBookSuccessful,
    trackInstantBookOrderInterrupted,
    trackDirectBookModalOpened,
    trackDirectBookModalPaymentPageOpened,
    trackDirectBookConfirmationPageReached,
    trackDirectBookError,
    trackDirectBookSuccessful,
    trackDirectBookOrderInterrupted,
    trackCampaignsViewed,
    trackVideoSubmitted,
    trackCampaignCompleted,
    trackRevisionRequested,
  };
};

export default useAnalytics;
