import React from "react";
import ModalHeader from "../ModalHeader";
import { Modal } from "antd";
import styles from "./index.module.scss";

const BoostStatisticModal = ({ children, handleClose, isOpen, title }) => {
  return (
    <Modal
      closeIcon={null}
      title={<ModalHeader modalTitle={title} close={handleClose} />}
      open={isOpen}
      footer={null}
      className={styles.root}
    >
      <div className={styles.content}>{children}</div>
    </Modal>
  );
};

export default BoostStatisticModal;
