const actionStatusList = [ 
    {key: "sent", whoAmI: ["musician", "creator"]}, 
    {key: "pending-shipment", whoAmI: ["musician"]}, 
    {key: "pending-receipt", whoAmI: ["creator"]}, 
    {key: "video-in-progress", whoAmI: ["creator"]}, 
    {key: "video-submitted", whoAmI: ["musician"]}, 
    {key: "video-rejected",  whoAmI: ["creator"]},
    {key: "video-approved",  whoAmI: ["creator"]},
    {key: "completed",  whoAmI: ["musician", "creator"]}
];

export default actionStatusList;