import React, { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { useSelector, useDispatch } from "react-redux";
import { notification, Space, Form, Row, Col, Select } from "antd";
import FloatInput from "../../CommonWidgets/FloatInput";
import { createShippingAddressRequest, createShippingAddressReset } from "../../../redux/actions/create-shipping-address";
import "../../assets/styles/modal.scss";
import statesList from "../../../config/states";
import { isFormInValid } from "../../../helper";
const {Option} = Select;

const AddShippingAddress = forwardRef((props, ref) => {
  const dispatch = useDispatch(),
    { createShippingAddressData } = useSelector((state) => ({ createShippingAddressData: state.createShippingAddressData })),
    [form] = Form.useForm(),
    onManageShippingAddress = () => {
      form
        .validateFields()
        .then(() => {
          if (!isFormInValid(form)) {
            props.loadingUpdate(true);
            dispatch(createShippingAddressRequest(hasAddShippingAddressFormValue));

          }
        })
        .catch((info) => {
    //
          //    props.loadingUpdate(false);
        });
    },
    [hasAddShippingAddressFormValue, setAddShippingAddressFormValue] = useState({
      receiverName: props.setDataToPage ? props.setDataToPage.receiverName : "",
      companyName: props.setDataToPage ? props.setDataToPage.companyName : "",
      addressLine1: props.setDataToPage ? props.setDataToPage.addressLine1 : "",
      addressLine2: props.setDataToPage ? props.setDataToPage.addressLine2 : "",
      city: props.setDataToPage ? props.setDataToPage.city : "",
      state: props.setDataToPage ? props.setDataToPage.state : "",
      zipCode: props.setDataToPage ? props.setDataToPage.zipCode : "",
      isSave: props.setDataToPage ? props.setDataToPage.isSave : false,
    }),
    onChange = (e) => {
      let formData = { ...hasAddShippingAddressFormValue };
      setAddShippingAddressFormValue({ ...formData, [e.target.name]: e.target.value });
    },
    handleSelectStateChange = (value) => {
      let formData = { ...hasAddShippingAddressFormValue };
      setAddShippingAddressFormValue({ ...formData, ["state"]: value });
    };

  useImperativeHandle(ref, () => ({
    onAddShippingAddress() {
      onManageShippingAddress();
    }
  }));

  useEffect(() => {
    setAddShippingAddressFormValue(hasAddShippingAddressFormValue);
  }, [hasAddShippingAddressFormValue]);

  useEffect(() => {
    if (!createShippingAddressData.error && Object.keys(createShippingAddressData?.payload).length) {
      notification.success({ message: "Success", description: createShippingAddressData?.payload?.message, placement: "topRight" });
      form.resetFields();
      props.updateData(createShippingAddressData?.payload.data);
      props.loadingUpdate(false);
      dispatch(createShippingAddressReset());

      setAddShippingAddressFormValue({
        receiverName: "",
        companyName: "",
        expiryMonthYear: "",
        addressLine1: "",
        addressLine2: "",
        city: "",
        state: "",
        zipCode: "",
        isSave: false
      });
    } else {
      if (createShippingAddressData?.error?.message) {
        notification.error({ message: "Error", description: createShippingAddressData?.error?.message, placement: "topRight" });
        props.loadingUpdate(false);
      }
    }
  }, [createShippingAddressData]);

  return (
    <Form
      name="manageShippingAddress"
      form={form}
      initialValues={{
        remember: true,
      }}
      onFinish={onManageShippingAddress}
    >
      <Space direction="vertical" className="w-full" size={0}>
        <Form.Item
          name={["shippingAddress", "receiverName"]}
          initialValue={hasAddShippingAddressFormValue.receiverName}
          rules={[
            {
              required: true,
              message: "Please enter the name",
            },
          ]}
          hasFeedback
        >
          <FloatInput size="large" label="Name" placeholder="John Doe" type="text" name="receiverName" values={hasAddShippingAddressFormValue.receiverName} onChange={(e) => onChange(e)} />
        </Form.Item>
        <Form.Item
          name={["shippingAddress", "companyName"]}
          initialValue={hasAddShippingAddressFormValue.companyName}
          hasFeedback
        >
          <FloatInput type="text" size="large" label="Company Name" placeholder="Enter Company Name" name="companyName" values={hasAddShippingAddressFormValue.companyName} onChange={(e) => onChange(e)} />
        </Form.Item>

        <Form.Item
          name={["shippingAddress", "addressLine1"]}
          initialValue={hasAddShippingAddressFormValue.addressLine1}
          rules={[
            {
              required: true,
              message: "Please enter address",
            },
          ]}
          hasFeedback
        >
          <FloatInput type="text" size="large" label="Address Line1" placeholder="Enter Address Line1" name="addressLine1" values={hasAddShippingAddressFormValue.addressLine1} onChange={(e) => onChange(e)} />
        </Form.Item>


        <Form.Item
          name={["shippingAddress", "addressLine2"]}
          initialValue={hasAddShippingAddressFormValue.addressLine2}
          hasFeedback
        >
          <FloatInput type="text" size="large" label="Address Line2" placeholder="Enter Address line2" name="addressLine2" values={hasAddShippingAddressFormValue.addressLine2} onChange={(e) => onChange(e)} />
        </Form.Item>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col span={8}>

            <Form.Item
              name={["shippingAddress", "city"]}
              initialValue={hasAddShippingAddressFormValue.city}
              rules={[
                {
                  required: true,
                  message: "Please enter city",
                },
              ]}
              hasFeedback
            >
              <FloatInput type="text" size="large" label="City" placeholder="Enter City" name="city" values={hasAddShippingAddressFormValue.city} onChange={(e) => onChange(e)} />
            </Form.Item>
          </Col>
          <Col span={8}>

            <Form.Item
              name={["shippingAddress", "state"]}
              initialValue={hasAddShippingAddressFormValue.state}
              rules={[
                {
                  required: true,
                  message: "Please enter state",
                },
              ]}
              hasFeedback
            >
               <Select size={"large"} onChange={handleSelectStateChange} defaultValue={hasAddShippingAddressFormValue.state}>
                  {statesList && statesList.map(item => {
                    return <Option key={item.code} value={item.code}>{item.name}</Option>
                  })}
                </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name={["shippingAddress", "zipCode"]}
              initialValue={hasAddShippingAddressFormValue.zipCode}
              rules={[
                {
                  required: true,
                  message: "Please enter Zip Code",
                },
              ]}
              hasFeedback
            >
              <FloatInput type="text" size="large" label="Zip Code" placeholder="Enter Zip Code" name="zipCode" values={hasAddShippingAddressFormValue.zipCode} onChange={(e) => onChange(e)} />
            </Form.Item>
          </Col>
        </Row>
      </Space>
    </Form>
  );
});

AddShippingAddress.displayName="AddShippingAddress";
export default AddShippingAddress;
