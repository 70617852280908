export default (
    state = {
      loading: false,
      payload: {},
      error: null,
    },
    action
  ) => {
    switch (action.type) {
      case "CHANGE_VERIFY_STATUS_REQUEST":
        return { ...state, loading: true, payload: {}, error: null };
      case "CHANGE_VERIFY_STATUS_REQUEST_SUCCESS":
        return { ...state, loading: false, payload: action.payload, error: null };
      case "CHANGE_VERIFY_STATUS_REQUEST_ERROR":
        return { ...state, loading: false, payload: {}, error: action.payload };
      default:
        return state;
    }
  };
  