import React from "react";
import { Button } from "antd";
import "./index.scss";

const PillSelector = (props) => {
  const { text, isSelected, onSelectionChanged } = props;

  return (
    <>
      <Button
        className="border-radius-30"
        type={isSelected ? "primary" : "default"}
        onClick={() => {
          onSelectionChanged && onSelectionChanged();
        }}
      >
        {text}
      </Button>
    </>
  );
};

export default PillSelector;
