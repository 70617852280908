import React, { useState } from "react";
import { Typography, Button } from "antd";
import { RightArrow } from "../../CommonWidgets/FontIcons";
import "./index.scss";
const { Title, Text } = Typography;

const CreatorMarkShipmentReceived = (props) => {
    const [clicked, setClicked] = useState(""),
        newStatus = "video-in-progress",
    clickedAction = () => {
        setClicked(newStatus);
        props.markShipmentReceivedAction(newStatus);
    };

    return (
        <>

            <div>
                <Title level={4}>{"Please mark the shipment as received"}</Title>
                <Text>Start making the video</Text>
                <div className="offer-action">
                    <div>
                        <Button type={"primary"} loading={clicked === newStatus ? props?.loaderStatus : false} onClick={() => clickedAction()}>Received<RightArrow /></Button>
                    </div>
                </div>

            </div>
        </>
    );
}

export default CreatorMarkShipmentReceived; 