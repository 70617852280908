import { CONFIG } from "./config/config";
import moment from "moment";

export const getAccessToken = () => (localStorage.getItem("USER") ? (JSON.parse(localStorage.getItem("USER")).userRole === "manager" || JSON.parse(localStorage.getItem("USER")).userRole === "owner" ? "owner" : JSON.parse(localStorage.getItem("USER")).userRole) : "");
export const setAccessToken = (a) => {
  localStorage.setItem("accessToken", a);
};

export function checkIfUserLoggedIn() {
  let loggedInUser = JSON.parse(window.localStorage.getItem(CONFIG.LOCALSTORAGE_USER));
  return loggedInUser ? loggedInUser : false;
}

export function getLoggedInUserName() {
  let loggedInUser = JSON.parse(window.localStorage.getItem(CONFIG.LOCALSTORAGE_USER));
  return loggedInUser ? loggedInUser.firstName : "";
}

export function updateLocalStorage(data) {
  let userData  = JSON.parse(localStorage.getItem(CONFIG.LOCALSTORAGE_USER));
  let 
    obj = {
      userName: data?.userName,
      tiktokHandle: data?.tiktokHandle,
      country: data?.country,
      userType: data?.userType,
      email: data?.email,
      accountStatus: (data?.accountStatus) ? data?.accountStatus : "",
      accountUrl: (data?.accountUrl) ? data?.accountUrl : "",
      profileImage: data.userType ? data?.userType === "creator" ? data?.tiktokInfo?.profileImage : data?.profileImage : userData?.profileImage,
    };
  
  obj.token = userData && userData.token ? userData.token : data.token;
  localStorage.setItem(CONFIG.LOCALSTORAGE_USER, JSON.stringify(obj));

  return true;
}

export function callLogout() {
  window.localStorage.removeItem(CONFIG.LOCALSTORAGE_USER);
  window.localStorage.removeItem('userId');
  window.localStorage.removeItem('login');
  window.location.href = "/";
}

export const required = (value) => {
  return value ? undefined : "Required";
};

export const isEqualToPasswordField = (value, values = {}) => (value === values.password ? undefined : "Confirm Password do not match password.");

export const isEmail = (value) => {
  const emailregex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i;
  return emailregex.test(value) ? undefined : "Enter valid email";
};

export const mustBeNumber = (value) => (isNaN(value) ? "Must be a number" : undefined);

export const mustBeAlphaNummer = (value) => {
  return /^[a-z0-9A-Z]+$/gi.test(value) ? undefined : "Must be a alphanumeric";
};

export const minValue = (min) => (value) => isNaN(value) || value >= min ? undefined : `Should be greater than ${min}`;

export const composeValidators =
  (...validators) =>
  (value, values) =>
    validators.reduce((error, validator) => error || validator(value, values), undefined);

export const max3Numbers = (value) => {
  const temp = value + "";
  return temp.length <= 2 ? undefined : "Maximum 2 numbers";
};

export const resolveWith = (a) => Promise.resolve(a);

export const activeLangOptions = (arr = []) => {
  return arr.filter((lang) => Boolean(lang.is_active)).map((data) => ({ label: data.language_name, value: data.language_id }));
};

export const compareTime = (to, from, msg) => {
  if (to !== "" && to !== undefined && to !== null && from !== "" && from !== null && from !== undefined) {
    let first_date = new Date();
    let second_date = new Date();
    if (from) {
      let time = from.split(":");
      if (time.length) {
        first_date.setHours(time[0]);
        first_date.setMinutes(time[1]);
        first_date.setSeconds(time[2]);
      }
    }
    if (to) {
      let time = to.split(":");
      if (time.length) {
        second_date.setHours(time[0]);
        second_date.setMinutes(time[1]);
        second_date.setSeconds(time[2]);
      }
    }
    if (first_date > second_date) {
      return msg;
    }
  }
};

export const validateImageFile = (fileExt) => {
  let validExt = ["image/jpg", "image/JPG", "image/JPEG", "image/jpeg", "image/gif", "image/GIF", "image/png", "image/PNG"];
  if (validExt.includes(fileExt)) {
    return true;
  } else {
    return false;
  }
};

export const getAge = (dateString) => {
  var today = new Date(),
    birthDate = new Date(dateString),
    age = today.getFullYear() - birthDate.getFullYear(),
    m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};

export const isDate = (s) => {
  if (isNaN(s) && !isNaN(Date.parse(s))) return true;
  else return false;
};

export const findDateYear = (age) => {
  let TODAY = new Date(),
    finalDob = new Date(TODAY).getFullYear() - age + "-" + new Date(TODAY).getMonth() + "-" + new Date(TODAY).getDate();
  return finalDob;
};


export const formatDateTime = (dateString) => {
  let months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
    birthDate = new Date(dateString);
  if (isDate(dateString)) {
    //	return (birthDate.getMonth()+1) + '/' + birthDate.getDate() + '/' +  birthDate.getFullYear() + " " + birthDate.getHours() + ":" + birthDate.getMinutes() + ":" + birthDate.getSeconds();
    return birthDate.getDate() + " " + months[birthDate.getMonth()] + " " + birthDate.getFullYear();
  } else {
    return "";
  }
};

export const  numberFormatter = (num) => {
  if (num >= 1000000000) {
    return Math.round(num / 1000000000).toFixed(1).replace(/\.0$/, '') + 'B';
  }
  if (num >= 1000000) {
    return Math.round(num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
  }
  if (num >= 1000) {
    return Math.round(num / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
  }
  return Math.round(num);
};


export const getFlag = (country) => {
  let flag = "unitedstates.png";
  switch (country) {
    case "US":
      flag = "unitedstates.png";
      break;
    case "UK":
      flag = "unitedkingdom.png";
      break;
    case "Pakistan":
      flag = "pakistan.png";
      break;
    default:
      break;
  }
  return flag;
};


export const formatNumber = (num) => {
  num = Math.round(num);
  return "$" + num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const isFormInValid = (form) => {
  return form.getFieldsError().some((item) => item.errors.length > 0);
};

export const getTiktokID = (url) => {
  var res = url.match(/(http(s)?:\/\/.)?(?:www|m)\.(?:tiktok.com)\/(?:@)([><?@+'`~^%&\*\[\]\{\}.!#|\\\"$';,:;=\(\),\-\w\s+]+)\/video\/[0-9]+/gms);
  return res != null;
};

export const capitalize = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const validateAge = (date) => {
  var yearsAgo = moment().subtract("years", 13);
  var birthday = moment(date);

  if (!birthday.isValid()) {
    return false;
  } else if (yearsAgo.isAfter(birthday)) {
    return true;
  } else {
    return false;
  }
};

export const trimSpace = (str) => {
 if (str) return str.replace(/[ ]+/g, "!"); 
 return str;
};

export const replaceCarrot = (str) => {
  if (str) return str.replace(/[!]+/g, " ");
  return str;
};

export const findName = (str) => {
  if (str) {
    if (checkIfUserLoggedIn().userType === "musician") {
      return replaceCarrot(str.split("-")[1]);
    } else {
      return replaceCarrot(str.split("-")[0]);
    }
  }
  return str;
}

export const nFormatter = (num, digits) => {
  var si = [
    { value: 1, symbol: "" },
    { value: 1E3, symbol: "k" },
    { value: 1E6, symbol: "M" },
    { value: 1E9, symbol: "G" },
    { value: 1E12, symbol: "T" },
    { value: 1E15, symbol: "P" },
    { value: 1E18, symbol: "E" }
  ];
  var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var i;
  for (i = si.length - 1; i > 0; i--) {
    if (num >= si[i].value) {
      break;
    }
  }
  return (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;
}

export const maskCard = (str) => {
  str = str.replace(/\s/g, "") ;
  return str.replace(/\d(?=\d{4})/g, "*");
}

export const validURL = (str) => {
  var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
    '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
  return pattern.test(str);
}

export const getDateDiff = (date1,date2,metric) => {
  return moment(date1).diff(date2,metric)
}

export const parseDate = (dateString) => {
  var d = new Date(dateString);
    var date = d.getDate();
var month = d.getMonth() + 1; // Since getMonth() returns month from 0-11 not 1-12
var year = d.getFullYear();
  return new Date(year, month, date);
}

export const rawHtml = (content) => {
  var displayAHtml = content;
  return { __html: displayAHtml };
};

export const divideBy100 = (data) => {
  return parseInt(data)/100;
}


export const multiply100 = (data) => {
  return parseInt(data)*100;
}

export const getRange = (objData) => {
 const rangeList = [], newObj=[],
 totalLength = objData.length,
  interval = 100/(totalLength-1);
  for (let i=0; i<=200; i=i+interval) {
    rangeList.push(i);
  }
  objData.map((item, i) => {
    newObj[rangeList[i]] = `$${item.amount}`;
  });
  return newObj;
}

export const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};