import React, { useState } from "react";
import "./index.scss";
import { Typography, Select } from "antd";
import Colors from "../../theme/Colors";

const { Text, Link } = Typography;

const FindCreatorSearchRangeTile = (props) => {
  const { title, fromKey, toKey, code, searchFromRange, searchToRange, isRange } = props,
    [fromValue, setFromValue] = useState(null),
    [toValue, setToValue] = useState(null),
    clearValues = () => {
      setFromValue(null);
      setToValue(null);
      props.clearValues(code);
    };

  return (
    <div className="range-item">
      <div className="section-title">
        <div>
          <Text level={3} className="fw-600" style={{ color: Colors.mutedText }}>
            {title}
          </Text>
        </div>
        <div>
          <a onClick={() => clearValues()}>
            <Link style={{ color: Colors.primaryColor }}>Clear</Link>
          </a>
        </div>
      </div>
      <div className="section-title">
        <div className={isRange === true ? "" : "w-full"}>
          <Select
            name={fromKey}
            placeholder="From"
            optionFilterProp="children"
            className={isRange === true ? "w150" : "w-full"}
            value={fromValue}
            allowClear
            options={toValue && isRange === true ? searchFromRange.filter((values) => values.value < toValue) : searchFromRange}
            filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
            onChange={(e) => {
              setFromValue(e);
              props.handleChange(fromKey, e);
            }}
          ></Select>
        </div>
        {isRange === true && (
          <div>
            <Select
              name={toKey}
              placeholder="To"
              optionFilterProp="children"
              value={toValue}
              allowClear
              options={fromValue ? searchToRange.filter((values) => values.value > fromValue) : searchToRange}
              className="select-search"
              filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
              onChange={(e) => {
                setToValue(e);
                props.handleChange(toKey, e);
              }}
            ></Select>
          </div>
        )}
      </div>
    </div>
  );
};
export default FindCreatorSearchRangeTile;
