import React from "react";
import "./index.scss";
import { rawHtml } from "../../../helper";
import { Typography } from "antd";
import Colors from "../../../theme/Colors";

const { Title } = Typography;

const DeliverableContainer = (props) => {
  return (
    <div className="deliverable-container">
      <div className="deliverable-container-header" style={{ backgroundColor: Colors.secondaryColor }}>
        <Title level={5}>{props.contentType}</Title>
      </div>

      <div className="deliverable-container-content" style={{ border: `5px solid ${Colors.secondaryColor}`, borderTop: null }}>
        {props.contentType === "Feedback" ? <div dangerouslySetInnerHTML={rawHtml(props.content)}></div> : <div>{props.content}</div>}
      </div>
    </div>
  );
};

export default DeliverableContainer;
