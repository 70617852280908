import React, { useMemo } from "react";
import styles from "./index.module.scss";
import { useState } from "react";
import { useEffect } from "react";
import { CONFIG } from "../../../../config/config";
import { apiCall } from "../../../../modules/api-call";
import { Spin, Input, Checkbox, notification, Switch } from "antd";
import { OBJECTIVES } from "../../../../constants";
import BoostStatisticModal from "../../../BoostStatisticModal";
import AddCreativeModal from "./AddCreativeModal";
import { useMediaQuery } from "../../../../hooks/useMediaQuery.";

const MAX_LIVE_CREATIVES = 5;

const Creatives = ({ isOpen, boostId, fullPackage }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [dataEqual, setDataEqual] = useState(true);
  const [isAddModalOpen, setAddModalOpen] = useState(false);

  const isMobile = useMediaQuery("(max-width:768px)");

  const liveCreativesCount = useMemo(
    () => data.filter((creative) => creative.statusByUser === "live").length,
    [data]
  );

  const handleChange = (id, field, value) => {
    const updatedUserData = data.map((creative) =>
      creative.objectives.length === 1 &&
      field === "objectives" &&
      Array.isArray(value) &&
      value.length === 0
        ? creative
        : creative._id === id
        ? { ...creative, [field]: value }
        : creative
    );
    setDataEqual(false);
    setData(updatedUserData);
  };

  const getBoostCreatives = async () => {
    setLoading(true);
    let url = CONFIG.BACKEND_API_URL + `user/evergreen-boost/creatives?boostId=${boostId}`;

    try {
      const { data: response } = await apiCall({
        method: "GET",
        url,
        auth: 1,
      });
      setData(response?.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleSave = async () => {
    try {
      setLoading(true);
      let url = CONFIG.BACKEND_API_URL + "user/evergreen-boost/creatives";
      const { data: response } = await apiCall({
        method: "PUT",
        url,
        auth: 1,
        data: {
          boostId,
          creatives: data,
        },
      });
      setData(response?.data.creatives);
      setLoading(false);
      setDataEqual(true);
      notification.success({
        message: "Successfully updated.",
        placement: "topRight",
      });
    } catch (error) {
      setLoading(false);
      notification.error({
        message: "Something went wrong.",
        description: "Please, try again",
        placement: "topRight",
      });
    }
  };

  const handleAddCreative = (creative) => {
    setAddModalOpen(false);
    setDataEqual(false);
    setData((prev) => [creative, ...prev]);
  };

  const handleOpenAddModal = () => {
    setAddModalOpen((prev) => !prev);
  };

  useEffect(() => {
    isOpen && boostId && getBoostCreatives();
  }, [isOpen, boostId]);

  return (
    <>
      <div>
        {loading ? (
          <div>
            <Spin />
          </div>
        ) : (
          <div className={styles.root}>
            <div className={styles.creatives}>
              {data.map((creative, i) => (
                <div className={styles.creativeItem} key={creative._id}>
                  <div className={styles.titleWrapper}>
                    <p className={styles.creativeTitle}>Creative {i + 1}</p>
                    <div className={styles.creativeStatusWrapper}>
                      <Switch
                        size={isMobile ? "small" : "default"}
                        checked={creative.statusByUser === "live"}
                        onChange={(e) => {
                          if (e && liveCreativesCount >= MAX_LIVE_CREATIVES) {
                            return;
                          }
                          handleChange(creative._id, "statusByUser", e ? "live" : "inactive");
                        }}
                      />
                      <p className={styles.creativeStatus}>
                        {creative.statusByUser === "live" ? "Live" : "Inactive"}
                      </p>
                    </div>
                  </div>
                  <div className={styles.creativeField}>
                    <label className={styles.label}>Authorization code</label>
                    <Input
                      onChange={(e) => handleChange(creative._id, "authCode", e.target.value)}
                      value={creative.authCode}
                      className={styles.input}
                      type="text"
                    />
                  </div>
                  <div className={styles.creativeField}>
                    <label className={styles.label}>Ad name</label>
                    <Input
                      onChange={(e) => handleChange(creative._id, "nameByUser", e.target.value)}
                      value={creative.nameByUser}
                      className={styles.input}
                      type="text"
                    />
                  </div>
                  <Checkbox.Group
                    className={styles.objectivesCheckboxes}
                    onChange={(value) => handleChange(creative._id, "objectives", value)}
                    value={creative.objectives}
                    options={OBJECTIVES.map((val) => ({
                      value: val,
                      label: val,
                      disabled: val === "follows" && !fullPackage,
                    }))}
                  />
                </div>
              ))}
            </div>
            <div className={styles.addNewButtonWrapper}>
              <button
                disabled={liveCreativesCount >= MAX_LIVE_CREATIVES}
                onClick={handleOpenAddModal}
                className={styles.addNewButton}
              >
                + Add new
              </button>
              {liveCreativesCount >= MAX_LIVE_CREATIVES && (
                <div className={styles.creativesLimit}>
                  <span>You can&apos;t add more than 5 Creatives</span>
                </div>
              )}
            </div>
            <button
              disabled={dataEqual || loading}
              onClick={handleSave}
              className={styles.saveButton}
            >
              {loading ? <Spin size="small" /> : "Submit Changes"}
            </button>
          </div>
        )}
      </div>
      {isAddModalOpen && (
        <BoostStatisticModal
          isOpen={isAddModalOpen}
          title="Add new creative"
          handleClose={handleOpenAddModal}
        >
          <AddCreativeModal handleAddCreative={handleAddCreative} fullPackage={fullPackage} />
        </BoostStatisticModal>
      )}
    </>
  );
};

export default Creatives;
